import React from 'react';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { useNavigate } from 'react-router-dom';

import { useAuthToken } from '../../../hooks/useAuthToken';
import { ButtonContainer } from './LogoutButtonStyle';

interface LogoutButtonProps {
  isCollapsed: boolean;
}

export const LogoutButton = ({ isCollapsed }: LogoutButtonProps) => {
  const { logout } = useAuthToken();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <ButtonContainer data-collapsed={isCollapsed} onClick={handleLogout}>
      <Icon name="ri-logout-circle-line" />
      <Typography as="span" variant="button">
        Log out
      </Typography>
    </ButtonContainer>
  );
};
