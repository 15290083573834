import React from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { Variant } from 'glints-aries/lib/@next/Typography';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  TimeZone,
  timeZone as timeZoneList,
  timeZoneAbbrMapping,
  timeZoneStringMapping,
} from '../../utils/timeZone';

export const FormattedDate = ({
  date,
  noDataText = '-',
  pattern = { month: 'short', day: '2-digit', year: 'numeric' },
  timeZone,
  textVariant = 'subtitle2',
}: {
  date?: string | null;
  noDataText?: string;
  pattern?: Intl.DateTimeFormatOptions;
  timeZone?: TimeZone;
  textVariant?: Variant;
}) => {
  let timeZoneAbbr = '';
  const localTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  let formattedDate = date
    ? new Date(date).toLocaleString('en-US', {
      timeZone: localTimeZone,
      ...pattern,
    })
    : '';

  if (date && timeZone && Object.values(timeZoneList).includes(timeZone)) {
    timeZoneAbbr = timeZoneAbbrMapping[timeZone];
    const timeZoneString = timeZoneStringMapping[timeZone];
    const utcDate = new Date(date);
    formattedDate = utcDate.toLocaleString('en-US', {
      timeZone: timeZoneString,
      ...pattern,
    });
  }

  if (!date) {
    return (
      <Typography as="span" variant={textVariant} color={Neutral.B85}>
        {noDataText}
      </Typography>
    );
  }

  return (
    <Typography as="span" variant={textVariant}>
      {formattedDate}
      {timeZone && (
        <Typography as="span" variant="overline" color={Neutral.B40}>
          {` ${timeZoneAbbr}`}
        </Typography>
      )}
    </Typography>
  );
};
