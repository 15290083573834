export const errorType = {
  FORBIDDEN: 'FORBIDDEN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  LINK_EXPIRED: 'LINK_EXPIRED',
  LINK_INVALID: 'LINK_INVALID',
  NOT_FOUND: 'NOT_FOUND',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  UNKNOWN: 'UNKNOWN',
} as const;

type TypeErrorType = typeof errorType;
export type ErrorType = TypeErrorType[keyof TypeErrorType];

export const errorContent = {
  FORBIDDEN: {
    title: 'Permission Denied',
    description:
      'Access to this page is restricted. For permission settings adjustments, kindly reach out to your account admin.',
  },
  INTERNAL_SERVER_ERROR: {
    title: 'Oops, Something Went Wrong.',
    description: 'An unexpected issue occurred. Please reload this page later.',
  },
  LINK_EXPIRED: {
    title: 'Your magic link has expired',
    description:
      'Please go back to the landing page and request a new magic link',
  },
  LINK_INVALID: {
    title: 'Invalid magic link',
    description: 'Please click the magic link from your email',
  },
  NOT_FOUND: {
    title: 'Page Not Found',
    description: 'The page you are trying to access does not exist',
  },
  SERVICE_UNAVAILABLE: {
    title: 'Oops, Something Went Wrong.',
    description: 'An unexpected issue occurred. Please reload this page later.',
  },
  TOKEN_EXPIRED: {
    title: 'Session Expired',
    description:
      'Your session has expired for security reasons. To proceed, please log in once more.',
  },
  UNAUTHORIZED: {
    title: 'Login Required',
    description: 'You must be logged in to view this page.',
  },
  UNKNOWN: {
    title: 'Oops, Something Went Wrong.',
    description:
      'An unexpected issue occurred. \nPlease reload this page later.',
  },
};
