import { PaginationProps } from 'glints-aries/lib/@next';
import { SortDirection } from 'glints-aries/lib/@next/DataTable';

import { InvoiceDispute, PaymentMethod } from '../../generated/graphql';

export const invoicePaymentsTab = {
  AWAITING_PAYMENT: 0,
  PAYMENT_COMPLETED: 1,
} as const;

type TypeInvoicePaymentsTab = typeof invoicePaymentsTab;
export type invoicePaymentsTab =
  TypeInvoicePaymentsTab[keyof TypeInvoicePaymentsTab];

export const tabIndexMapping: Record<number, string> = {
  [invoicePaymentsTab.AWAITING_PAYMENT]: 'awaiting-payment',
  [invoicePaymentsTab.PAYMENT_COMPLETED]: 'payment-completed',
};

export type Money = {
  currency: string;
  amount: string;
};

export type Payment = {
  amount: Money;
  date: string;
  id: string;
};

export type PaymentLine = {
  amount: Money;
  date: string;
  id: string;
  payment: Payment;
};

export type Invoice = {
  id: string;
  issueDate: string;
  dueDate?: string | null;
  referenceNumber: string;
  closedDate?: string | null;
  paidAmount: Money;
  amount: Money;
  paymentLines?: PaymentLine[];
  dispute?: InvoiceDispute | null;
};

interface SinglePaymentCompletedData {
  invoice: {
    id: string;
    amount: Money;
    closedDate?: string;
    creditNoteLines: {
      amount: Money;
    }[];
    paidAmount: Money;
    referenceNumber: string;
  };
  payment: {
    id: string;
    method?: PaymentMethod;
    isPdfReady: boolean;
    date: string;
  };
}

export interface PaymentCompletedTableProps extends PaginationProps {
  data: SinglePaymentCompletedData[];
  loading: boolean;
  handleSortChange(context: string, sortDirection: SortDirection): void;
}
