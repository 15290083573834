import { Badge } from 'glints-aries/lib/@next';
import {
  space8,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const CUSTOM_BREAKPOINT = "1180px";

export const TimeOffBalanceHeader = styled.div`
  margin: ${space16} 0;

  display: flex;
  flex-direction: row;
  gap: ${space16};

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    display: grid;
    grid-template-areas:
      'time-off-balance-select time-off-balance-select'
      'time-off-balance-talent-filter time-off-balance-talent-filter'
      'time-off-balance-search time-off-balance-export';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const SearchInputContainer = styled.div`
  width: 200px;
  grid-area: time-off-balance-search;
`;

export const SelectInputContainer = styled.div`
  width: 240px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    grid-area: time-off-balance-select;
  }
`;

export const SelectTalentFilterContainer = styled.div`
  width: 250px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    grid-area: time-off-balance-talent-filter;
  }
`;

export const ExportButtonContainer = styled.div`
  margin-left: auto;

  svg {
    height: 16px;
    width: 16px;
  }

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    display: flex;
    justify-content: flex-end;
    grid-area: time-off-balance-export;
  }
`;

export const HubberCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space24};
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space16};
`;

export const SelectOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SelectOptionLabel = styled.div`
  flex: 1;
  margin-right: ${space8};
`;

export const StyledBadge = styled(Badge)`
  margin-right: ${space8};
`;
