import React from 'react';
import { Card } from 'glints-aries/lib/@next';

import { Address, Company } from '../../../generated/graphql';
import {
  CardContainer,
  CardContentContainer,
  ColumnFlexContainer,
  LeftSectionContainer,
  RightSectionContainer,
  RowFlexContainer,
} from '../components/CardStyle';
import SingleData from '../components/SingleData';

type BillingInfoProps = Pick<Company, 'billingName' | 'billingEmail'> & {
  billingAddress: Address['street'];
  isLoading?: boolean;
};

const BillingInfo = ({
  billingName,
  billingEmail,
  billingAddress,
  isLoading = false,
}: BillingInfoProps) => (
  <CardContainer>
    <Card heading="Billing Info">
      <CardContentContainer>
        <ColumnFlexContainer>
          <RowFlexContainer>
            <LeftSectionContainer>
              <SingleData label="Billing Entity Name" isLoading={isLoading}>
                {billingName}
              </SingleData>
            </LeftSectionContainer>
            <RightSectionContainer>
              <SingleData label="Billing Email" isLoading={isLoading}>
                {billingEmail}
              </SingleData>
            </RightSectionContainer>
          </RowFlexContainer>
          <RowFlexContainer>
            <LeftSectionContainer>
              <SingleData label="Billing Address" isLoading={isLoading}>
                {billingAddress}
              </SingleData>
            </LeftSectionContainer>
            <RightSectionContainer />
          </RowFlexContainer>
        </ColumnFlexContainer>
      </CardContentContainer>
    </Card>
  </CardContainer>
);

export default BillingInfo;
