import { space8, space16, space24 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const WorkDayTitleContainer = styled.div`
  padding: ${space8} ${space16};
  margin-top: ${space16};
`;

export const DaysOffContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${space24} ${space16} 0;
  padding-top: ${space8};
  gap: ${space8};
`;

export const DaysOffTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space8};
`;

export const ScheduleDataTableContainer = styled.div`
  .data-table-container {
    box-shadow: none;
  }
`;
