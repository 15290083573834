import { Breakpoints, Link } from 'glints-aries/lib/@next';
import { button } from 'glints-aries/lib/@next/Typography/TypographyStyles';
import { borderRadius4 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const HEADER_HEIGHT = '132px';

export const DataTableContainer = styled.div`
  ::-webkit-scrollbar {
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: ${borderRadius4};
  }

  transform: translateX(-24px);
  width: calc(100%);
  position: absolute;
  height: calc(100% - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Neutral.B100};

  .data-table-container {
    background-color: ${Neutral.B100};
    height: 100%;

    table {
      border: none;
      height: auto;

      tbody tr {
        height: 56px;
      }
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }
`;

export const TableHeadingCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space4};

  svg {
    height: 20px;
    width: 20px;
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const LeftPaddingWrapper = styled.div`
  padding-left: ${space8};
`;

export const RightPaddingWrapper = styled.div`
  padding-right: ${space8};
`;

export const PaymentAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${space8};
`;

export const TableButtonContainer = styled.div`
  ${button}
  margin: 0px ${space8};

  &[data-no-margin-right='true'] {
    margin-right: 0px;
  }
`;

export const TablePaginationContainer = styled.div`
  display: grid;
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
`;

export const StyledLink = styled(Link)`
  p {
    margin: 0;
  }
`;
