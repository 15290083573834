import React from 'react';
import { DataTable, EmptyState } from 'glints-aries/lib/@next';
import { TableHeading } from 'glints-aries/lib/@next/DataTable';

import { ReactComponent as AstronautSOS } from '../../../../assets/images/astronaut-sos.svg';
import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { PublicHolidayData } from '../../../../generated/graphql';
import { EmptyStateContainer, StyledCell } from './HolidaysTableStyle';

interface HolidaysTableProps {
  publicHolidayData?: PublicHolidayData[] | null;
}

export const HolidaysTable = ({ publicHolidayData }: HolidaysTableProps) => {
  const headings: TableHeading[] = [
    { title: 'Date' },
    { title: 'Day' },
    { title: 'Holiday Name' },
  ];

  const emptyState = (
    <EmptyState
      image={<AstronautSOS />}
      title="Missing Data"
      description="The public holidays are currently not displaying on this page. We are working to resolve this issue promptly."
    />
  );

  return (
    <>
      {publicHolidayData && publicHolidayData?.length !== 0 ? (
        <DataTable headings={headings}>
          {publicHolidayData &&
            publicHolidayData.map((dt, index: number) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>
                  <FormattedDate
                    date={dt.date}
                    pattern={{ month: 'short', day: '2-digit' }}
                  />
                </DataTable.Cell>
                <DataTable.Cell>{dt.day}</DataTable.Cell>
                <StyledCell>
                  <DataTable.Cell>{dt.name}</DataTable.Cell>
                </StyledCell>
              </DataTable.Row>
            ))}
        </DataTable>
      ) : (
        <EmptyStateContainer>{emptyState}</EmptyStateContainer>
      )}
    </>
  );
};
