import { Avatar, Breakpoints } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const ModalContentContainer = styled.div`
  width: 600px;
`;

export const StyledBanner = styled.div`
  padding: ${space16} ${space16} ${space8};
  background: ${Neutral.B99};
  box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15),
    0px 0px 0px 1px rgba(71, 71, 71, 0.05);

  display: flex;
  flex-direction: column;
  gap: ${space16};
  margin-bottom: ${space16};
`;

export const TopBannerSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space16};
`;

export const StyledAvatar = styled(Avatar)`
  height: 60px;
  width: 72px;

  @media (max-width: ${Breakpoints.large}) {
    height: 40px;
    width: 48px;
  }
`;

export const UserInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  width: 100%;
`;

export const UserInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space8};
  align-items: center;
`;

export const BottomBannerSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space4};
`;

export const SingleInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  padding: ${space16} ${space16} 25px;
`;

export const StyledHorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Neutral.B85};
`;
