import { Breakpoints, IndexTable } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const EmptyStateContainer = styled.td`
  height: 618px;
  background: ${Neutral.B100};
`;

export const LoadingRow = styled(IndexTable.Row)`
  pointer-events: none;
`;

const HEADER_HEIGHT = '144px';

export const ManagedTalentsTableContainer = styled.div`
  box-shadow: 0px 12px 30px rgba(71, 71, 71, 0.1),
    0px -3px 15px -3px rgba(71, 71, 71, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - ${HEADER_HEIGHT});

  transform: translateX(-24px);
  width: calc(100%);
  position: absolute;

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }

  .Polaris-IndexTable {
    height: 100%;
    background: ${Neutral.B100};
  }
`;

export const StyledCell = styled(IndexTable.Cell)`
  padding: ${space16};
`;

export const ClickableWrapper = styled.div`
  cursor: pointer;
`;

export const LeftPaddingWrapper = styled.div`
  padding-left: ${space8};
`;

export const RightPaddingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
  padding-right: ${space8};
`;

export const TalentHubCellContainer = styled.div`
  display: flex;
`;

export const FlagContainer = styled.div`
  margin-right: ${space16};

  svg {
    border: 1px solid ${Neutral.B85};
  }
`;

export const TablePaginationContainer = styled.div`
  display: grid;
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
`;
