export const PAGE_SIZE = 20;

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ShowAlertType = {
  status?: AlertType.SUCCESS | AlertType.ERROR;
  content: string;
  shouldShow?: boolean;
};

export const alertContent = {
  [AlertType.SUCCESS]: 'Your dispute has been successfully sent',
  [AlertType.ERROR]: 'Failed to send your dispute. Please try again later',
};

export enum InvoiceStatus {
  OUTSTANDING = 'Outstanding',
  DUE = 'Due',
  OVERDUE = 'Overdue',
  DUESOON = 'Due in',
}
