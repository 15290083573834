import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
import '@fontsource/poppins/700.css';

import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ModalWithProvider } from 'glints-aries/lib/@next';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import GlobalStyle from './globalStyle';
import { router } from './Routes';

const queryClient = new QueryClient();

const App = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <ModalWithProvider />
      <RouterProvider router={router} />
      <ReactQueryDevtools />
      <div id="glints-portal-container" />
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
