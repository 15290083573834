import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const MAX_JOB_TITLE_WIDTH = '260px';
export const StyledCollapsibleFooter = styled.div`
  margin-top: auto;
  border-top: 1px solid ${Neutral.B85};
`;

export const CollapsedUserSection = styled.div`
  display: flex;
  height: 40px;
  padding: ${space4} ${space4} ${space4} ${space8};
  align-items: center;
  justify-content: center;
  gap: ${space8};
  border-radius: 50px;

  &[data-nav-collapsed='true'] {
    width: 68px;
    transition: width 0.3s ease;

    .job-title {
      display: none;
    }

    &:hover {
      width: ${MAX_JOB_TITLE_WIDTH};
      box-shadow: 0px 2px 16px 0px rgba(45, 45, 45, 0.08),
        0px 31px 41px 0px rgba(45, 45, 45, 0.2);
      background-color: ${Neutral.B100};

      .job-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${space8};
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
`;

export const UserAccountSection = styled.div`
  cursor: pointer;
  display: flex;
  padding: ${space12} ${space8};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${space8};

  span {
    margin-bottom: ${space4};
  }

  .role-badge {
    padding: 0px;
  }
`;

export const IconContainer = styled.div<{ isCollapsed: boolean }>`
  margin-left: auto;

  svg {
    transform: rotate(${props => (props.isCollapsed ? '0deg' : '-180deg')});
    transition: transform 200ms ease-in-out;
  }
`;

export const Content = styled.div<{ isCollapsed: boolean }>`
  margin: ${props => (props.isCollapsed ? 0 : `${space8} ${space16}`)};
  overflow: hidden;
  max-height: ${props => (props.isCollapsed ? 0 : 'auto')};
  transition: max-height 200ms ease-in-out;
`;
