import React from 'react';
import { Badge } from 'glints-aries/lib/@next';
import { Green, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { StatusDot, StatusIndicatorContainer } from './StatusIndicatorStyle';

interface StatusIndicatorProps {
  active: boolean;
}

export const StatusIndicator = ({ active }: StatusIndicatorProps) => (
  <StatusIndicatorContainer>
    <StatusDot color={active ? Green.B61 : Neutral.B85} />
    <Badge status={active ? 'success' : 'neutral'} className="status-badge">
      {active ? 'Active' : 'Inactive'}
    </Badge>
  </StatusIndicatorContainer>
);
