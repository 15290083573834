import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Badge,
  Icon,
  Modal,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { getGraphqlClient } from '../../clients/graphql';
import {
  UpdateUserProfileMutation,
  useUpdateUserProfileMutation,
} from '../../generated/graphql';
import { PhoneTextInput } from '../../modules/CompanyProfile/RolesPermissionsTab/components/AddEditUserModal/PhoneInput';
import {
  COUNTRY_CODE_OPTIONS,
  roleDetailsMapping,
} from '../../modules/CompanyProfile/RolesPermissionsTab/constants';
import { errorType } from '../../modules/Error/constants';
import { getInitials } from '../../utils/formatString';
import { FormTextInput } from '../FormTextInput/FormTextInput';
import { AlertType } from '../PageLayout/constants';
import { MyProfileModalProps, UserFields } from './interfaces';
import {
  BottomBannerSection,
  ModalContentContainer,
  SingleInfoSection,
  StyledAvatar,
  StyledBanner,
  StyledHorizontalLine,
  TopBannerSection,
  UserInfoRow,
  UserInfoSection,
} from './MyProfileModalStyle';

export const validationSchema = z.object({
  /* eslint-disable camelcase */
  department: z.string().nullish(),
  phoneNumber: z.object({
    countryCode: z.string().regex(/\+\d+/).nullish(),
    number: z
      .string()
      .regex(/^(\d{5,})?$/, 'Numbers only, no characters or whitespace')
      .max(15, 'Invalid phone number')
      .nullish(),
    extension: z
      .string()
      .regex(/^(?:\d+)?$/, 'Numbers only, no characters or whitespace')
      .max(7, 'Invalid phone number')
      .nullish(),
    /* eslint-enable camelcase */
  }),
});

export const MyProfileModal = ({
  isOpen,
  onClose,
  user,
  updateUser,
  updateShowAlert,
}: MyProfileModalProps) => {
  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();
  const [isEdit, setIsEdit] = useState(false);

  const defaultValues = {
    department: user?.department ?? '',
    phoneNumber: {
      countryCode:
        user?.phoneNumber?.countryCode ?? COUNTRY_CODE_OPTIONS[3].value,
      number: user?.phoneNumber?.number ?? '',
      extension: user?.phoneNumber?.extension ?? '',
    },
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty: isFormDirty, errors },
    reset: resetForm,
  } = useForm<UserFields>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const { isLoading: isEditLoading, mutate: editMutate } =
    useUpdateUserProfileMutation<Error, UpdateUserProfileMutation>(
      graphqlClient
    );

  const onEdit = async (userFields: UserFields) => {
    editMutate(
      {
        userProfileInput: { ...userFields },
      },
      {
        onSuccess: data => {
          updateUser(data.updateProfile);
          updateShowAlert({ shouldShow: true, type: AlertType.SUCCESS });
          setIsEdit(false);
        },
        onError: err => {
          updateShowAlert({ shouldShow: true, type: AlertType.ERROR });
          if (err.toString().substring(28, 31) === '401') {
            localStorage.removeItem('glintsMTS');
            navigate('/magic-link', {
              state: { errorType: errorType.TOKEN_EXPIRED },
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (user) {
      resetForm(user, { keepDefaultValues: true });
    }
  }, [resetForm, user]);

  const customActions = (
    <PrimaryButton
      onClick={() => setIsEdit(true)}
      icon={<Icon name="ri-pencil-line" />}
      iconPosition="left"
    >
      Edit
    </PrimaryButton>
  );

  const editPrimaryAction = {
    label: 'Save',
    action: handleSubmit(onEdit),
    loading: isEditLoading,
  };
  const editSecondaryAction = {
    label: 'Cancel',
    action: () => setIsEdit(false),
    disabled: isEditLoading,
  };

  const modalBanner = (
    <StyledBanner>
      <TopBannerSection>
        <StyledAvatar
          initials={getInitials(user?.name || '')}
          size="large"
          variant="warning"
        />
        <UserInfoSection>
          <UserInfoRow>
            <Typography as="div" variant="body2" color={Neutral.B18}>
              {user?.name}
            </Typography>
            <Badge
              status="neutral"
              style={{
                marginLeft: 'auto',
              }}
            >
              {user?.roles[0] && roleDetailsMapping[user?.roles[0].id]?.role}
            </Badge>
          </UserInfoRow>
          <UserInfoRow>
            <Icon name="ri-user-line" height={16} fill={Neutral.B40} />
            <Typography
              as="div"
              variant="body1"
              color={user?.jobTitle ? Neutral.B18 : Neutral.B85}
            >
              {user?.jobTitle ?? 'Unknown'}
            </Typography>
          </UserInfoRow>
          <UserInfoRow>
            <Icon name="ri-mail-line" height={16} fill={Neutral.B40} />
            <Typography as="div" variant="body1" color={Neutral.B18}>
              {user?.email}
            </Typography>
          </UserInfoRow>
        </UserInfoSection>
      </TopBannerSection>
      <BottomBannerSection>
        <Icon name="ri-error-warning-fill" height={16} fill={Neutral.B40} />
        <Typography as="div" variant="overline" color={Neutral.B40}>
          Need an update? Contact your company admins
        </Typography>
      </BottomBannerSection>
    </StyledBanner>
  );

  const modalContent = isEdit ? (
    <>
      <FormTextInput
        control={control}
        label="Department"
        name="department"
        placeholder="Enter department"
      />
      <PhoneTextInput control={control} label="Phone" />
    </>
  ) : (
    <>
      <SingleInfoSection>
        <Typography as="div" variant="caption" color={Neutral.B18}>
          Department
        </Typography>
        <Typography
          as="div"
          variant="body1"
          color={user?.department ? Neutral.B18 : Neutral.B85}
        >
          {user?.department ?? 'Unknown'}
        </Typography>
      </SingleInfoSection>
      <StyledHorizontalLine />
      <SingleInfoSection>
        <Typography as="div" variant="caption" color={Neutral.B18}>
          Phone Number
        </Typography>
        <Typography
          as="div"
          variant="body1"
          color={user?.phoneNumber?.number ? Neutral.B18 : Neutral.B85}
        >
          {user?.phoneNumber?.number
            ? `${user?.phoneNumber?.countryCode} ${user?.phoneNumber?.number} ${
              user?.phoneNumber?.extension ?? ''
            }`
            : 'Unknown'}
        </Typography>
      </SingleInfoSection>
    </>
  );

  if (isEdit) {
    return (
      <>
        <Modal
          isOpen={isOpen}
          header="My Profile"
          showCloseButton={true}
          onClose={onClose}
          primaryAction={editPrimaryAction}
          secondaryAction={editSecondaryAction}
        >
          <ModalContentContainer>
            {modalBanner}
            {modalContent}
          </ModalContentContainer>
        </Modal>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      header="My Profile"
      showCloseButton={true}
      onClose={onClose}
      customActions={customActions}
    >
      <ModalContentContainer>
        {modalBanner}
        {modalContent}
      </ModalContentContainer>
    </Modal>
  );
};
