import React from 'react';

import { ReactComponent as FlagID } from '../../assets/images/flag-id.svg';
import { ReactComponent as FlagMY } from '../../assets/images/flag-my.svg';
import { ReactComponent as FlagPH } from '../../assets/images/flag-ph.svg';
import { ReactComponent as FlagSG } from '../../assets/images/flag-sg.svg';
import { ReactComponent as FlagTW } from '../../assets/images/flag-tw.svg';
import { ReactComponent as FlagVN } from '../../assets/images/flag-vn.svg';
import { EmploymentStatus } from '../../generated/graphql';
import { HubCode } from './interface';

export const resourceName = {
  singular: 'managed talent',
  plural: 'managed talents',
};

export const flagMapping: { [code in HubCode]: React.ReactElement } = {
  ['BT']: <FlagID />,
  ['ID']: <FlagID />,
  ['MY']: <FlagMY />,
  ['PH']: <FlagPH />,
  ['SG']: <FlagSG />,
  ['TW']: <FlagTW />,
  ['VN']: <FlagVN />,
};

export const hubMapping: { [code in HubCode]: string | null } = {
  ['BT']: 'Batam',
  ['ID']: 'Jakarta',
  ['MY']: null,
  ['PH']: null,
  ['SG']: null,
  ['TW']: null,
  ['VN']: null,
};

export const statusMapping = {
  [EmploymentStatus.Active]: 'Active',
  [EmploymentStatus.ResignedOrTerminated]: 'Inactive',
  [EmploymentStatus.ContractEnded]: 'Contract Expired',
};

type OptionType = {
  label: string;
  value: EmploymentStatus;
};

export const statusFilterOptions: OptionType[] = [
  { label: 'Active', value: EmploymentStatus.Active },
  { label: 'Contract Expired', value: EmploymentStatus.ContractEnded },
  { label: 'Inactive', value: EmploymentStatus.ResignedOrTerminated },
];
