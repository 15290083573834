export const companyProfileTab = {
  COMPANY_DETAILS: 0,
  ROLES_AND_PERMISSIONS: 1,
} as const;

type TypeCompanyProfileTab = typeof companyProfileTab;
export type CompanyProfileTab =
  TypeCompanyProfileTab[keyof TypeCompanyProfileTab];

export const tabIndexMapping: Record<number, string> = {
  [companyProfileTab.COMPANY_DETAILS]: 'company-information',
  [companyProfileTab.ROLES_AND_PERMISSIONS]: 'roles-and-permissions',
};
