import React, { useCallback, useState } from 'react';
import { add as addDate, intervalToDuration } from 'date-fns';
import {
  ActionList,
  ActionListItem,
  Badge,
  Icon,
  IndexTable,
  PaginationProps,
  Popover,
  PrimaryButton,
  SkeletonImageSquare,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral, Orange } from 'glints-aries/lib/@next/utilities/colors';

import { getGraphqlClient } from '../../../../clients/graphql';
import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { TablePagination } from '../../../../components/TablePagination/TablePagination';
import {
  GetInvoicePdfMutation,
  useGetInvoicePdfMutation,
} from '../../../../generated/graphql';
import { formatMoney } from '../../../../utils/formatString';
import { InvoiceStatus } from '../constants';
import {
  Currency,
  DisputedBadgeContainer,
  IndexTableContainer,
  InvoiceReferenceWrapper,
  LeftPaddingWrapper,
  LoadingRow,
  PaymentAmountWrapper,
  PopoverActivatorContainer,
  StyledCell,
  StyledLink,
  TableActionsContainer,
  TablePaginationContainer,
} from './AwaitingPaymentTableStyle';
import { DisputeInvoiceModal } from './DisputeInvoiceModal';
import { DisputeInvoiceSidesheet } from './DisputeInvoiceSidesheet';

// TODO - put this in interface later, the one in interfaces.ts caused error now
type Invoice = {
  amount: {
    currency: string;
    amount: string;
  };
  closedDate?: string | null;
  dispute?: {
    id: string;
    invoiceId: string;
    reason: string;
    createdBy: {
      name: string;
    };
    createdAt: string;
  } | null;
  dueDate?: string;
  id: string;
  issueDate: string;
  paidAmount: {
    currency: string;
    amount: string;
  };
  paymentLines: {
    amount: {
      currency: string;
      amount: string;
    };
    date: string;
    id: string;
  }[];
  referenceNumber: string;
};

interface AwaitingPaymentTableProps extends PaginationProps {
  data?: Invoice[];
  loading: boolean;
  handleRefetch: () => void;
  handlePayClick: (index: number) => void;
}

export const AwaitingPaymentTable = ({
  totalItems,
  currentPage,
  pageSize,
  onPageChanged,
  data,
  loading,
  handleRefetch,
  handlePayClick,
}: AwaitingPaymentTableProps) => {
  const [popoverActive, setPopoverActive] = useState<boolean[]>(
    Array(data?.length).fill(false)
  );
  const [modalIndexSelected, setModalIndexSelected] = useState<number | null>(
    null
  );
  const [sidesheetIndexSelected, setSidesheetIndexSelected] = useState<
    number | null
  >(null);

  const togglePopoverActive = useCallback((index: number) => {
    setPopoverActive(popoverActive => [
      ...popoverActive.slice(0, index),
      !popoverActive[index],
      ...popoverActive.slice(index + 1),
    ]);
  }, []);

  const createPopoverActivator = (index: number) => (
    <PopoverActivatorContainer onClick={() => togglePopoverActive(index)}>
      <Icon name="ri-more" height={24} fill={Neutral.B40} />
    </PopoverActivatorContainer>
  );

  const getPopoverOptions = (dispute: boolean, index: number) => {
    const undisputedPopoverActions: ActionListItem[] = [
      {
        content: 'Dispute Invoice',
        icon: <Icon name="ri-error-warning-line" />,
        action: () => setModalIndexSelected(index),
      },
      {
        content: 'Download Invoice',
        icon: <Icon name="ri-download-line" />,
        action: () => {
          if (data) handleClickInvoicePdf(data[index].id);
        },
      },
    ];
    const disputedPopoverActions: ActionListItem[] = [
      {
        content: 'Dispute Invoice',
        icon: <Icon name="ri-error-warning-line" />,
        disabled: true,
      },
      {
        content: 'View Dispute',
        icon: <Icon name="ri-file-list-line" />,
        action: () => setSidesheetIndexSelected(index),
      },
      {
        content: 'Download Invoice',
        icon: <Icon name="ri-download-line" />,
        action: () => {
          if (data) handleClickInvoicePdf(data[index].id);
        },
      },
    ];
    return dispute ? disputedPopoverActions : undisputedPopoverActions;
  };

  const graphqlClient = getGraphqlClient();

  const { mutate: pdfMutate } = useGetInvoicePdfMutation<
    Error,
    GetInvoicePdfMutation
  >(graphqlClient);

  const handleClickInvoicePdf = (id: string) => {
    const pdfVariables = {
      id,
    };
    pdfMutate(pdfVariables, {
      onSuccess: data => {
        window.open(data?.invoicePdf?.url, '_blank');
      },
      onError: () => {
        // handle error
      },
    });
  };

  const getInvoiceStatusBadge = (dueDate?: string | null) => {
    if (!dueDate) {
      return <Badge status="information">{InvoiceStatus.OUTSTANDING}</Badge>;
    }

    const todayDate = new Date();
    const dueDatePlusOneDay = addDate(new Date(dueDate), { days: 1 });
    if (dueDatePlusOneDay < todayDate) {
      return <Badge status="attention">{InvoiceStatus.OVERDUE}</Badge>;
    }

    const duration = intervalToDuration({
      start: todayDate,
      end: new Date(dueDate),
    });

    const totalDays =
      1 +
      (duration ? duration.days ?? 0 : 0) +
      (duration ? duration.months ?? 0 : 0) * 30 +
      (duration ? duration.years ?? 0 : 0) * 365;
    let badgeContent: string = InvoiceStatus.OUTSTANDING;

    if (totalDays === 0) {
      badgeContent = InvoiceStatus.DUE;
    }

    if (totalDays <= 5) {
      badgeContent = `${InvoiceStatus.DUESOON} ${totalDays} ${
        totalDays === 1 ? 'day' : 'days'
      }`;
    }

    return <Badge status="warning">{badgeContent}</Badge>;
  };

  const paymentTooltip = ({
    referenceNumber,
    date,
  }: {
    referenceNumber: string;
    date: string;
  }) => (
    <Tooltip
      content={
        <Typography as="span" variant="subtitle2">
          There is a remaining balance for a short payment you made for{' '}
          {referenceNumber} on <FormattedDate date={date} />
        </Typography>
      }
      preferredPosition="bottom-center"
    >
      <Icon name="ri-error-warning-fill" fill={Orange.S86} />
    </Tooltip>
  );

  const rowMarkup = data?.map(
    (
      {
        id,
        dueDate,
        referenceNumber,
        paidAmount: { amount: paidAmount },
        amount: { currency: amountCurrency, amount },
        paymentLines,
        dispute,
      },
      index
    ) => {
      const isShort = parseFloat(paidAmount) < parseFloat(amount);
      const hadPaid = (paymentLines?.length ?? 0) > 0;

      return (
        <IndexTable.Row id={index.toString()} key={index} position={index}>
          <StyledCell>
            <LeftPaddingWrapper>
              {getInvoiceStatusBadge(dueDate)}
            </LeftPaddingWrapper>
          </StyledCell>
          <StyledCell>
            <FormattedDate date={dueDate} />
          </StyledCell>
          <StyledCell>
            <InvoiceReferenceWrapper>
              <StyledLink
                url="#"
                onClick={() => handleClickInvoicePdf(id)}
                removeUnderline={true}
              >
                <Typography as="span" variant="subtitle2">
                  {referenceNumber}
                </Typography>
              </StyledLink>
              {dispute !== null && (
                <DisputedBadgeContainer
                  onClick={() => setSidesheetIndexSelected(index)}
                >
                  <Icon name="ri-error-warning-line" />
                  <Typography as="span" variant="subtitle2">
                    Disputed
                  </Typography>
                </DisputedBadgeContainer>
              )}
            </InvoiceReferenceWrapper>
          </StyledCell>
          <StyledCell>
            <PaymentAmountWrapper>
              {isShort &&
                hadPaid &&
                paymentLines &&
                paymentTooltip({
                  referenceNumber,
                  date: paymentLines[0].date,
                })}
              <Typography as="span" variant="subtitle2" color={Neutral.B18}>
                {formatMoney({ amount })}
              </Typography>
              <Currency as="span" variant="overline">
                {amountCurrency}
              </Currency>
            </PaymentAmountWrapper>
          </StyledCell>
          <StyledCell>
            <TableActionsContainer>
              {/* <PrimaryButton
                size="default"
                onClick={() => handlePayClick(index)}
                disabled={true}
              >
                Pay
              </PrimaryButton> */}
              <Popover
                active={popoverActive[index]}
                activator={createPopoverActivator(index)}
                onClose={() => togglePopoverActive(index)}
              >
                <Popover.Pane>
                  <ActionList
                    items={getPopoverOptions(dispute !== null, index)}
                  />
                </Popover.Pane>
              </Popover>
            </TableActionsContainer>
          </StyledCell>
        </IndexTable.Row>
      );
    }
  );

  const loadingRow = [...Array(10).keys()].map(n => (
    <LoadingRow id={`loading-row-${n}`} key={n} position={n}>
      <StyledCell>
        <LeftPaddingWrapper>
          <SkeletonImageSquare height="24px" width="100%" />
        </LeftPaddingWrapper>
      </StyledCell>
      <StyledCell>
        <SkeletonImageSquare height="24px" width="100%" />
      </StyledCell>
      <StyledCell>
        <SkeletonImageSquare height="24px" width="100%" />
      </StyledCell>
      <StyledCell>
        <PaymentAmountWrapper>
          <SkeletonImageSquare height="24px" width="100%" />
        </PaymentAmountWrapper>
      </StyledCell>
      <StyledCell>
        <SkeletonImageSquare height="24px" width="100%" />
      </StyledCell>
    </LoadingRow>
  ));

  const handleClose = () => {
    handleRefetch();
    setModalIndexSelected(null);
  };

  return (
    <>
      <DisputeInvoiceModal
        isOpen={modalIndexSelected !== null}
        onClose={handleClose}
        invoiceId={data?.[modalIndexSelected ?? 0].id ?? ''}
        invoiceReference={data?.[modalIndexSelected ?? 0].referenceNumber ?? ''}
        paymentAmount={data?.[modalIndexSelected ?? 0].amount.amount ?? ''}
        paymentCurrency={data?.[modalIndexSelected ?? 0].amount.currency ?? ''}
      />
      <DisputeInvoiceSidesheet
        isOpen={sidesheetIndexSelected !== null}
        onClose={() => setSidesheetIndexSelected(null)}
        handleInvoiceClick={handleClickInvoicePdf}
        invoiceId={data?.[modalIndexSelected ?? 0].id ?? ''}
        invoiceReference={
          data?.[sidesheetIndexSelected ?? 0].referenceNumber ?? ''
        }
        paymentAmount={data?.[sidesheetIndexSelected ?? 0].amount.amount ?? ''}
        paymentCurrency={data?.[modalIndexSelected ?? 0].amount.currency ?? ''}
        disputeReason={
          data?.[sidesheetIndexSelected ?? 0].dispute?.reason ?? ''
        }
        requester={
          data?.[sidesheetIndexSelected ?? 0].dispute?.createdBy.name ?? ''
        }
        submissionDate={data?.[sidesheetIndexSelected ?? 0].dispute?.createdAt}
      />
      <IndexTableContainer>
        <IndexTable
          itemCount={data?.length || 0}
          selectable={false}
          headings={[
            {
              title: <LeftPaddingWrapper>Invoice Status</LeftPaddingWrapper>,
              id: 'invoice-status',
            },
            { title: 'Due Date' },
            { title: 'Invoice Reference' },
            {
              title: (
                <PaymentAmountWrapper>Payment Amount</PaymentAmountWrapper>
              ),
              id: 'payment-amount',
            },
            { title: 'Actions' },
          ]}
          emptyState={<></>}
        >
          {loading ? loadingRow : rowMarkup}
        </IndexTable>
        {!loading && (data?.length ?? 0) > 0 && (
          <TablePaginationContainer>
            <TablePagination
              currentPage={currentPage}
              pageSize={pageSize}
              totalItems={totalItems}
              onPageChanged={onPageChanged}
            />
          </TablePaginationContainer>
        )}
      </IndexTableContainer>
    </>
  );
};
