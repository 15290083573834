import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    background: ${Neutral.B99};
  }

  *::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${Neutral.B68} !important;
    border-radius: 2px !important;
    border: none !important;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: ${Neutral.B99} !important;
  }
  *::-webkit-scrollbar-track {
    background-color: ${Neutral.B99} !important;
  }
`;
