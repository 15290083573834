import React, { useEffect } from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { useNavigate } from 'react-router-dom';

import { useAuthToken } from '../../../../hooks/useAuthToken';
import { FrameContentContainer, StyledOutlineButton } from './FrameStyle';

export const errorType = {
  LINK_EXPIRED: 'LINK_EXPIRED',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  LINK_INVALID: 'LINK_INVALID',
  UNAUTHORIZED: 'UNAUTHORIZED',
} as const;
const errorOptions = errorType;

interface ErrorFrameProps {
  errorType: keyof typeof errorType;
}

const ErrorFrame = ({ errorType }: ErrorFrameProps) => {
  const { logout } = useAuthToken();
  useEffect(() => {
    if (errorType === errorOptions.TOKEN_EXPIRED) {
      logout();
    }
  }, [errorType, logout]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/login');
  };
  const errorTitle = {
    LINK_EXPIRED: 'Your magic link has expired',
    TOKEN_EXPIRED: 'Session Expired',
    LINK_INVALID: 'Invalid magic link',
    UNAUTHORIZED: 'Login Required',
  };
  const errorDescription = {
    LINK_EXPIRED:
      'Please go back to the landing page and request a new magic link',
    TOKEN_EXPIRED:
      'Your session has expired for security reasons. To proceed, please log in once more.',
    LINK_INVALID: 'Please click the magic link from your email',
    UNAUTHORIZED: 'You must be logged in to view this page.',
  };

  return (
    <FrameContentContainer>
      <Typography as="div" variant="subtitle1">
        {errorTitle[errorType]}
      </Typography>
      <Typography as="div" variant="subtitle2">
        {errorDescription[errorType]}
      </Typography>
      <div />
      <StyledOutlineButton onClick={handleClick}>
        Back to Login
      </StyledOutlineButton>
    </FrameContentContainer>
  );
};

export default ErrorFrame;
