import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import { getGraphqlClient } from '../../clients/graphql';
import {
  ResolveMagicLinkMutation,
  useResolveMagicLinkMutation,
} from '../../generated/graphql';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useNonInitialEffect } from '../../hooks/useNonInitialEffect';
import ErrorFrame, {
  errorType as ErrorOptions,
} from './components/Frame/ErrorFrame';
import { LoadingLayout } from './components/Layout/LoadingLayout';
import { LoginLayout } from './components/Layout/LoginLayout';
import { GreyBackgroundOverlay } from './components/Layout/LoginLayoutStyle';

const ANIMATION_DURATION = 4; // in seconds

interface MagicLinkPageProps {
  pageTitle: string;
  metaDescription: string;
}

const MagicLinkPage = ({ pageTitle, metaDescription }: MagicLinkPageProps) => {
  const graphqlClient = getGraphqlClient();
  const location = useLocation();
  const { errorType } = (location.state ?? { errorType: null }) as {
    errorType: 'TOKEN_EXPIRED' | 'UNAUTHORIZED' | null;
  };
  const navigate = useNavigate();
  const { login } = useAuthToken();
  const [magicLink, setMagicLink] = useState<string | null>(null);
  const [isInvalidQueryParams, setIsInvalidQueryParams] =
    useState<boolean>(false);

  const [timeRemaining, setTimeRemaining] =
    useState<number>(ANIMATION_DURATION);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime > 0) {
          return prevTime - 0.1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 100);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const magicLink = params.get('key');
    if (magicLink) {
      setMagicLink(magicLink);
    } else {
      setIsInvalidQueryParams(true);
    }
  }, [location]);

  const { isError, data, mutate } = useResolveMagicLinkMutation<
    ResolveMagicLinkMutation,
    Error
  >(graphqlClient);

  useNonInitialEffect(() => {
    if (magicLink) {
      mutate({
        code: magicLink,
      });
    }
  }, [magicLink]);

  useEffect(() => {
    if (data && data.resolveMagicLink) {
      login(data.resolveMagicLink.accessToken);
      setTimeout(() => navigate('/'), timeRemaining * 1000);
    }
  }, [data, login, navigate, timeRemaining]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      {isError || isInvalidQueryParams || errorType !== null ? (
        <>
          <GreyBackgroundOverlay />
          <LoginLayout>
            <ErrorFrame
              errorType={
                isError
                  ? ErrorOptions.LINK_EXPIRED
                  : errorType || ErrorOptions.LINK_INVALID
              }
            />
          </LoginLayout>
        </>
      ) : (
        <LoadingLayout />
      )}
    </>
  );
};

export default MagicLinkPage;
