import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal, ModalProps } from 'glints-aries/lib/@next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { getGraphqlClient } from '../../../../../clients/graphql';
import {
  CreateUserMutation,
  UpdateUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
} from '../../../../../generated/graphql';
import { errorType } from '../../../../../modules/Error/constants';
import { alertContent, roleDetailsMapping } from '../../constants';
import { AddEditUserModalProps, FormMode, Role, User } from '../../interfaces';
import { ModalContentContainer } from '../../RolesPermissionsTabStyle';
import { RoleCardOptionList } from './RoleCardOptionList';
import { UserFields, validationSchema } from './UserFields';

export const AddEditUserModal = ({
  isOpen,
  onClose,
  user,
  formMode,
  currentRole,
  updateCurrentUsers,
  users,
  updateFormMode,
  refetchUsersCountPerRole,
  updateShowAlert,
}: AddEditUserModalProps) => {
  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();
  const [step, setStep] = useState(!currentRole ? 1 : 2);
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(
    currentRole
  );

  const defaultValues = {
    name: user?.name ?? '',
    department: user?.department ?? '',
    email: user?.email ?? '',
    jobTitle: user?.jobTitle ?? '',
    phoneNumber: {
      countryCode: '+65',
      // user?.phoneNumber?.countryCode !== ''
      //   ? user?.phoneNumber?.countryCode
      //   : '+65',
      number: user?.phoneNumber?.number ?? '',
      extension: user?.phoneNumber?.extension ?? '',
    },
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty: isFormDirty, errors },
    reset: resetForm,
    setError,
    watch,
  } = useForm<User>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    isLoading: isCreateLoading,
    error: createError,
    mutate: createMutate,
  } = useCreateUserMutation<Error, CreateUserMutation>(graphqlClient);

  const {
    isLoading: isEditLoading,
    error: editError,
    mutate: editMutate,
  } = useUpdateUserMutation<Error, UpdateUserMutation>(graphqlClient);

  const onCreate = async (user: User) => {
    const phoneNumber = user.phoneNumber?.number ? user.phoneNumber : null;
    createMutate(
      {
        contact: { ...user, phoneNumber, roleId: [selectedRole] },
      },
      {
        onSuccess: data => {
          // TODO if created from overview, show Alert,
          // update the count on button and redirect to manage users page
          // if from Manage Users, update list of users and show alert

          if (updateCurrentUsers) {
            const updatedUsers: User[] = [
              data.addContact as User,
              ...(users || []),
            ];
            updateCurrentUsers(updatedUsers);
          }

          if (updateFormMode) {
            updateFormMode({
              mode: FormMode.VIEW,
              userId: undefined,
              isOpen: false,
            });
          }

          if (refetchUsersCountPerRole) {
            refetchUsersCountPerRole();
          }

          updateShowAlert({
            shouldShowAlert: true,
            formMode: FormMode.CREATE,
            alertContent: `${data?.addContact?.name} ${
              alertContent[FormMode.CREATE]
            } ${roleDetailsMapping[selectedRole as Role].role}`,
          });
          resetForm();
          onClose();
        },
        onError: err => {
          const emailExistsErr = 'email already exists';

          const doesEmailExists = err.message.includes(emailExistsErr);

          if (doesEmailExists) {
            setError('email', { message: 'This email already exists' });
          }

          if (err.toString().substring(28, 31) === '401') {
            localStorage.removeItem('glintsMTS');
            navigate('/magic-link', {
              state: { errorType: errorType.TOKEN_EXPIRED },
            });
          }
        },
      }
    );
  };

  const onEdit = async (userFields: User) => {
    const phoneNumber = userFields.phoneNumber?.number
      ? userFields.phoneNumber
      : null;
    editMutate(
      {
        contact: { ...userFields, phoneNumber, roleId: [selectedRole] },
        id: user?.id,
      },
      {
        onSuccess: data => {
          if (updateCurrentUsers) {
            let updatedUsers = [];
            if (currentRole !== selectedRole) {
              updatedUsers = users?.filter(
                user => user.id !== data.updateContact?.id
              ) as User[];
            } else {
              updatedUsers = users?.map(user => {
                if (user.id === data.updateContact?.id) {
                  return data?.updateContact;
                }

                return user;
              }) as User[];
            }
            updateCurrentUsers(updatedUsers);
          }

          if (updateFormMode) {
            updateFormMode({
              mode: FormMode.VIEW,
              userId: undefined,
              isOpen: false,
            });
          }

          updateShowAlert({
            shouldShowAlert: true,
            formMode: FormMode.EDIT,
            alertContent: `${data?.updateContact?.name} ${
              alertContent[FormMode.EDIT]
            }`,
          });
          resetForm();
          onClose();
        },
        onError: err => {
          const emailExistsErr = 'email already exists';

          const doesEmailExists = err.message.includes(emailExistsErr);

          if (doesEmailExists) {
            setError('email', { message: 'This email already exists' });
          }

          if (err.toString().substring(28, 31) === '401') {
            localStorage.removeItem('glintsMTS');
            navigate('/magic-link', {
              state: { errorType: errorType.TOKEN_EXPIRED },
            });
          }
        },
      }
    );
  };

  const handleClose = () => {
    if (currentRole) {
      onClose();
      resetForm();
      setSelectedRole(currentRole);
      setStep(2);

      return;
    }

    setStep(1);
    setSelectedRole(undefined);
    onClose();
    resetForm();
  };

  const handleSelectRole = (newRole: Role) => {
    setSelectedRole(newRole);
  };

  const handleSave = () => {
    if (formMode?.mode === FormMode.EDIT) {
      handleSubmit(dataFields => onEdit(dataFields))();
      return;
    }
    handleSubmit(dataFields => onCreate(dataFields))();
  };

  const handleChangeRole = () => {
    setStep(1);
  };

  const modalArgs: Record<number, Partial<ModalProps>> = {
    1: {
      header: 'Assign a Role to New User',
      primaryAction: {
        label: 'Next',
        action: () => setStep(step + 1),
        disabled: !selectedRole,
      },
      secondaryAction: {
        label: 'Cancel',
        action: handleClose,
      },
      onClose: handleClose,
    },
    2: {
      header:
        currentRole && formMode?.mode === FormMode.EDIT
          ? `Edit ${user?.name}`
          : `Add New ${roleDetailsMapping[selectedRole as Role]?.role}`,
      showBackButton: Boolean(!currentRole),
      onBack: () => setStep(1),
      primaryAction: {
        label: 'Save',
        disabled: !isFormDirty,
        action: handleSave,
      },
      secondaryAction: {
        label: 'Cancel',
        action: handleClose,
      },
      onClose: handleClose,
    },
  };

  const formSteps: Record<number, React.ReactNode> = {
    1: (
      <RoleCardOptionList
        control={control}
        selectedRole={selectedRole || ''}
        onSelectRole={handleSelectRole}
      />
    ),
    2: (
      <UserFields
        role={selectedRole}
        control={control}
        formMode={formMode?.mode}
        handleChangeRole={handleChangeRole}
      />
    ),
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} {...modalArgs[step]}>
      <ModalContentContainer>{formSteps[step]}</ModalContentContainer>
    </Modal>
  );
};
