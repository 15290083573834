import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate } from 'react-router-dom';

import { getGraphqlClient } from '../../clients/graphql';
import {
  SendMagicLinkMutation,
  useSendMagicLinkMutation,
} from '../../generated/graphql';
import { useAuthToken } from '../../hooks/useAuthToken';
import { errorType } from '../../modules/Error/constants';
import ConfirmationFrame from './components/Frame/ConfirmationFrame';
import LandingFrame from './components/Frame/LandingFrame';
import { LoginLayout } from './components/Layout/LoginLayout';

interface LoginPageProps {
  pageTitle: string;
  metaDescription: string;
}

const LoginPage = ({ pageTitle, metaDescription }: LoginPageProps) => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [cooldown, setCooldown] = useState<number>(0); // in seconds
  const { isAuthenticated } = useAuthToken();

  const { isLoading, isError, error, mutate } = useSendMagicLinkMutation<
    SendMagicLinkMutation,
    Error
  >(graphqlClient);
  const handleSend = (email: string) => {
    mutate(
      {
        email: email,
      },
      {
        onSuccess: () => {
          setEmail(email);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (err: any) => {
          if (err.toString().substring(28, 31) === '401') {
            localStorage.removeItem('glintsMTS');
            navigate('/magic-link', {
              state: { errorType: errorType.TOKEN_EXPIRED },
            });
          }
          if (err?.response?.errors[0]?.message === 'too often') {
            const nextPossibleRequest =
              err?.response?.errors[0]?.extensions?.next_possible_request;
            const nextPossibleRequestTime = new Date(nextPossibleRequest);
            const currentTime = new Date();
            const timeDifference =
              nextPossibleRequestTime.getTime() - currentTime.getTime();
            const secondsDifference = Math.ceil(timeDifference / 1000);
            if (secondsDifference > 0) setCooldown(secondsDifference);
          }
        },
      }
    );
  };

  const navigateToLandingFrame = () => {
    setEmail('');
    setCooldown(0);
  };

  if (isAuthenticated) return <Navigate to="/" />;
  return (
    <LoginLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      {email === '' ? (
        <LandingFrame
          onSend={handleSend}
          isLoading={isLoading}
          error={isError ? error.toString() : ''}
          cooldown={cooldown}
        />
      ) : (
        <ConfirmationFrame
          email={email}
          onBack={navigateToLandingFrame}
          onSend={handleSend}
          isLoading={isLoading}
          error={isError ? error.toString() : ''}
        />
      )}
    </LoginLayout>
  );
};

export default LoginPage;
