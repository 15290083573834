import { Checkbox } from 'glints-aries/lib/@next';
import { space4 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

interface FormCheckBoxInputProps {
  height: string;
}

export const FormCheckboxContainer = styled.div<FormCheckBoxInputProps>`
  height: ${props => props.height};
  svg {
    height: 24px;
    width: 24px;
  }
`;

export const StyledCheckboxInput = styled(Checkbox)`
  margin-bottom: ${space4};
  margin-top: 0;
`;
