import { GraphQLClient } from 'graphql-request';

import { GRAPHQL_ENDPOINT } from '../constants/constants';

export const getGraphqlClient = () => {
  const authToken = () => localStorage.getItem('glintsMTS') || null;
  const hasAuthToken = authToken() !== null;
  const headers = hasAuthToken
    ? { headers: { authorization: `Bearer ${authToken()}` } }
    : undefined;

  const graphqlClient = new GraphQLClient(GRAPHQL_ENDPOINT, headers);

  return graphqlClient;
};
