export const getInitials = (str: string) => {
  if (!str) {
    return;
  }

  const splitStr = str.split(' ');
  const initials = splitStr.map(s => s.charAt(0).toUpperCase());

  return initials.join('');
};

export const formatMoney = ({
  amount,
  locale,
  options = { minimumFractionDigits: 2 },
}: {
  amount: string;
  locale?: string | string[];
  options?: Intl.NumberFormatOptions;
}) => new Intl.NumberFormat(locale, options).format(parseFloat(amount));
