import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space4, space12, space16, space24, space32 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const CUSTOM_BREAKPOINT = "900px";

export const SubheaderFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${space4} 0 ${space32};
  align-items: center;
  gap: ${space4};
`;

// not needed for now
export const StyledBanner = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${space16};
  margin-bottom: ${space32};
  gap: ${space16};

  background-color: ${Blue.S08};
  border: 1px solid ${Blue.S99};
  border-radius: ${borderRadius8};
`;

// not needed for now
export const BannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space12};
`;

export const PaymentCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: ${space24};

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const PaymentCardTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentCardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${space16};
  padding: 20px ${space24};

  width: 300px;
  border-radius: ${borderRadius8};
  background-color: ${Neutral.B100};
  box-shadow: 0px 1px 2px 0px rgba(71, 71, 71, 0.20), 0px 0px 5px 0px rgba(71, 71, 71, 0.10);

  &[data-selected='true'] {
    border: 2px solid ${Blue.S99};
  }

  &[data-disabled='true'] {
    > div:not(#no-disable-overlay) {
      opacity: 0.3;
    }
    svg {
      opacity: 0.3;
    }
  }

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: calc(100% - 48px);
  }
`;

export const BottomSection = styled.div`
  display: flex;
`;
