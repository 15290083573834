import {
  FormMode,
  Permission,
  PermissionFeature,
  Role,
  UserRoleCount,
} from './interfaces';

export const ROLE_UNASSIGNED = 'UNASSIGNED';
export type RoleUnassigned = typeof ROLE_UNASSIGNED;

export const roleDetailsMapping: Record<
  Role | RoleUnassigned,
  { role: string; roleDetails: string }
> = {
  [Role.CompanyAdmin]: {
    role: 'Company Admin',
    roleDetails:
      'The Company Admin can manage all the aspects of the company: everything from members, billing and other company-wide settings.',
  },
  [Role.PeopleManager]: {
    role: 'People Manager',
    roleDetails:
      'The People Team Manager can manage people and their contracts (create, edit, end) and add items to their payroll.',
  },
  [Role.Payer]: {
    role: 'Payer',
    roleDetails:
      'The payer can add items to payroll, receive notifications related to invoicing, and process payments.',
  },
  [Role.Viewer]: {
    role: 'Viewer',
    roleDetails:
      'Viewers can view personal and professional sensitive data, such as contract details, compensation and benefits.',
  },
  [ROLE_UNASSIGNED]: {
    role: 'Unassigned',
    roleDetails:
      'Please review the user profiles listed here and assign them appropriate roles. Complete any missing data to enable platform usage for them. Exercise caution: avoid editing or deleting profiles if you are unsure of the changes, as these profiles are integrated with our CRMS. For guidance or assistance, please contact your Account Manager.',
  },
};

export const roleCountMapping: Record<Role | RoleUnassigned, UserRoleCount> = {
  [Role.CompanyAdmin]: UserRoleCount.ADMIN,
  [Role.PeopleManager]: UserRoleCount.PEOPLE_MANAGER,
  [Role.Payer]: UserRoleCount.PAYER,
  [Role.Viewer]: UserRoleCount.VIEWER,
  [ROLE_UNASSIGNED]: UserRoleCount.NONE,
};

export const roleRouteMapping: Record<Role | RoleUnassigned, string> = {
  [Role.CompanyAdmin]: 'company-admin',
  [Role.PeopleManager]: 'people-manager',
  [Role.Payer]: 'payer',
  [Role.Viewer]: 'viewer',
  [ROLE_UNASSIGNED]: 'unassigned',
};

export const permissionDetails = {
  [PermissionFeature.COMPANY_SETTINGS]: {
    [Permission.READ]:
      'View company information inclluding registered business address, billing entity name and other company-specific information',
  },
  [PermissionFeature.MANAGED_TALENTS]: {
    [Permission.READ]:
      'View all details of managed talents, including contract type, job start/end date, and contract expiry date among others',
  },
  [PermissionFeature.TIME_OFF_TRACKER]: {
    [Permission.READ]:
      'View all details related to time off tracking, including time off request statuses, leave types, requested dates, and leave balances',
  },
  [PermissionFeature.ATTENDANCE_LOG]: {
    [Permission.READ]:
      'View detailed attendance records, including presence, absence, and other attendance-related information',
  },
  [PermissionFeature.INVOICING]: {
    [Permission.READ]:
      'View details of invoices including issued date, due date, payment amount, status',
    [Permission.WRITE]:
      'Raise queries to Glints and request a review and adjustment of the invoice',
  },
};

export type RolePermissionFeatureType = {
  role: string;
  permissions: Record<
    PermissionFeature,
    Partial<Record<Partial<Permission>, boolean>>
  >;
};

export type RolePermissionsDetailsMappingType = Record<
  Role,
  RolePermissionFeatureType
>;

export const rolePermissionsDetailsMapping: RolePermissionsDetailsMappingType =
  {
    [Role.CompanyAdmin]: {
      role: 'Company Admin',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.MANAGED_TALENTS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.TIME_OFF_TRACKER]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.ATTENDANCE_LOG]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.INVOICING]: {
          [Permission.READ]: true,
          [Permission.WRITE]: true,
        },
      },
    },
    [Role.PeopleManager]: {
      role: 'People Manager',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.MANAGED_TALENTS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.TIME_OFF_TRACKER]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.ATTENDANCE_LOG]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.INVOICING]: {
          [Permission.READ]: false,
          [Permission.WRITE]: false,
        },
      },
    },
    [Role.Payer]: {
      role: 'Payer',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.MANAGED_TALENTS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.TIME_OFF_TRACKER]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.ATTENDANCE_LOG]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.INVOICING]: {
          [Permission.READ]: true,
          [Permission.WRITE]: true,
        },
      },
    },
    [Role.Viewer]: {
      role: 'Viewer',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.MANAGED_TALENTS]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.TIME_OFF_TRACKER]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.ATTENDANCE_LOG]: {
          [Permission.READ]: true,
        },
        [PermissionFeature.INVOICING]: {
          [Permission.READ]: true,
          [Permission.WRITE]: false,
        },
      },
    },
  };

export const COUNTRY_CODE_OPTIONS = [
  {
    id: '1',
    label: '+852',
    value: '+852',
  },
  {
    id: '2',
    label: '+62',
    value: '+62',
  },
  {
    id: '3',
    label: '+60',
    value: '+60',
  },
  {
    id: '4',
    label: '+65',
    value: '+65',
  },
  {
    id: '5',
    label: '+886',
    value: '+886',
  },
  {
    id: '6',
    label: '+84',
    value: '+84',
  },
];

export const alertContent: Partial<Record<FormMode, string>> = {
  [FormMode.CREATE]: 'has been added as a',
  [FormMode.EDIT]: 'has been updated',
  [FormMode.DELETE]: 'has been removed from this platform',
};
