import { TabModel } from 'glints-aries/lib/@next';
import { Option } from 'glints-aries/lib/@next/Menu';

import {
  EmploymentStatus,
  TimeOffCategory,
  TimeOffRequestStatus,
} from '../../generated/graphql';
import { requestStatusText } from './interfaces';

export const tabs: TabModel[] = [
  { id: 'time-off-requests', content: 'Time Off Requests' },
  { id: 'time-off-balance', content: 'Time Off Balance' },
];

const requestStatusOptions = Object.values(TimeOffRequestStatus).map(
  status => ({ id: status, label: requestStatusText[status], value: status })
);

export const selectRequestStatusOptions = [
  {
    id: 'ALL',
    label: 'All statuses',
    value: 'ALL',
  },
  ...requestStatusOptions,
];

export const PAGE_SIZE = 20;

export const timeOffTypeMapping: Record<TimeOffCategory, string> = {
  [TimeOffCategory.AnnualLeave]: 'Annual Leave',
  [TimeOffCategory.SickLeave]: 'Sick Leave',
  [TimeOffCategory.Others]: 'Others',
};

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const alertContent = {
  [AlertType.SUCCESS]: 'File successfully downloaded',
  [AlertType.ERROR]: 'Export failed. Retry later.',
};

export const TALENT_OPTIONS: Option[] = [
  {
    id: '1',
    label: 'Active',
    value: EmploymentStatus.Active,
  },
  {
    id: '2',
    label: 'End of Contract',
    value: EmploymentStatus.ContractEnded,
  },
  {
    id: '3',
    label: 'Departed',
    value: EmploymentStatus.ResignedOrTerminated,
  },
];

export const JS_MONTH_OFFSET = 1;
