import { space4 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 80px);
`;

export const TextContainer = styled.div`
  padding-bottom: 16px;

  @media (max-width: 576px) {
    width: 250px;
  }
`;

export const PlanetIconContainer = styled.div`
  padding-top: 32px;

  svg {
    height: 135px;
    width: 159px;
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
  margin-top: 32px;
  width: 158px;
  height: 86px;
`;

export const PlanetSVGContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 36px;
  animation: floating 4s ease-in-out 0s infinite alternate;

  svg {
    height: 66px;
    width: 84px;
  }

  @keyframes floating {
    0% {
      transform: rotate(-2deg) translateX(2px);
    }
    50% {
      transform: rotate(-4deg) translateX(-2px);
    }
    100% {
      transform: rotate(-16deg) translateX(2px);
    }
  }
`;

export const SatelitesSVGContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  animation: spinning 12s linear 0s infinite forwards;

  svg {
    height: 86px;
    width: 158px;
  }

  @keyframes spinning {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ContactUsTextContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 24px;
  gap: ${space4};

  @media (max-width: 576px) {
    display: block;
    width: 300px;
  }
`;
