import React from 'react';
import { DataTable, SideSheet, Tag, Typography } from 'glints-aries/lib/@next';
import { TableHeading } from 'glints-aries/lib/@next/DataTable';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { DaysOffContainer, DaysOffTagsContainer, ScheduleDataTableContainer, WorkDayTitleContainer } from './ScheduleSideSheetStyle';

interface ScheduleSideSheetProps {
  isOpen: boolean;
  onClose: () => void;
  fullName?: string;
  scheduleData: {
    day: string;
    checkIn: string;
    checkOut: string;
  }[];
  daysOff: string[];
}

export const ScheduleSideSheet = ({ isOpen, fullName, onClose, scheduleData, daysOff }: ScheduleSideSheetProps) => {
  const scheduleHeadings: TableHeading[] = [
    { title: "Day" },
    { title: "Check in" },
    { title: "Check out" },
  ];

  const rowMarkup = scheduleData.map((data, index) => (
    <DataTable.Row key={index}>
      <DataTable.Cell verticalAlign="center">
        <Tag>
          {data.day}
        </Tag>
      </DataTable.Cell>
      <DataTable.Cell verticalAlign="center">
        {data.checkIn} SGT
      </DataTable.Cell>
      <DataTable.Cell verticalAlign="center">
        {data.checkOut} SGT
      </DataTable.Cell>
    </DataTable.Row>
  ));

  const daysOffSection = (
    <DaysOffContainer>
      <Typography variant="body2" color={Neutral.B18} as="span">
      Day Off
      </Typography>
      <DaysOffTagsContainer>
        {daysOff.map((day, index) => (
          <Tag key={index}>
            {day}
          </Tag>
        ))}
      </DaysOffTagsContainer>
    </DaysOffContainer>
  );

  return (
    <>
      <SideSheet
        isOpen={isOpen}
        title={`${fullName}'s Schedule`}
        onClose={onClose}
        showBackButton={false}
      >
        <WorkDayTitleContainer>
          <Typography variant="body2" color={Neutral.B18} as="span">
            Work Day
          </Typography>
        </WorkDayTitleContainer>
        <ScheduleDataTableContainer>
          <DataTable headings={scheduleHeadings} style={{ border: 'none' }}>
            {rowMarkup}
          </DataTable>
          {daysOffSection}
        </ScheduleDataTableContainer>
      </SideSheet>
    </>
  );
};
