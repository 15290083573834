import React from 'react';

import { StyledBox } from './BoxStyle';

interface BoxProps {
  children: React.ReactNode;
}

export const Box = ({ children }: BoxProps) => (
  <StyledBox>{children}</StyledBox>
);
