import { Breakpoints } from "glints-aries/lib/@next";
import { Blue, Neutral } from "glints-aries/lib/@next/utilities/colors";
import { space8, space12, space24 } from "glints-aries/lib/@next/utilities/spacing";
import styled from "styled-components";

export const OptionContainer = styled.div<{ collapseWidth: string }>`
  display: flex;
  flex-direction: row;
  margin: 0 ${space12};
  padding: ${space12};
  gap: ${space8};

  cursor: pointer;
  color: ${Neutral.B18};
  width: 176px;
  border-radius: 50px;

  svg {
    fill: ${Neutral.B40};
    height: 20px;
    width: 20px;
  }

  &:hover {
    box-shadow: 0px 2px 16px 0px rgba(45, 45, 45, 0.08), 0px 0px 0px 0px rgba(0, 0, 0, 0.10);
  }

  &[data-selected='true'] {
    background-color: ${Blue.S08};
    border: 1px solid ${Blue.S54};
    color: ${Blue.S99};
    cursor: default;

    svg {
      fill: ${Blue.S99};
    }

    &:hover {
      box-shadow: none;
    }
  }

  &[data-collapsed='true'] {
    width: 20px;
    transition: width 0.3s ease;

    & > span {
      display: none;
    }

    &:hover {
      width: ${props => props.collapseWidth};
      box-shadow: 0px 2px 16px 0px rgba(45, 45, 45, 0.08), 0px 31px 41px 0px rgba(45, 45, 45, 0.20);
      background-color: ${Neutral.B100};
      &[data-selected='true'] {
        background-color: ${Blue.S08};
      }

      & > span {
        display: block;
        margin-right: ${space8};
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
    border-radius: 0px;
    border: none;
    background-color: ${Neutral.B99};
    margin: 0;
    padding: ${space12} ${space24};

    &:hover {
      background-color: ${Neutral.B95};
      box-shadow: none;
    }

    &[data-selected='true'] {
      border: none;
      &:hover {
        background-color: ${Blue.S08};
      }
    }
  }
`;
