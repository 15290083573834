import React from 'react';
import { Icon, Typography } from 'glints-aries/lib/@next';

import { ButtonContainer } from './LogoutButtonStyle';

interface MyProfileButtonProps {
  isCollapsed: boolean;
  handleClick(): void;
}

export const MyProfileButton = ({
  isCollapsed,
  handleClick,
}: MyProfileButtonProps) => (
  <ButtonContainer data-collapsed={isCollapsed} onClick={handleClick}>
    <Icon name="ri-account-circle-line" />
    <Typography as="span" variant="button">
      My Profile
    </Typography>
  </ButtonContainer>
);
