export const timeZone = {
  INDONESIA_BATAM: 'BT',
  INDONESIA_JAKARTA: 'ID',
  SINGAPORE: 'SG',
  VIETNAM: 'VN',
} as const;

type TypeTimeZone = typeof timeZone;
export type TimeZone = TypeTimeZone[keyof TypeTimeZone];

export const timeZoneAbbrMapping: Record<TimeZone, string> = {
  [timeZone.INDONESIA_BATAM]: 'WIB',
  [timeZone.INDONESIA_JAKARTA]: 'WIB',
  [timeZone.SINGAPORE]: 'SGT',
  [timeZone.VIETNAM]: 'ICT',
};

export const timeZoneStringMapping: Record<TimeZone, string> = {
  [timeZone.INDONESIA_BATAM]: 'Asia/Jakarta',
  [timeZone.INDONESIA_JAKARTA]: 'Asia/Jakarta',
  [timeZone.SINGAPORE]: 'Asia/Singapore',
  [timeZone.VIETNAM]: 'Asia/Ho_Chi_Minh',
};
