import React, { ReactNode } from 'react';
import { Badge, Card, SideSheet, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { EmploymentStatus, Money } from '../../../../generated/graphql';
import {
  flagMapping,
  hubMapping,
} from '../../../../modules/ManagedTalents/constants';
import { HubCode } from '../../../../modules/ManagedTalents/interface';
import { computeTenure } from '../../../../utils/date';
import {
  CardData,
  CardFlexDataContainer,
  CardRow,
  CountryContainer,
  FlagContainer,
  SidesheetContentContainer,
} from './HubberDetailsSideSheetStyle';

type HubberDetailsSideSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  fullName?: string;
  status?: EmploymentStatus;
  hub?: string;
  email?: string | null;
  phone?: string | null;
  seatId?: string; // TODO - no data from api yet, currently is hidden
  department?: string; // TODO - no data from api yet, currently is hidden
  jobTitle?: string;
  jobStartDate: string;
  jobEndDate?: string;
  contractEndDate?: string;
  salary?: Money | null;
};

export const HubberDetailsSideSheet = ({
  isOpen,
  onClose,
  ...details
}: HubberDetailsSideSheetProps) => {
  const { tenureYears, tenureMonths } = computeTenure({
    startDate: details.jobStartDate,
    endDate: details.jobEndDate,
  });

  const CardHeading = ({ title }: { title: string }) => (
    <Typography variant="subtitle2" color={Neutral.B18} style={{ margin: 0 }}>
      {title}
    </Typography>
  );

  const SingleData = ({
    label,
    value,
    leftMargin = false,
    salary = false,
  }: {
    label: string;
    value?: string | ReactNode;
    leftMargin?: boolean;
    salary?: boolean;
  }) => (
    <CardData data-left-margin={leftMargin}>
      <Typography as="div" variant="caption" color={Neutral.B40}>
        {label}
      </Typography>
      {typeof value === 'string' || value === null || value === undefined ? (
        <Typography
          as="div"
          variant="body1"
          color={value ? Neutral.B18 : Neutral.B85}
        >
          {value ? value : 'Unknown'}
          {value && salary && (
            <Typography as="div" variant="overline" color={Neutral.B40}>
              per mo
            </Typography>
          )}
        </Typography>
      ) : (
        value
      )}
    </CardData>
  );

  const statusBadge = (
    <CardFlexDataContainer>
      <Badge
        status={
          details.status === EmploymentStatus.Active ? 'success' : 'neutral'
        }
      >
        {details.status === EmploymentStatus.Active ? 'Active' : 'Inactive'}
      </Badge>
    </CardFlexDataContainer>
  );

  const talentHubLocation = details.hub && (
    <CardFlexDataContainer>
      <FlagContainer>{flagMapping[details.hub as HubCode]}</FlagContainer>
      <CountryContainer>
        <Typography as="span" variant="body1" color={Neutral.B18}>
          {details.hub}
        </Typography>
        {details.hub === 'ID' ||
          (details.hub === 'BT' && (
            <Typography as="span" variant="overline" color={Neutral.B40}>
              &#40;{hubMapping[details.hub as HubCode]}&#41;
            </Typography>
          ))}
      </CountryContainer>
    </CardFlexDataContainer>
  );

  return (
    <SideSheet
      isOpen={isOpen}
      title="Managed Talent's Details"
      onClose={onClose}
      showBackButton={false}
    >
      <SidesheetContentContainer>
        <Card heading={<CardHeading title="Basic Information" />}>
          <CardRow>
            <SingleData label="Full Name" value={details.fullName} />
            <SingleData label="Status" value={statusBadge} leftMargin={true} />
          </CardRow>
          <CardRow>
            <SingleData
              label="Tenure"
              value={`${tenureYears} yr ${tenureMonths} mo`}
            />
            <SingleData
              label="TalentHub Location"
              value={talentHubLocation}
              leftMargin={true}
            />
          </CardRow>
          <CardRow>
            <SingleData label="Email" value={details.email} />
            <SingleData label="Phone" value={details.phone} leftMargin={true} />
          </CardRow>
        </Card>
        <Card heading={<CardHeading title="Contract Details" />}>
          <CardRow>
            <SingleData label="Job Title" value={details.jobTitle} />
            <SingleData
              label="Job Start Date"
              value={
                <FormattedDate
                  date={details.jobStartDate}
                  textVariant="body1"
                />
              }
              leftMargin={true}
            />
          </CardRow>
          <CardRow>
            <SingleData
              label="Job End Date"
              value={
                <FormattedDate
                  date={details.jobEndDate}
                  textVariant="body1"
                  noDataText="Still present"
                />
              }
            />
            <SingleData
              label="Contract Expiry Date"
              value={
                <FormattedDate
                  date={details.contractEndDate}
                  textVariant="body1"
                  noDataText="N/A"
                />
              }
              leftMargin={true}
            />
          </CardRow>
        </Card>
      </SidesheetContentContainer>
    </SideSheet>
  );
};
