import React, { useState } from 'react';
import { InlineError, Select } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  Control,
  ControllerRenderProps,
  FieldValues,
  useController,
} from 'react-hook-form';

import {
  LabelContainer,
  StyledLabel,
  StyledTextInput,
} from '../../../../../components/FormTextInput/FormTextInputStyle';
import { Phone } from '../../../../../generated/graphql';
import {
  PhoneInputContainer,
  PhoneInputFieldContainer,
  StyledExtTextInput,
  StyledNumberTextInput,
  StyledSelectContainer,
} from '../../../components/CardStyle';
import { COUNTRY_CODE_OPTIONS } from '../../constants';

interface PhoneInputProps {
  control: Control;
  label?: string;
  value?: Phone;
}

const CountryCodeSelect = ({
  selectedCountryCode,
  field,
}: {
  selectedCountryCode?: string;
  field: ControllerRenderProps<FieldValues, 'phoneNumber.countryCode'>;
}) => {
  const [selected, setSelected] = useState(
    selectedCountryCode || COUNTRY_CODE_OPTIONS[3].value
  );

  const handleSelect = ({ value }: { value: string }) => {
    setSelected(value);
    field.onChange(value);
  };

  return (
    <StyledSelectContainer>
      <Select
        name="phoneNumber.countryCode"
        options={COUNTRY_CODE_OPTIONS}
        onSelect={handleSelect}
        selectedValues={[selected]}
        width="100%"
        zIndexOverride={1000}
        listHeight={152}
      />
    </StyledSelectContainer>
  );
};

export const PhoneTextInput = ({ control, label, value }: PhoneInputProps) => {
  const {
    field: numberField,
    fieldState: { error },
  } = useController({
    name: 'phoneNumber.number',
    control,
  });

  const { field: countryCodeField } = useController({
    name: 'phoneNumber.countryCode',
    control,
  });

  const { field: extensionField } = useController({
    name: 'phoneNumber.extension',
    control,
  });

  return (
    <PhoneInputFieldContainer>
      <LabelContainer>
        <StyledLabel variant="subtitle2" color={Neutral.B18}>
          {label}
        </StyledLabel>
      </LabelContainer>
      <PhoneInputContainer>
        <CountryCodeSelect
          selectedCountryCode={value?.countryCode || countryCodeField.value}
          field={countryCodeField}
        />
        <StyledNumberTextInput>
          <StyledTextInput
            onChange={numberField.onChange}
            onBlur={numberField.onBlur}
            value={value?.number || numberField.value}
            name="phoneNumber.number"
            ref={numberField.ref}
            placeholder="Enter phone number"
          />
        </StyledNumberTextInput>
        <StyledExtTextInput>
          <StyledTextInput
            onChange={extensionField.onChange}
            onBlur={extensionField.onBlur}
            value={value?.extension || extensionField.value}
            name="phoneNumber.extension"
            ref={extensionField.ref}
            placeholder="ext. #"
          />
        </StyledExtTextInput>
      </PhoneInputContainer>
      {error?.message && <InlineError text={error?.message} />}
    </PhoneInputFieldContainer>
  );
};
