import React from 'react';
import {
  Badge,
  EmptyState,
  Icon,
  PaginationProps,
  SkeletonImageSquare,
  Typography,
} from 'glints-aries/lib/@next';
import { DataTable, TableHeading } from 'glints-aries/lib/@next/DataTable';
import { IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { ComponentAction } from 'glints-aries/lib/types/componentAction';

import { ReactComponent as NebulaSVG } from '../../../../assets/images/nebula.svg';
import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { StatusIndicator } from '../../../../components/StatusIndicator/StatusIndicator';
import { TablePagination } from '../../../../components/TablePagination/TablePagination';
import {
  EmploymentStatus,
  SortOrder,
  TimeOffCategory,
} from '../../../../generated/graphql';
import { roundDownNearestHalf } from '../../../../utils/math';
import { TimeZone } from '../../../../utils/timeZone';
import { timeOffTypeMapping } from '../../constants';
import {
  requestStatusBadgeMapping,
  requestStatusText,
  TimeOffRequest,
} from '../../interfaces';
import {
  HubberNameFlexContainer,
  HubberNameWrapper,
  LeftPaddingCell,
  RequestedTimeContainer,
  RightPaddingCell,
  SortButton,
  StyledDataTable,
  TableContainer,
  TablePaginationContainer,
  TimeOffTypeContainer,
} from '../TimeOffRequestsTabStyle';

interface TimeOffRequestsTableProps extends PaginationProps {
  timeOffRequests?: TimeOffRequest[];
  loading: boolean;
  noSearchResults?: boolean;
  onResetSearch(): void;
  sortOrder: SortOrder;
  onSort(): void;
  isNoHubbers: boolean;
  onResetAll(): void;
}

export const TimeOffRequestsTable = ({
  loading,
  onPageChanged,
  timeOffRequests,
  currentPage,
  pageSize,
  totalItems,
  sortOrder,
  onSort,
  isNoHubbers,
  onResetAll,
}: TimeOffRequestsTableProps) => {
  const sortDirection: Record<SortOrder, Partial<IconNames>> = {
    [SortOrder.Asc]: 'ri-arrow-up-line',
    [SortOrder.Desc]: 'ri-arrow-down-line',
  };

  const headings: TableHeading[] = [
    {
      title: (
        <LeftPaddingCell>
          <RequestedTimeContainer>
            <SortButton onClick={onSort}>
              <Icon name={sortDirection[sortOrder]} height="16px" />
            </SortButton>
            <Typography variant="caption" color={Neutral.B18} as="span">
              Requested Time
            </Typography>
          </RequestedTimeContainer>
        </LeftPaddingCell>
      ),
    },
    { title: 'Request Status' },
    { title: 'Hubber Name' },
    { title: 'Time Off Type' },
    { title: 'Time Off Start Date' },
    { title: 'Time Off End Date' },
    { title: <RightPaddingCell>Time Off Duration</RightPaddingCell> },
  ];

  const timeOffDurationCell = ({
    timeoffDuration,
  }: {
    timeoffDuration: string;
  }) => {
    const parseStringtoFloat = parseFloat(timeoffDuration);
    const stringDay = parseStringtoFloat <= 1 ? 'day' : 'days';
    const duration = roundDownNearestHalf(parseStringtoFloat).toFixed(1);
    return `${duration} ${stringDay}`;
  };

  const timeOffTypeCell = ({
    name,
    category,
  }: {
    name: string;
    category: TimeOffCategory;
  }) => {
    if (category === TimeOffCategory.Others) {
      return (
        <TimeOffTypeContainer>
          <Typography as="span" variant="caption">
            {timeOffTypeMapping[category]}
          </Typography>
          <Typography as="span" variant="subtitle2" color={Neutral.B40}>
            {name}
          </Typography>
        </TimeOffTypeContainer>
      );
    }

    return (
      <Typography as="span" variant="caption">
        {timeOffTypeMapping[category]}
      </Typography>
    );
  };

  const rowMarkup = timeOffRequests?.map(
    (dt: TimeOffRequest, index: number) => (
      <DataTable.Row key={index}>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <LeftPaddingCell>
            <FormattedDate
              date={dt.requestedAt}
              pattern={{
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              timeZone={dt.hubber.hub as TimeZone}
            />
          </LeftPaddingCell>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          {
            <Badge status={requestStatusBadgeMapping[dt.requestStatus]}>
              {requestStatusText[dt.requestStatus]}
            </Badge>
          }
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          <HubberNameFlexContainer>
            <HubberNameWrapper>{dt.hubber.fullName}</HubberNameWrapper>
            <StatusIndicator
              active={dt.hubber.status === EmploymentStatus.Active}
            />
          </HubberNameFlexContainer>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          {timeOffTypeCell({
            name: dt.balance.name,
            category: dt.balance.category,
          })}
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          <FormattedDate
            date={dt.timeoffStartDate}
            timeZone={dt.hubber.hub as TimeZone}
          />
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          <FormattedDate
            date={dt.timeoffEndDate}
            timeZone={dt.hubber.hub as TimeZone}
          />
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          {timeOffDurationCell({ timeoffDuration: dt.timeoffDuration })}
        </DataTable.Cell>
      </DataTable.Row>
    )
  );

  const emptyState = (
    <EmptyState
      title="No Hubbers' Data"
      description="There are currently no hubbers under your company"
      image={<NebulaSVG />}
    />
  );
  const noMatchAction: ComponentAction = {
    label: 'Clear All Filters',
    onClick: onResetAll,
  };
  const noMatchingResultsState = (
    <EmptyState
      title="No Requests"
      description="No results were found based on current filtering conditions."
      basicButtonAction={noMatchAction}
      image={<NebulaSVG />}
    />
  );

  const loadingRow = [...Array(10).keys()].map(n => (
    <DataTable.Row id={`loading-row-${n}`} key={n} position={n}>
      {[...Array(7).keys()].map(n => (
        <DataTable.Cell key={`loading-row-cell-${n}`}>
          <SkeletonImageSquare height="24px" width="100%" />
        </DataTable.Cell>
      ))}
    </DataTable.Row>
  ));

  return (
    <TableContainer>
      <StyledDataTable
        headings={headings}
        emptyState={isNoHubbers ? emptyState : noMatchingResultsState}
      >
        {loading ? loadingRow : rowMarkup}
      </StyledDataTable>
      {!loading && (timeOffRequests?.length ?? 0) > 0 && (
        <TablePaginationContainer>
          <TablePagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChanged={onPageChanged}
          />
        </TablePaginationContainer>
      )}
    </TableContainer>
  );
};
