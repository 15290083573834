import React from 'react';
import { Badge, Collapse, Icon, Typography } from 'glints-aries/lib/@next';
import { Green, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  permissionDetails,
  rolePermissionsDetailsMapping,
} from '../../constants';
import { Permission, PermissionFeature, Role } from '../../interfaces';
import {
  CollapseItemContainer,
  PermissionDetailIconContainer,
  PermissionDetailsContainer,
  SideSheetContentContainer,
  StyledSideSheet,
} from '../../RolesPermissionsTabStyle';

export interface PermissionSideSheetProps {
  role?: Role;
  showSideSheet: boolean;
  updateShowSideSheet(newValue: boolean): void;
}

export const PermissionSideSheet = ({
  role,
  showSideSheet,
  updateShowSideSheet,
}: PermissionSideSheetProps) => {
  const getStatusBadge = (obj: Partial<Record<Partial<Permission>, boolean>>) => {
    const values = Object.values(obj);
    if (values.every((value) => value)) {
      return <Badge status="success">Full Access</Badge>;
    } else if (values.some((value) => value)) {
      return <Badge status="warning">Limited Access</Badge>;
    } else {
      return <Badge status="neutral">No Access</Badge>;
    }
  };

  if (!role) return null;
  return (
    <StyledSideSheet
      isOpen={showSideSheet}
      onClose={() => updateShowSideSheet(false)}
      title={`${rolePermissionsDetailsMapping[role].role}'s Permissions`}
      showBackButton={false}
    >
      <SideSheetContentContainer>
        <Collapse>
          {Object.entries(PermissionFeature).map(([permissionFeature, title]) => (
            <CollapseItemContainer key={`${role}-${permissionFeature}`}>
              <Collapse.Item
                title={title}
                defaultCollapsed={false}
                headerRight={getStatusBadge(rolePermissionsDetailsMapping[role].permissions[
                  PermissionFeature[permissionFeature as keyof typeof PermissionFeature]])}
              >
                <PermissionDetailsContainer>
                  {Object.entries(
                    permissionDetails[title as PermissionFeature]
                  ).map(([permission, permissionDetails], index) => {
                    const isPermitted =
                      rolePermissionsDetailsMapping[role].permissions[title][
                        permission as Permission
                      ];
                    return (
                      <li key={`${permissionFeature}-permission-li-${index}`}>
                        <PermissionDetailIconContainer>
                          {isPermitted ? (
                            <Icon
                              name="ri-checkbox-circle-fill"
                              fill={Green.B61}
                              height="24px"
                            />
                          ) : (
                            <Icon
                              name="ri-close-circle-fill"
                              fill={Neutral.B68}
                              height="24px"
                            />
                          )}
                        </PermissionDetailIconContainer>
                        <Typography as="span" variant="body1" color={Neutral.B18}>
                          {permissionDetails}
                        </Typography>
                      </li>
                    );
                  })}
                </PermissionDetailsContainer>
              </Collapse.Item>
            </CollapseItemContainer>
          ))}
        </Collapse>
      </SideSheetContentContainer>
    </StyledSideSheet>
  );
};
