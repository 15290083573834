import React, { useEffect, useState } from 'react';
import { EmptyState } from 'glints-aries/lib/@next';

import { ReactComponent as AstronautSafeSVG } from '../../../assets/images/astronaut-safe.svg';
import { getGraphqlClient } from '../../../clients/graphql';
import {
  GetInvoiceInvoiceSummaryQuery,
  GetInvoicesQuery,
  InvoiceSortField,
  PaymentLineSortField,
  SortOrder,
  useGetInvoiceInvoiceSummaryQuery,
  useGetInvoicesQuery,
} from '../../../generated/graphql';
import { handleAuthError } from '../../../utils/handleAuthError';
import { Invoice } from '../interfaces';
import { EmptyStateContainer } from './AwaitingPaymentTabStyle';
import { AwaitingPaymentTable } from './components/AwaitingPaymentTable';
import { InvoiceBanner } from './components/InvoiceBanner';
import { PAGE_SIZE } from './constants';

interface AwaitingPaymentTabProps {
  handlePayment: (data: Invoice) => void;
}

export const AwaitingPaymentTab = ({
  handlePayment,
}: AwaitingPaymentTabProps) => {
  const graphqlClient = getGraphqlClient();
  const [page, setPage] = useState(1);
  const [loadingDelay, setLoadingDelay] = useState(true);

  const invoicesVariables = {
    page,
    pageSize: PAGE_SIZE,
    isClosed: false,
    paymentLineSortInput: {
      field: PaymentLineSortField.Date,
      order: SortOrder.Desc,
    },
    sorts: { field: InvoiceSortField.DueDate, order: SortOrder.Asc },
  };

  const {
    isLoading: isLoadingInvoices,
    refetch: refetchInvoices,
    data: invoicesData,
    error: errorInvoices,
    isFetching: isFetchingInvoices,
  } = useGetInvoicesQuery<GetInvoicesQuery, Error>(
    graphqlClient,
    invoicesVariables,
    {}
  );

  const { isLoading: isLoadingInvoiceSummary, data: invoiceSummaryData } =
    useGetInvoiceInvoiceSummaryQuery<GetInvoiceInvoiceSummaryQuery, Error>(
      graphqlClient
    );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRefetch = () => {
    refetchInvoices();
  };

  const handlePayClick = (index: number) => {
    if (invoicesData?.invoices.data[index]) {
      handlePayment(invoicesData?.invoices.data[index]);
    }
  };

  useEffect(() => {
    // if (isLoadingInvoices || isLoadingInvoiceSummary) {
    const delay = 400;

    const timeoutId = setTimeout(() => {
      setLoadingDelay(false);
    }, delay);

    // Cleanup the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
    // }
  }, [isLoadingInvoices, isLoadingInvoiceSummary]);

  const awaitingPaymentTable =
    invoicesData?.invoices.data.length === 0 ? (
      <EmptyStateContainer>
        <EmptyState
          image={<AstronautSafeSVG />}
          title="No Invoices Awaiting Payment"
          description="Everything's in order! No outstanding payments remain."
        />
      </EmptyStateContainer>
    ) : (
      <AwaitingPaymentTable
        currentPage={invoicesData?.invoices?.pageNo || 1}
        pageSize={invoicesData?.invoices?.pageSize || 0}
        totalItems={invoicesData?.invoices.total || 0}
        onPageChanged={handlePageChange}
        data={errorInvoices ? [] : invoicesData?.invoices.data}
        // loading={isLoadingInvoices || isFetchingInvoices}
        loading={loadingDelay}
        handleRefetch={handleRefetch}
        handlePayClick={handlePayClick}
      />
    );

  if (errorInvoices && errorInvoices.message.substring(21, 24) === '401')
    return handleAuthError();

  return (
    <>
      {invoicesData?.invoices?.data?.length !== 0 && (
        <InvoiceBanner
          loading={loadingDelay}
          // loading={isLoadingInvoiceSummary}
          openCount={invoiceSummaryData?.invoiceSummary.open || 0}
          outstandingCount={invoiceSummaryData?.invoiceSummary.outstanding || 0}
          overdueCount={invoiceSummaryData?.invoiceSummary.overdue || 0}
          totalUnpaidAmount={invoiceSummaryData?.invoiceSummary?.totalUnpaid}
        />
      )}
      {awaitingPaymentTable}
    </>
  );
};
