import React, { useState } from 'react';
import { EmptyState } from 'glints-aries/lib/@next';
import { SortDirection } from 'glints-aries/lib/@next/DataTable';

import { ReactComponent as NebulaSVG } from '../../../assets/images/nebula.svg';
import { getGraphqlClient } from '../../../clients/graphql';
import {
  GetPaymentCompletedQuery,
  PaymentLineSortField,
  SortOrder,
  useGetPaymentCompletedQuery,
} from '../../../generated/graphql';
import { handleAuthError } from '../../../utils/handleAuthError';
import { PAYMENT_COMPLETED_TABLE_PAGE_SIZE } from '../constants';
import { PaymentCompletedTable } from './components/PaymentCompletedTable';

export const PaymentCompletedTab = () => {
  const graphqlClient = getGraphqlClient();
  const [page, setPage] = useState(1);
  const [sorts, setSorts] = useState([
    { field: PaymentLineSortField.Date, order: SortOrder.Desc },
  ]);

  const variables = {
    page,
    pageSize: PAYMENT_COMPLETED_TABLE_PAGE_SIZE,
    sorts,
  };

  const { isLoading, data, error, isFetching } = useGetPaymentCompletedQuery<
    GetPaymentCompletedQuery,
    Error
  >(graphqlClient, variables, {});

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSortPaidDate = (
    context: string,
    sortDirection: SortDirection
  ) => {
    if (context !== 'paid-date') {
      return;
    }

    const order =
      sortDirection === 'ASCENDING' ? SortOrder.Asc : SortOrder.Desc;

    setSorts([
      {
        field: PaymentLineSortField.Date,
        order,
      },
    ]);
  };

  const paymentCompledData = data?.paymentLines?.data || [];
  const isNoData = paymentCompledData.length === 0;

  if (error && error.message.substring(21, 24) === '401')
    return handleAuthError();

  return (
    <>
      {(!isNoData || isLoading || isFetching) && (
        <PaymentCompletedTable
          totalItems={data?.paymentLines?.total || 0}
          currentPage={page}
          pageSize={PAYMENT_COMPLETED_TABLE_PAGE_SIZE}
          onPageChanged={handlePageChange}
          data={paymentCompledData}
          loading={isLoading || isFetching}
          handleSortChange={handleSortPaidDate}
        />
      )}
      {isNoData && !isLoading && !isFetching && (
        <EmptyState
          image={<NebulaSVG />}
          title="No Invoices Have Been Paid"
          description="We haven't received any payments from your company yet."
        />
      )}
    </>
  );
};
