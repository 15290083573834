import React, { ReactNode, useState } from "react";
import { Badge, Button, ButtonGroup, Icon, PrimaryButton, RadioButton, Tag, Typography } from "glints-aries/lib/@next";
import { Neutral } from "glints-aries/lib/@next/utilities/colors";

import { ReactComponent as PaymentBankSVG } from '../../../assets/images/payment-bank.svg';
import { ReactComponent as PaymentCreditCardSVG } from '../../../assets/images/payment-credit-card.svg';
import { ReactComponent as PaymentPayNowSVG } from '../../../assets/images/payment-paynow.svg';
import { Invoice } from "../interfaces";
import {
  // BannerContentContainer,
  BottomSection,
  PaymentCardsContainer,
  PaymentCardTopSection,
  PaymentCardWrapper,
  // StyledBanner,
  SubheaderFlexContainer
} from "./PaymentMethodStyle";

interface PaymentMethodProps {
  data: Invoice;
  handleBack: () => void;
}

type PaymentMethod = '' | 'bank' | 'paynow' | 'creditcard';

export const PaymentMethodPage = ({ data, handleBack }: PaymentMethodProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>('');

  const PaymentCard = ({ svg, value, method, description, fee, enabled }:
      { svg: ReactNode; value: PaymentMethod; method: string; description: string; fee: string; enabled: boolean; }) => (
    <PaymentCardWrapper data-disabled={!enabled} data-selected={selectedPaymentMethod === value}>
      <PaymentCardTopSection id="no-disable-overlay">
        {svg}
        <div>
          {enabled ? (
            <RadioButton
              value={value}
              checked={selectedPaymentMethod === value}
              onChange={e => setSelectedPaymentMethod(e.target.value as PaymentMethod)}
            />
          ) :
            (
              <Badge>Coming Soon</Badge>
            )}
        </div>
      </PaymentCardTopSection>
      <div>
        <Typography as="div" variant="body2" style={{
          marginBottom: '4px'
        }}>
          {method}
        </Typography>
        <Typography as="div" variant="body1" color={Neutral.B40}>
          {description}
        </Typography>
      </div>
      <div>
        <Tag>
          <Typography as="div" variant="subtitle2" color={Neutral.B18}>
            Transaction Fee
          </Typography>
        </Tag>
        <Typography as="div" variant="body2" color={Neutral.B18} style={{ padding: '4px 0 0 8px' }}>
          {fee}
        </Typography>
      </div>
    </PaymentCardWrapper>
  );

  return (
    <>
      <Typography as="span" variant="headline6" color={Neutral.B18}>
        Select a Payment Method
      </Typography>
      <SubheaderFlexContainer>
        <Typography as="div" variant="subtitle2" color={Neutral.B18}>
          You&apos;re paying for
        </Typography>
        <Tag>
          {data.referenceNumber}
        </Tag>
      </SubheaderFlexContainer>
      {/* <StyledBanner>
        <div>
          someimghere
        </div>
        <BannerContentContainer>
          <Typography as="div">
            Set up eGIRO to save
          </Typography>
        </BannerContentContainer>
      </StyledBanner> */}
      <PaymentCardsContainer>
        <PaymentCard
          svg={<PaymentBankSVG />}
          value="bank"
          method="Bank Transfer"
          description="Initiate wire transfers from your bank account to make payments"
          fee={"$0 - $50"}
          enabled={true}
        />
        <PaymentCard
          svg={<PaymentPayNowSVG />}
          value="paynow"
          method="PayNow QR Code"
          description="Make payments by PayNow QR Code on your mobile banking apps"
          fee={"$0.5 per transaction"}
          enabled={false}
        />
        <PaymentCard
          svg={<PaymentCreditCardSVG />}
          value="creditcard"
          method="Credit Card"
          description="Use your credit card for swift, hassle-free payments"
          fee={"1.5% - 3.5% per transaction"}
          enabled={false}
        />
      </PaymentCardsContainer>
      <BottomSection>
        <ButtonGroup style={{ marginLeft: 'auto' }}>
          <Button
            icon={<Icon name="ri-arrow-m-left-line" />}
            iconPosition="left"
            onClick={handleBack}
          >
            Back
          </Button>
          <PrimaryButton
            icon={<Icon name="ri-arrow-m-right-line"/>}
            iconPosition="right"
            disabled={selectedPaymentMethod === ''}
          >
            Next
          </PrimaryButton>
        </ButtonGroup>
      </BottomSection>
    </>
  );
};
