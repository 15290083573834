import React from 'react';
import { Button, Card, Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral, Red } from 'glints-aries/lib/@next/utilities/colors';

import { ROLE_UNASSIGNED } from '../constants';
import { RolesPermissionsCardProps } from '../interfaces';
import {
  StyledUserCountBadge,
  UnassignedContent,
} from '../RolesPermissionsTabStyle';

export const RolesPermissionsCard = ({
  role,
  roleDetails,
  userCount,
  loadingUserCount,
  onManageUsersClick,
  onViewPermissions,
}: RolesPermissionsCardProps) => {
  const isUnassignedRole = role.toUpperCase() === ROLE_UNASSIGNED;

  const roleDetailsContent = isUnassignedRole ? (
    <UnassignedContent className="unassigned-content">
      <Icon height="20px" name="ri-error-warning-fill" fill={Red.B93} />
      <Typography as="span" variant="subtitle2" color={Red.B93}>
        <b>Please review the user profiles listed here and assign them appropriate roles. </b>
        Complete any missing data to enable platform usage for them.
        <b> Exercise caution:</b> avoid editing or deleting profiles if you are unsure of the changes, as these profiles are integrated with our CRMS. For guidance or assistance, please contact your Account Manager.
      </Typography>
    </UnassignedContent>
  ) : (
    roleDetails
  );

  if (userCount === 0 && isUnassignedRole) return null;

  return (
    <Card
      heading={
        <Typography as="span" variant="body2" color={Neutral.B18}>
          {role}
        </Typography>
      }
      actionsAlignment="left"
      customActions={
        <div className="custom-actions-container">
          <Button
            icon={<Icon name="ri-group-line" />}
            onClick={onManageUsersClick}
          >
            <Typography as="span" variant="button">
              Manage Users
            </Typography>
            <StyledUserCountBadge data-hidden={loadingUserCount}>
              {userCount}
            </StyledUserCountBadge>
          </Button>
          {!isUnassignedRole && (
            <Button onClick={onViewPermissions}>View Permissions</Button>
          )}
        </div>
      }
    >
      <Card.Section>{roleDetailsContent}</Card.Section>
    </Card>
  );
};
