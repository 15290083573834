import { BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';
import { Option } from 'glints-aries/lib/@next/Menu';

import { AttendanceStatus, EmploymentStatus } from '../../generated/graphql';

export const attendanceStatusBadgeMapping: Record<
  AttendanceStatus,
  BadgeStatusVariant
> = {
  [AttendanceStatus.Present]: 'information',
  [AttendanceStatus.DataIncomplete]: 'critical',
  [AttendanceStatus.Absent]: 'attention',
  [AttendanceStatus.TimeOff]: 'neutral',
  [AttendanceStatus.Holiday]: 'neutral',
  [AttendanceStatus.LateIn]: 'enticing',
};

export const attendanceStatusText: Record<AttendanceStatus, string> = {
  [AttendanceStatus.Present]: 'Present',
  [AttendanceStatus.DataIncomplete]: 'Data incomplete',
  [AttendanceStatus.Absent]: 'Absent',
  [AttendanceStatus.TimeOff]: 'Time off',
  [AttendanceStatus.Holiday]: 'Public holiday',
  [AttendanceStatus.LateIn]: 'Late in',
};

export const JS_MONTH_OFFSET = 1;

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ShowAlertType = {
  status?: AlertType.SUCCESS | AlertType.ERROR;
  content: string;
  shouldShow?: boolean;
};

export const alertContent = {
  [AlertType.SUCCESS]: 'File successfully downloaded',
  [AlertType.ERROR]: 'Export failed. Retry later.',
};

export const TALENT_OPTIONS: Option[] = [
  {
    id: '1',
    label: 'Active',
    value: EmploymentStatus.Active,
  },
  {
    id: '2',
    label: 'End of Contract',
    value: EmploymentStatus.ContractEnded,
  },
  {
    id: '3',
    label: 'Departed',
    value: EmploymentStatus.ResignedOrTerminated,
  },
];

export const PAGE_SIZE = 10;
export const HOUR_TOLERANCE = 1; // show down orange arrow if total hours is less than expected hours by this amount, same with up red arrow if more than expected hours by this amount
export const today = new Date();
