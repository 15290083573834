import { DependencyList, useEffect, useRef } from 'react';

/**
 * it is simply useEffect that doesn't run on initial render
 */
export const useNonInitialEffect = (
  effectFunction: () => void,
  dependencies: DependencyList
) => {
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else {
      effectFunction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useNonInitialEffect;
