import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'glints-aries/lib/@next';

import { ReactComponent as GlintsIconSVG } from '../../../assets/images/glints-icon.svg';
import { ReactComponent as GlintsManagedTalentTextSVG } from '../../../assets/images/glints-managed-talent-text.svg';
import { ReactComponent as GlintsTitleTextSVG} from '../../../assets/images/glints-title-text.svg';
import {
  GlintsLogoContainer,
  GlintsTextContainer,
  ManagedTalentTextContainer,
} from '../../../modules/Login/components/Layout/LoginLayoutStyle';
import {
  HeaderCollapsedContainer,
  HeaderContainer,
  HeaderSmallScreenContainer,
  IconContainer,
  IconSmallScreenContainer,
  MainLogoContainer
} from './HeaderStyle';

interface HeaderProps {
  isCollapsed: boolean;
  isLargeScreen: boolean;
  onCollapseClick?: () => void;
  onLogoClick?: () => void;
  faded?: boolean;
  isHomePageClicked?: boolean;
}

export const Header = ({ isCollapsed, isLargeScreen, onCollapseClick, onLogoClick, faded=false, isHomePageClicked=false }: HeaderProps) => {
  // if the mts icon is clicked on large screen, scaling animation should be disabled
  const [isHomePageClickedRecently, setIsHomePageClickedRecently] = useState<boolean>(isHomePageClicked);
  const targetDivRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setTimeout(() => {
      setIsHomePageClickedRecently(false);
    }, 10);
  }, []);

  const MainLogo = () => (
    <>
      <GlintsLogoContainer>
        <GlintsIconSVG />
      </GlintsLogoContainer>
      <GlintsTextContainer>
        <GlintsTitleTextSVG />
      </GlintsTextContainer>
      <ManagedTalentTextContainer>
        <GlintsManagedTalentTextSVG />
      </ManagedTalentTextContainer>
    </>
  );

  const handleLogoClick = () => {
    if (!(!isLargeScreen && isCollapsed)) onLogoClick?.();
  };

  if (!isLargeScreen) {
    return (
      <HeaderSmallScreenContainer data-collapsed={isCollapsed}>
        <MainLogoContainer className="menu-header-logo" data-collapsed={isCollapsed} onClick={handleLogoClick} data-nopointer={isCollapsed} data-faded={faded}>
          <MainLogo />
        </MainLogoContainer>
        <IconSmallScreenContainer onClick={onCollapseClick} data-collapsed={isCollapsed}>
          <Icon name={isCollapsed ? "ri-menu-line" : "ri-close"} />
        </IconSmallScreenContainer>
      </HeaderSmallScreenContainer>
    );
  }

  if (isCollapsed) {
    return (
      <HeaderCollapsedContainer>
        <IconContainer onClick={onCollapseClick} data-collapsed={isCollapsed}>
          <Icon name="ri-expand-right-line" />
        </IconContainer>
      </HeaderCollapsedContainer>
    );
  }

  return (
    <HeaderContainer ref={targetDivRef} data-hover={isHomePageClickedRecently}>
      <MainLogoContainer className="menu-header-logo" onClick={handleLogoClick} data-hover={isHomePageClickedRecently}>
        <MainLogo />
      </MainLogoContainer>
      <IconContainer className="menu-header-expand-icon" onClick={onCollapseClick}>
        <Icon name="ri-contract-left-line" />
      </IconContainer>
    </HeaderContainer>
  );
};
