import React, { ReactNode } from 'react';
import { SkeletonText } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { StyledLabel, StyledValue } from './CardStyle';

interface SingleDataProps {
  label?: React.ReactNode;
  children?: React.ReactNode | undefined;
  isLoading?: boolean;
  isPhone?: boolean;
}

const SingleData = ({
  label,
  children,
  isLoading = false,
  isPhone = false,
}: SingleDataProps) => {
  if (isLoading) return <SkeletonText rows={[{}, {}]} />;

  const checkNoPhone = (phone: ReactNode) => {
    if (!phone) return true;
    if (phone.toString()[0] !== '+') return false;
    return phone.toString().split(' ')[1] === undefined || phone.toString().split(' ')[1] === '';
  };

  return (
    <>
      <StyledLabel variant="caption" color={Neutral.B40}>
        {label}
      </StyledLabel>
      <StyledValue variant="body1" color={!children || children === '' || (isPhone && checkNoPhone(children)) ? Neutral.B85 : Neutral.B18}>
        {!children || children === '' || (isPhone && checkNoPhone(children)) ? 'Unknown' : children}
      </StyledValue>
    </>
  );
};

export default SingleData;
