import React, { useState } from 'react';
import { isBefore } from 'date-fns';
import {
  DataTable,
  Icon,
  SideSheet,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { TableHeading } from 'glints-aries/lib/@next/DataTable';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { ReactComponent as FlagID } from '../../../../assets/images/flag-id.svg';
import { ReactComponent as FlagMY } from '../../../../assets/images/flag-my.svg';
import { ReactComponent as FlagPH } from '../../../../assets/images/flag-ph.svg';
import { ReactComponent as FlagSG } from '../../../../assets/images/flag-sg.svg';
import { ReactComponent as FlagTW } from '../../../../assets/images/flag-tw.svg';
import { ReactComponent as FlagVN } from '../../../../assets/images/flag-vn.svg';
import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { StatusIndicator } from '../../../../components/StatusIndicator/StatusIndicator';
import { HubberCardProps } from '../interfaces';
import { HolidaysTable } from './HolidaysTable';
import {
  DataTableContainer,
  HubberCardContainer,
  HubberCardHeader,
  HubberCardHeaderCountryLink,
  HubberCardHeaderLeftContainer,
  HubberCardHeaderRightContainer,
  HubberCardNameWrapper,
  TableCellLeftMarginContainer,
  TableCellRightMarginContainer,
} from './HubberCardStyle';

export const HubberCard = ({
  fullName,
  active,
  joinDate,
  resignDate,
  contractEndDate,
  timeOffBalance,
  publicHoliday,
  year,
}: HubberCardProps) => {
  const today = new Date();
  const hasResignDate = Boolean(resignDate);
  let jobEndDate;

  if (hasResignDate) {
    jobEndDate = <FormattedDate date={resignDate} />;
  } else if (contractEndDate && isBefore(new Date(contractEndDate), today)) {
    jobEndDate = <FormattedDate date={contractEndDate} />;
  } else {
    jobEndDate = 'Present';
  }
  const headings: TableHeading[] = [
    {
      title: (
        <TableCellLeftMarginContainer>
          Time Off Type
        </TableCellLeftMarginContainer>
      ),
      align: 'left',
    },
    {
      title: (
        <TableCellRightMarginContainer>
          Amount Available
          <Tooltip
            content="Your total available time off, prorated monthly and carried over on a calendar basis."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </TableCellRightMarginContainer>
      ),
      align: 'right',
    },
    {
      title: (
        <TableCellRightMarginContainer>
          Amount Used
        </TableCellRightMarginContainer>
      ),
      align: 'right',
    },
    {
      title: (
        <TableCellRightMarginContainer>Balance</TableCellRightMarginContainer>
      ),
      align: 'right',
    },
  ];

  const getRoundedDays = (balance: string | null) => {
    if (!balance) return null;
    const floatValue = parseFloat(balance);
    const roundedValue = Math.floor(floatValue * 2) / 2; // round down to nearest 0.5
    return roundedValue.toFixed(1);
  };

  const tableContent = [
    {
      type: timeOffBalance[0].name,
      amountAvailable: getRoundedDays(timeOffBalance[0].available),
      amountUsed: getRoundedDays(timeOffBalance[0].used),
      balance: getRoundedDays(timeOffBalance[0].balance),
    },
    {
      type: timeOffBalance[1].name,
      amountAvailable: getRoundedDays(timeOffBalance[1].available),
      amountUsed: getRoundedDays(timeOffBalance[1].used),
      balance: getRoundedDays(timeOffBalance[1].balance),
    },
    {
      type: (
        <>
          {timeOffBalance[2].name}
          <Tooltip
            content="This includes leaves such as Bereavement, Maternity, Marriage, and more. For detailed information, please refer to the Time Off Requests."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </>
      ),
      amountAvailable: getRoundedDays(timeOffBalance[2].available),
      amountUsed: getRoundedDays(timeOffBalance[2].used),
      balance: getRoundedDays(timeOffBalance[2].balance),
    },
  ];

  const [showSideSheet, setShowSideSheet] = useState<boolean>(false);
  const openSideSheet = () => setShowSideSheet(true);
  const validCountryCodes: string[] = ['ID', 'MY', 'PH', 'SG', 'TW', 'VN'];
  const flagSVG: { [key: string]: React.ReactElement } = {
    ID: <FlagID />,
    MY: <FlagMY />,
    PH: <FlagPH />,
    SG: <FlagSG />,
    TW: <FlagTW />,
    VN: <FlagVN />,
  };
  const countryName: { [key: string]: string } = {
    ID: 'Indonesia',
    MY: 'Malaysia',
    PH: 'Philippines',
    SG: 'Singapore',
    TW: 'Taiwan',
    VN: 'Vietnam',
  };

  return (
    <>
      {publicHoliday && (
        <SideSheet
          isOpen={showSideSheet}
          title={`Holidays in ${
            countryName[publicHoliday.countryCode]
          } in ${year}`}
          onClose={() => setShowSideSheet(false)}
          showBackButton={false}
        >
          <HolidaysTable publicHolidayData={publicHoliday.data} />
        </SideSheet>
      )}
      <HubberCardContainer>
        <HubberCardHeader>
          <HubberCardHeaderLeftContainer>
            <HubberCardNameWrapper>
              <Typography variant="body2" as="span" color={Neutral.B18}>
                {fullName}
              </Typography>
              <StatusIndicator active={active} />
            </HubberCardNameWrapper>
            <Typography variant="subtitle2" as="span" color={Neutral.B40}>
              <FormattedDate date={joinDate} /> to {jobEndDate}
            </Typography>
          </HubberCardHeaderLeftContainer>
          <HubberCardHeaderRightContainer>
            <Typography as="div" variant="subtitle2" color={Neutral.B18}>
              Following Holiday:
            </Typography>
            {publicHoliday &&
            validCountryCodes.includes(publicHoliday.countryCode) ? (
                <>
                  {flagSVG[publicHoliday.countryCode]}
                  <HubberCardHeaderCountryLink onClick={openSideSheet}>
                    <Typography as="div" variant="subtitle2" color={Blue.S99}>
                      {publicHoliday?.countryCode}
                    </Typography>
                    <div className="no-border">
                      <Icon name="ri-arrow-m-right-fill" fill={Blue.S99} />
                    </div>
                  </HubberCardHeaderCountryLink>
                </>
              ) : (
                <Typography as="span" variant="subtitle2" color={Neutral.B85}>
                Unknown
                </Typography>
              )}
          </HubberCardHeaderRightContainer>
        </HubberCardHeader>
        <DataTableContainer>
          <DataTable headings={headings}>
            {tableContent.map((dt, index: number) => (
              <DataTable.Row key={index}>
                <DataTable.Cell align="left">
                  <TableCellLeftMarginContainer>
                    {dt.type}
                  </TableCellLeftMarginContainer>
                </DataTable.Cell>
                <DataTable.Cell align="right">
                  <TableCellRightMarginContainer>
                    {dt.amountAvailable ? (
                      `${dt.amountAvailable} days`
                    ) : (
                      <Typography variant="body1" as="span" color={Neutral.B85}>
                        &mdash;
                      </Typography>
                    )}
                  </TableCellRightMarginContainer>
                </DataTable.Cell>
                <DataTable.Cell align="right">
                  <TableCellRightMarginContainer>
                    {dt.amountUsed ? (
                      `${dt.amountUsed} days`
                    ) : (
                      <Typography variant="body1" as="span" color={Neutral.B85}>
                        &mdash;
                      </Typography>
                    )}
                  </TableCellRightMarginContainer>
                </DataTable.Cell>
                <DataTable.Cell align="right">
                  <TableCellRightMarginContainer>
                    {dt.balance ? (
                      `${dt.balance} days`
                    ) : (
                      <Typography variant="body1" as="span" color={Neutral.B85}>
                        &mdash;
                      </Typography>
                    )}
                  </TableCellRightMarginContainer>
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
        </DataTableContainer>
      </HubberCardContainer>
    </>
  );
};
