import React from 'react';
import { Navigate } from 'react-router-dom';

import { errorType } from '../modules/Login/components/Frame/ErrorFrame';

export const handleAuthError = () => {
  // TODO - use interceptors, handle refresh token logic (once there is refresh token)
  localStorage.removeItem('glintsMTS');

  return (
    <>
      <Navigate to="/magic-link" state={{ errorType: errorType.TOKEN_EXPIRED }} />
    </>
  );
};
