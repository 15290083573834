import React, { useEffect, useState } from 'react';
import { CheckboxProps, InlineError } from 'glints-aries/lib/@next';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormCheckboxContainer,
  StyledCheckboxInput
} from './FormCheckboxStyle';

interface FormCheckboxInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
  Omit<CheckboxProps, 'value' | 'defaultValue' | 'name'> {
  label?: string;
  value?: string | null;
  apiError?: string | null;
  height?: string;
}

export const CheckboxInputError = ({
  errorMessage = 'An unknown error has occurred',
}: {
  errorMessage?: string;
}) => <InlineError text={errorMessage} />;

export const FormCheckboxInput = ({
  control,
  name,
  value,
  apiError,
  height = 'auto',
  ...props
}: FormCheckboxInputProps<FieldValues>) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.message);
    }

    if (apiError) {
      setErrorMessage(apiError);
    }
  }, [error, apiError]);

  return (
    <FormCheckboxContainer height={height}>
      <StyledCheckboxInput
        {...props}
        height={height}
        onChange={field.onChange} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        value={value || field.value} // input value
        name={field.name} // send down the input name
        ref={field.ref} // send input ref, so we can focus on input when error appear
      />
      {(error || apiError) && <CheckboxInputError errorMessage={errorMessage} />}
    </FormCheckboxContainer>
  );
};
