import { Breakpoints, DataTable, PrimaryButton } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

const CUSTOM_BREAKPOINT = '1150px';

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${space16};
  margin: ${space16} 0;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    display: grid;
    grid-template-areas:
      'header-request-filter header-request-filter'
      'header-talent-filter header-talent-filter'
      'header-search header-export';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const SearchInputContainer = styled.div`
  width: 200px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    grid-area: header-search;
  }
`;

export const SelectContainer = styled.div`
  width: 175px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    grid-area: header-request-filter;
  }
`;

export const SelectTalentContainer = styled.div`
  width: 250px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    grid-area: header-talent-filter;
  }
`;

export const ExportContainer = styled.div`
  margin-left: auto;

  @media (max-width: ${CUSTOM_BREAKPOINT}}) {
    display: flex;
    justify-content: flex-end;
    grid-area: header-export;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  .export-download-icon {
    height: 16px;
  }

  .export-down-icon {
    height: 20px;
  }
`;

export const RequestedTimeContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    margin-right: ${space4};

    svg {
      margin: 0;
    }
  }
`;

export const SortButton = styled.button`
  display: flex;
  align-items: center;
`;

export const TimeOffTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  transform: translateX(-24px);
  width: calc(100%);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Neutral.B100};

  .data-table-container {
    background-color: ${Neutral.B100};
    height: 100%;

    table {
      height: auto;

      tbody tr {
        height: 56px;
      }
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }
`;

export const StyledDataTable = styled(DataTable)`
  thead {
    tr {
      position: sticky;
      top: 0;
    }
  }
`;

export const HubberNameFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

export const HubberNameWrapper = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftPaddingCell = styled.div`
  padding-left: ${space8};
`;

export const RightPaddingCell = styled.div`
  padding-right: ${space8};
`;

export const TablePaginationContainer = styled.div`
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
  position: sticky;
  bottom: -32px;
  border-top: 1px solid ${Neutral.B85};
`;
