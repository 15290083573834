// TODO - remove this file later, temporary only for sample data purpose

interface Schedule {
  day: string;
  checkIn: string;
  checkOut: string;
}

export const scheduleData: Schedule[] = [
  {
    day: "Monday",
    checkIn: "08:00",
    checkOut: "12:00",
  },
  {
    day: "Tuesday",
    checkIn: "13:00",
    checkOut: "17:00",
  },
  {
    day: "Wednesday",
    checkIn: "08:00",
    checkOut: "12:00",
  },
  {
    day: "Thursday",
    checkIn: "13:00",
    checkOut: "17:00",
  },
  {
    day: "Friday",
    checkIn: "13:00",
    checkOut: "17:00",
  },
];
export const daysOffData: string[] = ["Saturday", "Sunday"];
