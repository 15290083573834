import React from 'react';

import { ReactComponent as GlintsIconSVG } from '../../../assets/images/glints-icon.svg';
import { ReactComponent as GlintsManagedTalentTextSVG } from '../../../assets/images/glints-managed-talent-text.svg';
import { ReactComponent as GlintsTitleTextSVG } from '../../../assets/images/glints-title-text.svg';
import { ReactComponent as MainPlanetsSVG } from '../../../assets/images/main-planets.svg';
import { ReactComponent as SmallPlanetsSVG } from '../../../assets/images/small-planets.svg';
import {
  FrameContainer,
  GlintsLogoContainer,
  GlintsTextContainer,
  GreyBackgroundOverlay,
  MainContainer,
  MainPlanetsContainer,
  ManagedTalentTextContainer,
  SmallPlanetsContainer,
  StyledBackground,
  TitleContainer,
  TopBackgroundContainer,
} from './LandingPageLayoutStyle';

interface LandingPageLayoutProps {
  hasFooter?: boolean;
  overlayGrey?: boolean;
  children?: React.ReactNode;
}

export const LandingPageLayout = ({
  hasFooter = false,
  overlayGrey = false,
  children,
}: LandingPageLayoutProps) => (
  <>
    {overlayGrey && <GreyBackgroundOverlay />}
    <StyledBackground />
    <MainPlanetsContainer>
      <MainPlanetsSVG />
    </MainPlanetsContainer>
    <SmallPlanetsContainer>
      <SmallPlanetsSVG />
    </SmallPlanetsContainer>
    <TopBackgroundContainer>
      <TitleContainer to="/">
        <GlintsLogoContainer>
          <GlintsIconSVG />
        </GlintsLogoContainer>
        <GlintsTextContainer>
          <GlintsTitleTextSVG />
        </GlintsTextContainer>
        <ManagedTalentTextContainer>
          <GlintsManagedTalentTextSVG />
        </ManagedTalentTextContainer>
      </TitleContainer>
    </TopBackgroundContainer>
    <MainContainer>
      <FrameContainer data-footer={hasFooter}>{children}</FrameContainer>
    </MainContainer>
  </>
);
