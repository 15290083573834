import {
  Breakpoints,
  IndexTable,
  Link,
  Typography,
} from 'glints-aries/lib/@next';
import { borderRadius4 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space4,space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const HEADER_HEIGHT = '276px';

export const IndexTableContainer = styled.div`
  box-shadow: 0px 0px 0px 1px rgba(84, 84, 84, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Neutral.B100};
  height: calc(100% - ${HEADER_HEIGHT});

  transform: translateX(-24px);
  width: calc(100%);
  position: absolute;

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }

  table thead tr {
    height: 52px;
  }
`;

export const StyledCell = styled(IndexTable.Cell)`
  padding: ${space16};
`;

export const LeftPaddingWrapper = styled.div`
  padding-left: ${space8};
`;

export const PaymentAmountWrapper = styled.div`
  padding-right: ${space8};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${space8};

  svg {
    height: 20px;
    width: 20px;
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const LoadingRow = styled(IndexTable.Row)`
  pointer-events: none;
`;

export const TablePaginationContainer = styled.div`
  display: grid;
  padding: ${space8} ${space16};
`;

export const Currency = styled(Typography)`
  line-height: normal;
  color: ${Neutral.B40};
`;

export const StyledLink = styled(Link)`
  p {
    margin: 0;
  }
`;

export const InvoiceReferenceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
`;

export const DisputedBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${space4};
  padding: ${space4} ${space8};

  background-color: ${Blue.S08};
  color: ${Blue.S99};
  border-radius: ${borderRadius4};
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
    fill: ${Blue.S99};
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export const TableActionsContainer = styled.div`
  display: flex;
  fled-direction: row;
  gap: ${space16};
  align-items: center;
`;

export const PopoverActivatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius4};
  height: 32px;
  width: 32px;
  cursor: pointer;

  &:hover {
    background-color: ${Neutral.B99};
  }

  svg {
    height: 20px;
    width: 20px;
    transform: rotate(90deg);
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;
