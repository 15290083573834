import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const SidesheetContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${space12};
  overflow: scroll;
  gap: ${space16};
`;

export const CardRow = styled.div`
  padding: ${space16} ${space24};
  display: flex;
  flex-direction: row;
`;

export const CardData = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  flex: 1;
  overflow-wrap: break-word;
  overflow: hidden;

  &[data-left-margin='true'] {
    margin-left: ${space32};
  }
`;

export const CardFlexDataContainer = styled.div`
  display: flex;
  align-items: center;

  &[data-pointer='true'] {
    cursor: pointer;
  }
`;

export const FlagContainer = styled.div`
  margin-right: ${space8};

  svg {
    height: 18px;
    border: 1px solid ${Neutral.B85};
    vertical-align: middle;
  }
`;

export const CountryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${space4};
`;
