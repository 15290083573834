import { useState } from 'react';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import { checkTokenValid } from '../utils/checkTokenValid';

interface AuthTokens {
  accessToken: string | null;
  isAuthenticated: boolean;
  login: (accessToken: string) => void;
  logout: () => void;
}

/**
 * custom hook for storing and retrieving auth tokens
 */
export const useAuthToken = (): AuthTokens => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem('glintsMTS') || null
  );

  const isAuthenticated =
    accessToken !== null ? checkTokenValid(accessToken) : false;

  const login = (accessToken: string) => {
    setAccessToken(accessToken);
    localStorage.setItem('glintsMTS', accessToken);
  };

  const logout = () => {
    setAccessToken(null);
    localStorage.removeItem('glintsMTS');
  };

  return {
    accessToken,
    isAuthenticated,
    login,
    logout,
  };
};

export const useCurrentUser = () => {
  const accessToken = localStorage.getItem('glintsMTS');

  const decodedToken: { sub?: string } | null = accessToken
    ? jwt_decode(accessToken)
    : null;

  return { userId: decodedToken?.sub };
};
