import { Breakpoints } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space8,
  space12,
  space16,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: fixed;
  z-index: 10;

  width: 224px;
  gap: ${space16};
  height: calc(100vh - 55px);
  padding: ${space8} 0 0;
  margin-right: ${space16};
  background-color: rgba(150, 0, 0, 0.1);

  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 6px 0px ${Neutral.B85};
  backdrop-filter: blur(3px);
  overflow: hidden;

  @keyframes menu-condense {
    from {
      width: 224px;
    }
    to {
      width: 68px;
    }
  }
  @keyframes menu-expand {
    from {
      width: 68px;
    }
    to {
      width: 224px;
    }
  }
  &[data-animate='true'] {
    animation: menu-expand 400ms ease-in-out;
  }

  &[data-collapsed='true'] {
    gap: ${space32};
    width: 68px;
    overflow: visible;
    &[data-animate='true'] {
      animation: menu-condense 400ms ease-in-out;
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    width: calc(100vw - 75px);
    height: 36px;
    padding: ${space12} ${space16};

    & .menu-content {
      display: none;
    }

    &[data-collapsed='true'] {
      width: calc(100vw - 75px);
    }
  }
`;

export const MenuMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;

  margin-right: ${space16};
  background-color: rgba(150, 0, 0, 0.1);

  border-radius: ${space8};
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 6px 0px #d4d5d8;
  backdrop-filter: blur(3px);
  overflow: hidden;

  width: calc(100vw - 55px);
  height: 30px;
  padding: ${space12} ${space16};
`;

export const MenuMobileWrapper = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(45, 45, 45, 0.5);
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 999;

  animation: mts-menu-fade-in 0.2s;
  &[data-menu-animate='true'] {
    animation: mts-menu-fade-out 0.2s;
  }

  @keyframes mts-menu-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes mts-menu-fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const MenuMobileSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Neutral.B99};
  height: 100vh;
  width: 300px;

  animation: mts-slide-in 0.2s;
  &[data-menu-animate='true'] {
    animation: mts-slide-out 0.2s;
  }

  @keyframes mts-slide-in {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes mts-slide-out {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(100%);
    }
  }
`;
