import { Breakpoints, Link, OutlineButton } from 'glints-aries/lib/@next';
import { Blue, Yellow } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

export const FrameContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;

  position: absolute;
  width: 430px;
  left: 50%;
  top: 47%;
  transform: translate(-50%, -50%);

  @media (max-width: ${Breakpoints.large}) {
    position: relative;
    width: 100%;
    left: auto;
    top: auto;
    transform: none;
  }
`;

export const StyledConsentCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -12px;
`;

export const StyledConsentCheckboxLabelContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  p {
    margin: 0;
  }
`;

export const StyledRowContent = styled.div`
  width: 325px;

  @media (max-width: ${Breakpoints.large}) {
    width: 280px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const StyledSendEmailButtonContainer = styled.div`
  width: 325px;

  button > svg {
    fill: ${Yellow.S75};
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 280px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const StyledSendEmailErrorContainer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
`;

export const StyledButtonGroupContainer = styled.div`
  padding-top: 24px;
  flex-direction: column;

  @media (max-width: ${Breakpoints.large}) {
    & > div {
      flex-direction: column;
    }

    gap: 16px;
    margin-bottom: 39px;
  }
`;

export const StyledOutlineButton = styled(OutlineButton)`
  width: 210px;
`;

export const ContactUsTextContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-left: 4px;
    fill: ${Blue.S99};
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 90%;
  }
`;
