import React from 'react';
import { SkeletonImageSquare, Typography } from 'glints-aries/lib/@next';
import { BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { formatMoney } from '../../../../utils/formatString';
import { Money } from '../../interfaces';
import {
  FrameContainer,
  GridContainer,
  MultiCurrencyContainer,
  StyledBadge,
  StyledBanner,
  StyledVerticalLine,
  TotalUnpaidContainer,
} from './InvoiceBannerStyle';

interface InvoiceBannerProps {
  loading: boolean;
  openCount: number;
  outstandingCount: number;
  overdueCount: number;
  totalUnpaidAmount?: Money[];
}

export const InvoiceBanner = ({
  loading,
  openCount,
  outstandingCount,
  overdueCount,
  totalUnpaidAmount,
}: InvoiceBannerProps) => {
  const InvoiceCount = ({
    count,
    status,
    title,
  }: {
    count: number;
    status: BadgeStatusVariant;
    title: string;
  }) => (
    <FrameContainer>
      {loading ? (
        <SkeletonImageSquare height="40px" width="60px" />
      ) : (
        <Typography as="span" variant="headline5" color={Neutral.B18}>
          {count}
        </Typography>
      )}
      <StyledBadge status={status}>{title}</StyledBadge>
    </FrameContainer>
  );

  const unpaidInvoicesCount = (
    <FrameContainer data-right-margin={true}>
      {loading ? (
        <SkeletonImageSquare height="40px" width="60px" />
      ) : (
        <Typography
          as="div"
          variant="headline5"
          color={Neutral.B18}
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {openCount}
        </Typography>
      )}
      <Typography
        as="div"
        variant="caption"
        color={Neutral.B40}
        style={{
          textAlign: 'center',
        }}
      >
        Unpaid Invoices
      </Typography>
    </FrameContainer>
  );

  const renderTotalUnpaid = () =>
    totalUnpaidAmount && totalUnpaidAmount?.length > 0 ? (
      <MultiCurrencyContainer>
        {totalUnpaidAmount?.map(({ amount, currency }: Money) => (
          <TotalUnpaidContainer key={`unpaid-${currency}`}>
            <Typography
              as="span"
              variant="headline5"
              color={Neutral.B18}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {`${formatMoney({
                amount,
              })}`}
            </Typography>
            <Typography
              as="span"
              variant="caption"
              color={Neutral.B18}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {`${currency}`}
            </Typography>
          </TotalUnpaidContainer>
        ))}
      </MultiCurrencyContainer>
    ) : (
      '0'
    );

  const totalAmount = (
    <FrameContainer data-right-margin={true}>
      {loading ? (
        <SkeletonImageSquare height="30px" width="100px" />
      ) : (
        renderTotalUnpaid()
      )}
      <Typography
        as="div"
        variant="caption"
        color={Neutral.B40}
        style={{
          textAlign: 'center',
        }}
      >
        Total Amount
      </Typography>
    </FrameContainer>
  );

  return (
    <StyledBanner>
      <GridContainer>
        <InvoiceCount count={overdueCount} status="attention" title="Overdue" />
        <InvoiceCount
          count={outstandingCount}
          status="warning"
          title="Due soon"
        />
      </GridContainer>
      <StyledVerticalLine />
      <GridContainer>
        {unpaidInvoicesCount}
        {totalAmount}
      </GridContainer>
    </StyledBanner>
  );
};
