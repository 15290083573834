import React from 'react';
import { Card, SkeletonImageSquare } from 'glints-aries/lib/@next';

import { PLACEHOLDER_COMPANY_IMAGE_LINK } from '../../../constants/constants';
import { Address, Company } from '../../../generated/graphql';
import {
  CardContainer,
  CardContentContainer,
  ColumnFlexContainer,
  LeftSectionContainer,
  RightSectionContainer,
  RowFlexContainer,
  StyledImage,
  StyledImageContainer,
} from '../components/CardStyle';
import SingleData from '../components/SingleData';

type BasicInfoProps = Pick<Company, 'name' | 'registeredName'> & {
  registeredAddress: Address['street'];
  operatingAddress: Address['street'];
  isLoading?: boolean;
};

const BasicInfo = ({
  name,
  registeredName,
  registeredAddress,
  operatingAddress,
  isLoading = false,
}: BasicInfoProps) => (
  <CardContainer>
    <Card heading="Basic Info">
      <CardContentContainer>
        <StyledImageContainer>
          {isLoading ? (
            <SkeletonImageSquare height="120px" width="120px" />
          ) : (
            <StyledImage src={PLACEHOLDER_COMPANY_IMAGE_LINK} />
          )}
        </StyledImageContainer>
        <ColumnFlexContainer>
          <RowFlexContainer>
            <LeftSectionContainer>
              <SingleData label="Company Name" isLoading={isLoading}>
                {name}
              </SingleData>
            </LeftSectionContainer>
            <RightSectionContainer>
              <SingleData label="Registered Entity Name" isLoading={isLoading}>
                {registeredName}
              </SingleData>
            </RightSectionContainer>
          </RowFlexContainer>
          <RowFlexContainer>
            <LeftSectionContainer>
              <SingleData
                label="Registered Business Address"
                isLoading={isLoading}
              >
                {registeredAddress}
              </SingleData>
            </LeftSectionContainer>
          </RowFlexContainer>
        </ColumnFlexContainer>
      </CardContentContainer>
    </Card>
  </CardContainer>
);

export default BasicInfo;
