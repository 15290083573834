import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import PageLayout from './components/PageLayout/PageLayout';
import AttendanceLogPage from './modules/AttendanceLog/AttendanceLogPage';
import DailyLog from './modules/AttendanceLog/DailyLog/DailyLog';
import CompanyProfilePage from './modules/CompanyProfile/CompanyProfilePage';
import { companyProfileTab } from './modules/CompanyProfile/constants';
import { errorType } from './modules/Error/constants';
import ErrorPage from './modules/Error/Error';
import HomePage from './modules/Home/HomePage';
import InvoicePaymentsPage from './modules/InvoicePayments/InvoicePaymentsPage';
import ErrorFrame, {
  errorType as error,
} from './modules/Login/components/Frame/ErrorFrame';
import { LoginLayout } from './modules/Login/components/Layout/LoginLayout';
import { GreyBackgroundOverlay } from './modules/Login/components/Layout/LoginLayoutStyle';
import LoginPage from './modules/Login/LoginPage';
import MagicLinkPage from './modules/Login/MagicLinkPage';
import ManagedTalentsPage from './modules/ManagedTalents/ManagedTalentsPage';
import TimeOffTrackerPage from './modules/TimeOffTracker/TimeOffTrackerPage';

const ErrorBoundaryWrapper = () => (
  <ErrorBoundary fallback={<ErrorPage errorType={errorType.UNKNOWN} />}>
    <Outlet />
  </ErrorBoundary>
);

export const router = createBrowserRouter([
  {
    element: <ErrorBoundaryWrapper />,
    children: [
      {
        path: '/',
        element: (
          <PageLayout
            component={<HomePage />}
            direct={true}
            pageTitle="Welcome to Glints Managed Talent Platform"
            metaDescription="Welcome to Glints Managed Talent - where we unlock the full potential of your hiring and talent management endeavors through our streamlined capabilities"
          />
        ),
        errorElement: <ErrorPage errorType={errorType.NOT_FOUND} />,
      },
      {
        path: 'managed-talents',
        element: (
          <PageLayout
            component={<ManagedTalentsPage />}
            pageTitle="Your Managed Talents List | Glints Managed Talent"
            metaDescription="Glints Managed Talent offers comprehensive insights into your managed talents, empowering you with valuable information for enhanced supervision and effective talent management"
          />
        ),
      },
      {
        path: 'company-settings',
        children: [
          {
            path: 'company-information',
            element: (
              <PageLayout
                component={
                  <CompanyProfilePage
                    activeTab={companyProfileTab.COMPANY_DETAILS}
                  />
                }
                pageTitle="Your Company Settings | Glints Managed Talent"
                metaDescription="View your Company Settings on Glints Managed Talent, where you can access and manage essential company information and details"
              />
            ),
          },
          {
            path: 'roles-and-permissions',
            element: (
              <PageLayout
                component={
                  <CompanyProfilePage
                    activeTab={companyProfileTab.ROLES_AND_PERMISSIONS}
                  />
                }
                pageTitle="Your Company Settings | Glints Managed Talent"
                metaDescription="View your Company Settings on Glints Managed Talent, where you can access and manage essential company information and details"
              />
            ),
            children: [
              {
                path: ':role',
              },
            ],
          },
        ],
      },
      {
        path: 'time-off-tracker',
        element: (
          <PageLayout
            component={<TimeOffTrackerPage />}
            pageTitle="Time Off Tracker | Glints Managed Talent"
            metaDescription="TODO"
          />
        ),
        children: [
          {
            path: 'time-off-balance',
          },
          {
            path: 'time-off-requests',
          },
        ],
      },
      {
        path: 'attendance-log',
        element: (
          <PageLayout
            component={<AttendanceLogPage />}
            pageTitle="Attendance Log | Glints Managed Talent"
            metaDescription="TODO"
          />
        ),
      },
      {
        path: 'attendance-log/:hubberId',
        element: <PageLayout component={<DailyLog />} />,
      },
      {
        path: 'invoice-payments',
        element: (
          <PageLayout
            component={<InvoicePaymentsPage />}
            pageTitle="Invoice Payments | Glints Managed Talent"
            metaDescription="TODO"
          />
        ),
        children: [
          {
            path: 'awaiting-payment',
          },
          {
            path: 'payment-completed',
          },
        ],
      },
      {
        path: 'login',
        element: (
          <LoginPage
            pageTitle="Log into Glints Managed Talent"
            metaDescription="Log in to your Glints Managed Talent account to access talent management tools and streamline your hiring and talent management processes efficiently"
          />
        ),
      },
      {
        path: 'magic-link',
        element: (
          <MagicLinkPage
            pageTitle="Login with a Magic Link | Glints Managed Talent"
            metaDescription="Experience a seamless login process with a magic link on Glints Managed Talent. Simplify your access and begin managing talents effortlessly"
          />
        ),
      },
      {
        path: 'auth/403',
        element: (
          <PageLayout
            component={<ErrorPage errorType={errorType.FORBIDDEN} />}
            centered={true}
          />
        ),
      },
      {
        path: 'auth/404',
        element: (
          <PageLayout
            component={<ErrorPage errorType={errorType.NOT_FOUND} />}
            centered={true}
          />
        ),
      },
      {
        path: 'auth/unknown',
        element: (
          <PageLayout
            component={<ErrorPage errorType={errorType.UNKNOWN} />}
            centered={true}
          />
        ),
      },
      {
        path: '/404',
        element: <ErrorPage errorType={errorType.NOT_FOUND} />,
      },
      {
        path: '/unknown',
        element: <ErrorPage errorType={errorType.UNKNOWN} />,
      },
      {
        path: '/401/unauthorized',
        element: (
          <>
            <GreyBackgroundOverlay />
            <LoginLayout>
              <ErrorFrame errorType={error.UNAUTHORIZED} />
            </LoginLayout>
          </>
        ),
      },
      {
        path: '/401/session-expired',
        element: (
          <>
            <GreyBackgroundOverlay />
            <LoginLayout>
              <ErrorFrame errorType={error.TOKEN_EXPIRED} />
            </LoginLayout>
          </>
        ),
      },
    ],
  },
]);
