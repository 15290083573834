import { BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';

import { AttendanceStatus, EmploymentStatus } from '../../../generated/graphql';
import { attendanceStatusText } from '../constants';

export const attendanceStatusOptions = Object.values(AttendanceStatus).map(
  status => ({
    id: status,
    label: attendanceStatusText[status],
    value: status,
  })
);

export const selectAttendanceStatusOptions = [
  {
    id: 'ALL',
    label: 'All statuses',
    value: 'ALL',
  },
  ...attendanceStatusOptions,
];

export const talentStatusBadgeMapping: Record<
  EmploymentStatus,
  Partial<BadgeStatusVariant>
> = {
  [EmploymentStatus.Active]: 'success',
  [EmploymentStatus.ContractEnded]: 'neutral',
  [EmploymentStatus.ResignedOrTerminated]: 'neutral',
};

export const employmentStatusText: Record<EmploymentStatus, string> = {
  [EmploymentStatus.Active]: 'Active',
  [EmploymentStatus.ContractEnded]: 'Contract Expired',
  [EmploymentStatus.ResignedOrTerminated]: 'Inactive',
};
