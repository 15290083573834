import React, { useEffect, useState } from 'react';
import { Icon, Tooltip, Typography } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { ReactComponent as PlanetSVG } from '../../assets/images/planet.svg';
import { ReactComponent as SatelitesSVG } from '../../assets/images/satelites.svg';
import { SUPPORT_EMAIL } from '../../constants/constants';
import {
  AnimationContainer,
  ContactUsTextContainer,
  MainContainer,
  PlanetSVGContainer,
  SatelitesSVGContainer,
  TextContainer,
} from './HomePageStyle';

const HomePage = () => {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(true);
  const handleWindowSizeChange = () =>
    setIsLargeScreen(window.innerWidth > 768);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const copySupportEmailToClipboard = () => {
    navigator.clipboard.writeText(SUPPORT_EMAIL);
  };

  return (
    <>
      <MainContainer>
        <TextContainer>
          <Typography as="div" variant="headline3" color={Blue.S100}>
            Welcome to <br />
            Glints Managed Talent
          </Typography>
        </TextContainer>
        <TextContainer>
          <Typography as="div" variant="body1" color={Blue.S100}>
            {isLargeScreen
              ? 'Begin your journey by selecting an option from the menu on the left'
              : 'Begin your journey by accessing the menu in the top bar'}
          </Typography>
        </TextContainer>
        <AnimationContainer>
          <PlanetSVGContainer>
            <PlanetSVG />
          </PlanetSVGContainer>
          <SatelitesSVGContainer>
            <SatelitesSVG />
          </SatelitesSVGContainer>
        </AnimationContainer>
        <ContactUsTextContainer>
          <Typography as="span" variant="subtitle2" color={Neutral.B40}>
            If you have any issues or ideas, please click on the
          </Typography>
          <Typography as="span" variant="caption" color={Neutral.B40}>
            “Report”
          </Typography>
          <Typography as="span" variant="subtitle2" color={Neutral.B40}>
            side tab on the right
          </Typography>
        </ContactUsTextContainer>
      </MainContainer>
    </>
  );
};

export default HomePage;
