import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { ManageUsers } from './components/ManageUsers/ManageUsers';
import { RolesPermissionsOverview } from './components/RolesPermissionsOverview';
import { Role } from './interfaces';

const RolesPermissionsTab = () => {
  const location = useLocation();

  const [isManageUsers, setIsManageUsers] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);

  useEffect(() => {
    if (location.pathname === '/company-settings/roles-and-permissions') {
      setIsManageUsers(false);
    } else {
      setIsManageUsers(true);
    }
  }, [location]);

  const updateIsManageUsers = (newValue: boolean) => {
    setIsManageUsers(newValue);
  };

  const updateSelectedRole = (newValue: Role) => {
    setSelectedRole(newValue);
  };

  if (isManageUsers) {
    return (
      <ManageUsers
        role={selectedRole}
        updateIsManageUsers={updateIsManageUsers}
      />
    );
  }

  return (
    <RolesPermissionsOverview
      selectedRole={selectedRole}
      updateIsManageUsers={updateIsManageUsers}
      updateSelectedRole={updateSelectedRole}
    />
  );
};

export default RolesPermissionsTab;
