import React from 'react';
import { Card, SkeletonImageCircle } from 'glints-aries/lib/@next';

import { PLACEHOLDER_PROFILE_IMAGE_LINK } from '../../../constants/constants';
import {
  CardContainer,
  CardContentContainer,
  ColumnFlexContainer,
  LeftSectionContainer,
  RightSectionContainer,
  RowFlexContainer,
  StyledCircleImage,
  StyledImageContainer,
} from '../components/CardStyle';
import SingleData from '../components/SingleData';
import { AccountManagerProps } from '../interfaces';

export const AccountManager = ({
  name,
  isLoading = false,
  email,
}: AccountManagerProps) => (
  <CardContainer>
    <Card heading="Your Account Manager from Glints">
      <CardContentContainer>
        <StyledImageContainer>
          {isLoading ? (
            <SkeletonImageCircle size="120px" />
          ) : (
            <StyledCircleImage src={PLACEHOLDER_PROFILE_IMAGE_LINK} />
          )}
        </StyledImageContainer>
        <ColumnFlexContainer>
          <RowFlexContainer>
            <LeftSectionContainer>
              <SingleData label="Name" isLoading={isLoading}>
                {name}
              </SingleData>
            </LeftSectionContainer>
            <RightSectionContainer>
              <SingleData label="Email" isLoading={isLoading}>
                {email}
              </SingleData>
            </RightSectionContainer>
          </RowFlexContainer>
        </ColumnFlexContainer>
      </CardContentContainer>
    </Card>
  </CardContainer>
);
