import { BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';

import {
  EmploymentStatus,
  EmploymentType,
  TimeOffCategory,
  TimeOffRequestStatus,
} from '../../generated/graphql';

type Hubber = {
  id: string;
  fullName: string;
  joinDate: string;
  contractEndDate?: string | null;
  resignDate?: string | null;
  hub: string;
  jobTitle: string;
  employmentType: EmploymentType;
  contractType?: string | null;
  status: EmploymentStatus;
};

type TimeOffBalance = {
  category: TimeOffCategory;
  hubberId: string;
  name: string;
};

export interface TimeOffRequest {
  balance: TimeOffBalance;
  hubber: Hubber;
  hubberId: string;
  id: string;
  requestStatus: TimeOffRequestStatus;
  requestedAt: string;
  timeoffCode: string;
  timeoffDuration: string;
  timeoffEndDate: string;
  timeoffStartDate: string;
}

export const timeOffTrackerTab = {
  TIME_OFF_REQUESTS: 0,
  TIME_OFF_BALANCE: 1,
} as const;

type TypeTimeOffTrackerTab = typeof timeOffTrackerTab;
export type timeOffTrackerTab =
  TypeTimeOffTrackerTab[keyof TypeTimeOffTrackerTab];

export const tabIndexMapping: Record<number, string> = {
  [timeOffTrackerTab.TIME_OFF_BALANCE]: 'time-off-balance',
  [timeOffTrackerTab.TIME_OFF_REQUESTS]: 'time-off-requests',
};

export const requestStatusBadgeMapping: Record<
  TimeOffRequestStatus,
  BadgeStatusVariant
> = {
  [TimeOffRequestStatus.Approved]: 'success',
  [TimeOffRequestStatus.Cancelled]: 'neutral',
  [TimeOffRequestStatus.Pending]: 'warning',
  [TimeOffRequestStatus.Rejected]: 'critical',
};

export const requestStatusText: Record<TimeOffRequestStatus, string> = {
  [TimeOffRequestStatus.Approved]: 'Approved',
  [TimeOffRequestStatus.Cancelled]: 'Cancelled',
  [TimeOffRequestStatus.Pending]: 'Pending',
  [TimeOffRequestStatus.Rejected]: 'Rejected',
};

export type ShowAlertType = {
  status?: 'success' | 'error';
  content: string;
  shouldShow?: boolean;
};
