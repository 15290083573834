import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Icon,
  PrimaryButton,
  Spinner,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { FieldValues, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormCheckboxInput } from '../../../../components/FormCheckboxInput/FormCheckboxInput';
import {
  FormTextInput,
  TextInputError,
} from '../../../../components/FormTextInput/FormTextInput';
import { USER_AGREEMENT } from '../../../../constants/constants';
import {
  FrameContentContainer,
  StyledConsentCheckboxContainer,
  StyledConsentCheckboxLabelContainer,
  StyledLink,
  StyledRowContent,
  StyledSendEmailButtonContainer,
  StyledSendEmailErrorContainer,
} from './FrameStyle';

const validationSchema = z.object({
  email: z
    /* eslint-disable camelcase */
    .string({ required_error: 'Email is required' })
    /* eslint-enable camelcase */
    .email({ message: 'Invalid format for email address' }),
});

interface LandingFrameProps {
  onSend: (email: string) => void;
  isLoading: boolean;
  error: string;
  cooldown: number;
}

const LandingFrame = ({ onSend, isLoading, error, cooldown }: LandingFrameProps) => {
  const [timerCount, setTimerCount] = useState<number>(cooldown);

  useEffect(() => {
    setTimerCount(cooldown);
  }, [cooldown]);

  useEffect(() => {
    if (timerCount > 0) {
      const timer = setTimeout(() => {
        setTimerCount(timerCount - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timerCount]);

  const { control, handleSubmit, watch } = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });

  // submit the form when enter button is pressed
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit(handleClick)();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  const handleClick = (data: FieldValues) => {
    onSend(data.email);
  };

  useEffect(() => {
    const handleEnterKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit(handleClick)();
      }
    };
    document.addEventListener('keydown', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isConsented = watch("consent");
  const isEmailEmpty = !watch("email");

  return (
    <FrameContentContainer>
      <Typography as="div" variant="headline3" color={Neutral.B18}>
        Hello!
      </Typography>
      <Typography as="div" variant="subtitle1" color={Neutral.B18}>
        Log into your account without a password using a magic link
      </Typography>
      <StyledRowContent />
      <StyledRowContent>
        <FormTextInput
          control={control}
          name="email"
          placeholder="Enter your email address"
          height="auto"
        />
      </StyledRowContent>
      <StyledRowContent>
        <StyledConsentCheckboxContainer>
          <FormCheckboxInput
            control={control}
            name="consent"
            height="auto"
          />
          <StyledConsentCheckboxLabelContainer>
            <Typography as="span" variant="subtitle2" color={Neutral.B18}>
              I hereby agree to Glints&apos;&nbsp;
            </Typography>
            <StyledLink url={USER_AGREEMENT} target="_blank">
              <Typography as="span" variant="subtitle2">
                User Agreement
              </Typography>
            </StyledLink>
          </StyledConsentCheckboxLabelContainer>
        </StyledConsentCheckboxContainer>
      </StyledRowContent>
      <StyledSendEmailButtonContainer>
        <PrimaryButton
          size="large"
          icon={isLoading ? <Spinner /> : <Icon name="ri-sparkling2-fill" />}
          fullWidth={true}
          disabled={isLoading || timerCount > 0 || !isConsented || isEmailEmpty}
          onClick={handleSubmit(handleClick)}
        >
          {!isLoading && `Send Magic Link ${timerCount > 0 ? `(${timerCount})` : ''}`}
        </PrimaryButton>
        {error !== '' && (
          <StyledSendEmailErrorContainer>
            <TextInputError errorMessage={
              error?.toString().substring(7, 16) === "too often" ?
                "Please wait for a moment before sending another magic link." :
                "Something went wrong, please try again later."} />
          </StyledSendEmailErrorContainer>
        )}
      </StyledSendEmailButtonContainer>
    </FrameContentContainer>
  );
};

export default LandingFrame;
