import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Sidetab } from '@typeform/embed-react';
import { Alert, Portal } from 'glints-aries/lib/@next';
import { IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { getGraphqlClient } from '../../clients/graphql';
import { useGetUserQuery } from '../../generated/graphql';
import { useAuthToken, useCurrentUser } from '../../hooks/useAuthToken';
import { errorType } from '../../modules/Error/constants';
import { GetUserQuery, User } from '../MyProfileModal/interfaces';
import { MyProfileModal } from '../MyProfileModal/MyProfileModal';
import { CollapsibleFooter } from './components/CollapsibleFooter';
import { Header } from './components/Header';
import { LogoutButton } from './components/LogoutButton';
import { MyProfileButton } from './components/MyProfileButton';
import { Option } from './components/Option';
import { alertContent, AlertType } from './constants';
import {
  MenuContainer,
  MenuMobileContainer,
  MenuMobileSideContainer,
  MenuMobileWrapper,
} from './MenuStyle';
import {
  MainPageContainer,
  PageLayoutContainer,
  StyledHomeBackground,
} from './PageLayoutStyle';

interface PageLayoutProps {
  component: JSX.Element;
  requireAuth?: boolean; // if true, require auth to access this page
  direct?: boolean; // if true, redirect to /login immediately if not authenticated
  pageTitle?: string;
  metaDescription?: string;
  centered?: boolean; // make content vertically and horizontally centered
}

const PageLayout = ({
  component,
  requireAuth = true,
  direct = false,
  pageTitle = 'Glints Managed Talent',
  metaDescription = '',
  centered = false,
}: PageLayoutProps) => {
  const menuOptions: {
    iconName: IconNames;
    selectedIconName: IconNames;
    title: string;
    path: string;
    menuName: string;
    collapsedWidth: string;
  }[] = useMemo(
    () => [
      {
        iconName: 'ri-building-line',
        selectedIconName: 'ri-building-fill',
        title: 'Company Settings',
        path: '/company-settings/company-information',
        menuName: 'company-settings',
        collapsedWidth: '162px',
      },
      {
        iconName: 'ri-user5-line',
        selectedIconName: 'ri-user5-fill',
        title: 'Managed Talents',
        path: '/managed-talents',
        menuName: 'managed-talents',
        collapsedWidth: '167px',
      },
      {
        iconName: 'ri-suitcase3-line',
        selectedIconName: 'ri-suitcase3-fill',
        title: 'Time Off Tracker',
        path: '/time-off-tracker/time-off-requests',
        menuName: 'time-off-tracker',
        collapsedWidth: '164px',
      },
      {
        iconName: 'ri-calendar-check-line',
        selectedIconName: 'ri-calendar-check-fill',
        title: 'Attendance Log',
        path: '/attendance-log',
        menuName: 'attendance-log',
        collapsedWidth: '155px',
      },
      {
        iconName: 'ri-currency-line',
        selectedIconName: 'ri-currency-fill',
        title: 'Invoice Payments',
        path: '/invoice-payments/awaiting-payment',
        menuName: 'invoice-payments',
        collapsedWidth: '172px',
      },
    ],
    []
  );

  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();
  const { isAuthenticated, logout } = useAuthToken();
  const currentUser = useCurrentUser();
  const [showAlert, setShowAlert] = useState({
    shouldShow: false,
    type: AlertType.SUCCESS,
  });

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(true);
  const handleWindowSizeChange = () =>
    setIsLargeScreen(window.innerWidth > 768);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isAnimate, setIsAnimate] = useState<boolean>(false);
  const [isMenuAnimate, setIsMenuAnimate] = useState<boolean>(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>();
  const [openSideTabTypeForm, setOpenSideTabTypeForm] = useState(false);

  const { data: userData } = useGetUserQuery<GetUserQuery, Error>(
    graphqlClient,
    { userId: currentUser.userId }
  );

  const handleProfileModalClose = () => setIsProfileModalOpen(false);

  // if url change (e.g., refresh page, manually typed), update selectedOption accordingly
  const location = useLocation();
  useEffect(() => {
    const removeTrailingSlash = (inputString: string) => {
      if (inputString.endsWith('/')) return inputString.slice(0, -1);
      return inputString;
    };
    const path = removeTrailingSlash(window.location.pathname);
    const currentMenu = path.split('/')[1];
    const index = menuOptions.findIndex(menu => menu.menuName === currentMenu);
    setSelectedOption(index);
  }, [location, menuOptions]);

  useEffect(() => {
    if (isLargeScreen) setIsCollapsed(false);
  }, [isLargeScreen]);
  useEffect(() => {
    if (!isLargeScreen) setIsCollapsed(true);
  }, [isLargeScreen, navigate]);

  // prevent scrolling when menu bar is open in small screen
  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) return;
    const enableScroll = () => (document.body.style.overflow = 'unset');
    if (!isLargeScreen && !isCollapsed) {
      document.body.style.overflow = 'hidden';
    } else enableScroll();
    return () => {
      enableScroll();
    };
  }, [isLargeScreen, isCollapsed]);

  const [isHomePageClicked, setIsHomePageClicked] = useState<boolean>(false);
  const handleOptionClick = (index: number | null) => {
    setIsAnimate(false);
    if (index === selectedOption && !isLargeScreen) {
      setIsCollapsed(true);
      return;
    }
    setSelectedOption(index);
    setIsHomePageClicked(false);
    if (index === null) {
      navigate('/');
      setIsHomePageClicked(true);
    } else navigate(menuOptions[index].path);
  };

  const handleCollapseClick = () => {
    setIsAnimate(true);
    if (!isCollapsed && !isLargeScreen) {
      setIsMenuAnimate(true);
      setTimeout(() => {
        setIsCollapsed(!isCollapsed);
        setIsMenuAnimate(false);
      }, 180);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  const updateUser = (data: User) => {
    setUser(data);
  };

  const updateShowAlert = (showAlertValue: {
    shouldShow: boolean;
    type: AlertType;
  }) => {
    setShowAlert(showAlertValue);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData) {
      updateUser(userData.contact);
    }
  }, [userData]);

  const MenuComponent = () =>
    isLargeScreen ? (
      <>
        <MenuContainer data-collapsed={isCollapsed} data-animate={isAnimate}>
          <Header
            isCollapsed={isCollapsed}
            onCollapseClick={handleCollapseClick}
            isLargeScreen={isLargeScreen}
            onLogoClick={() => handleOptionClick(null)}
            isHomePageClicked={isHomePageClicked}
          />
          {menuOptions.map((menu, i) => (
            <Option
              key={i}
              iconName={menu.iconName}
              selectedIconName={menu.selectedIconName}
              title={menu.title}
              isSelected={selectedOption === i}
              isCollapsed={isCollapsed}
              onClick={() => handleOptionClick(i)}
              collapseWidth={menu.collapsedWidth}
            />
          ))}
          <CollapsibleFooter
            isNavCollapsed={isCollapsed}
            name={userData?.contact.name}
            role={userData?.contact.roles[0].id}
            jobTitle={userData?.contact.jobTitle}
            updateNavCollapsed={(newValue: boolean) => setIsCollapsed(newValue)}
          >
            <MyProfileButton
              isCollapsed={isCollapsed}
              handleClick={() => setIsProfileModalOpen(true)}
            />
            <LogoutButton isCollapsed={isCollapsed} />
          </CollapsibleFooter>
        </MenuContainer>
      </>
    ) : isCollapsed ? (
      <MenuMobileContainer data-collapsed={true}>
        <Header
          isCollapsed={true}
          onCollapseClick={handleCollapseClick}
          isLargeScreen={isLargeScreen}
          onLogoClick={() => handleOptionClick(null)}
        />
      </MenuMobileContainer>
    ) : (
      <>
        <MenuMobileContainer data-collapsed={true}>
          <Header
            isCollapsed={true}
            onCollapseClick={handleCollapseClick}
            isLargeScreen={isLargeScreen}
            onLogoClick={() => handleOptionClick(null)}
            faded={true}
          />
        </MenuMobileContainer>
        <Portal>
          <MenuMobileWrapper data-menu-animate={isMenuAnimate}>
            <MenuMobileSideContainer data-menu-animate={isMenuAnimate}>
              <Header
                isCollapsed={false}
                onCollapseClick={handleCollapseClick}
                isLargeScreen={isLargeScreen}
                onLogoClick={() => handleOptionClick(null)}
              />
              <div style={{ height: '24px' }} />
              {menuOptions.map((menu, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: '16px',
                  }}
                >
                  <Option
                    key={i}
                    iconName={menu.iconName}
                    selectedIconName={menu.selectedIconName}
                    title={menu.title}
                    isSelected={selectedOption === i}
                    isCollapsed={false}
                    onClick={() => handleOptionClick(i)}
                    collapseWidth={menu.collapsedWidth}
                  />
                </div>
              ))}
              <CollapsibleFooter
                isNavCollapsed={isCollapsed}
                name={userData?.contact.name}
                role={userData?.contact.roles[0].id}
                jobTitle={userData?.contact.jobTitle}
                updateNavCollapsed={(newValue: boolean) =>
                  setIsCollapsed(newValue)
                }
              >
                <MyProfileButton
                  isCollapsed={isCollapsed}
                  handleClick={() => setIsProfileModalOpen(true)}
                />
                <LogoutButton isCollapsed={isCollapsed} />
              </CollapsibleFooter>
            </MenuMobileSideContainer>
          </MenuMobileWrapper>
        </Portal>
      </>
    );

  return (
    <>
      <MyProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleProfileModalClose}
        user={user}
        updateUser={updateUser}
        updateShowAlert={updateShowAlert}
      />
      <Sidetab
        id="EhGEiKaG"
        buttonText="Report"
        customIcon="https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/layout/questionnaire-icon.svg"
        buttonTextColor={Blue.S99}
        buttonColor={Neutral.B100}
        hidden={{
          name: userData?.contact?.name || '',
          email: userData?.contact?.email || '',
          company: userData?.company?.name || '',
          /* eslint-disable camelcase */
          user_id: userData?.contact?.id || '',
          user_role: userData?.contact?.roles[0].id || '',
          job_title: userData?.contact?.jobTitle || '',
        }}
      />
      {requireAuth && !isAuthenticated ? (
        direct ? (
          <Navigate to="/login" />
        ) : (
          <Navigate
            to="/magic-link"
            state={{ errorType: errorType.UNAUTHORIZED }}
          />
        )
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <StyledHomeBackground />
          <PageLayoutContainer className="page-layout-container">
            <MenuComponent />
            <MainPageContainer
              data-collapsed={isCollapsed}
              data-animate={isAnimate}
              data-hasframe={!direct}
              data-centered={centered}
              className="main-page-container"
            >
              {component}
            </MainPageContainer>
          </PageLayoutContainer>
        </>
      )}
      <Alert
        show={showAlert.shouldShow}
        onDismissed={() =>
          setShowAlert({
            ...showAlert,
            shouldShow: false,
          })
        }
        content={alertContent[showAlert.type]}
        status={showAlert.type}
      />
    </>
  );
};

export default PageLayout;
