import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Alert,
  Badge,
  Icon,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { getGraphqlClient } from '../../../clients/graphql';
import {
  AttendanceSortField,
  AttendanceStatus,
  ExportTalentAttendanceMutation,
  GetTalentAttendanceLogsQuery,
  SortOrder,
  useExportTalentAttendanceMutation,
  useGetTalentAttendanceLogsQuery,
} from '../../../generated/graphql';
import { handleAuthError } from '../../../utils/handleAuthError';
import { periodFilterOptions } from '../components/PeriodFilter';
import {
  alertContent,
  AlertType,
  JS_MONTH_OFFSET,
  ShowAlertType,
} from '../constants';
import { DailyLogTable } from './components/DailyLogTable';
import {
  employmentStatusText,
  selectAttendanceStatusOptions,
  talentStatusBadgeMapping,
} from './constants';
import {
  AttendanceStatusSelect,
  AttendanceStatusSelectContainer,
  BackButtonContainer,
  Container,
  ExportContainer,
  FilterContainer,
  HeadingContainer,
  HeadingNav,
  HeadingTitle,
  LeftContainer,
  PeriodSelect,
  PeriodSelectContainer,
} from './DailyLogStyle';

const DailyLog = () => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<ShowAlertType>({
    status: undefined,
    content: '',
    shouldShow: false,
  });
  const { hubberId } = useParams();
  const today = new Date();
  const [searchParams, setSearchParams] = useSearchParams({
    status: selectAttendanceStatusOptions[0].value,
  });
  const [prevSearch] = useState(searchParams.get('prevSearch') ?? '');
  const [prevPage] = useState(searchParams.get('prevPage') ?? '1');
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    (searchParams.get('sortOrder') as SortOrder) ?? SortOrder.Desc
  );
  const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState(
    searchParams.get('status') ?? selectAttendanceStatusOptions[0].value
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    searchParams.get('year') && searchParams.get('month')
      ? format(
        new Date(`${searchParams.get('year')} ${searchParams.get('month')}`),
        'MMM yyy'
      )
      : format(today, 'MMM yyy')
  );

  const getAttendanceVariables = {
    id: hubberId,
    period: {
      year: new Date(selectedPeriod).getFullYear(),
      month: new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET,
    },
    sorts: {
      field: AttendanceSortField.Date,
      order: sortOrder,
    },
    ...(selectedAttendanceStatus !== selectAttendanceStatusOptions[0].value && {
      status: selectedAttendanceStatus as AttendanceStatus,
    }),
  };

  const {
    isLoading: isLoadingExport,
    mutate: exportMutate,
  } = useExportTalentAttendanceMutation<Error, ExportTalentAttendanceMutation>(
    graphqlClient
  );

  const {
    isLoading: isLoadingAttendance,
    data: attendanceData,
    error: attendanceError,
    isFetching: isFetchingAttendance,
  } = useGetTalentAttendanceLogsQuery<GetTalentAttendanceLogsQuery, Error>(
    graphqlClient,
    getAttendanceVariables,
    {}
  );

  const handleBack = () => {
    navigate({
      pathname: '/attendance-log',
      search: createSearchParams({
        year: `${new Date(selectedPeriod).getFullYear()}`,
        month: `${new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET}`,
        page: prevPage,
        name: prevSearch,
      }).toString(),
    });
  };

  useEffect(() => {
    const handlePopState = () => handleBack();
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleResetFiltersClick = () => {
    setSelectedAttendanceStatus(selectAttendanceStatusOptions[0].value);
    setSelectedPeriod(format(today, 'MMM yyy'));
  };

  const handleSortClick = () => {
    setSortOrder(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
  };

  const handleSelectPeriod = ({ value }: { value: string }) => {
    setSelectedPeriod(value);
    setSearchParams({
      year: `${new Date(value).getFullYear()}`,
      month: `${new Date(value).getMonth() + JS_MONTH_OFFSET}`,
    });
  };

  const handleSelectStatus = ({ value }: { value: string }) => {
    setSelectedAttendanceStatus(value);
    setSearchParams({ status: value });
  };

  const onExport = async () => {
    const exportVariables = {
      hubberId,
      period: {
        year: new Date(selectedPeriod).getFullYear(),
        month: new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET,
      },
      ...(selectedAttendanceStatus !==
        selectAttendanceStatusOptions[0].value && {
        status: selectedAttendanceStatus as AttendanceStatus,
      }),
    };

    exportMutate(exportVariables, {
      onSuccess: exportData => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });
        window.open(exportData?.exportHubberAttendance?.fileUrl, '_blank');
      },
      onError() {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  useEffect(() => {
    setSearchParams({
      year: `${new Date(selectedPeriod).getFullYear()}`,
      month: `${new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET}`,
      status: selectedAttendanceStatus,
      sortField: AttendanceSortField.Date,
      sortOrder,
    });
  }, [selectedAttendanceStatus, selectedPeriod, setSearchParams, sortOrder]);

  if (attendanceError && attendanceError.message.substring(21, 24) === '401')
    return handleAuthError();

  return (
    <Container>
      <HeadingContainer>
        <HeadingNav>
          <BackButtonContainer onClick={handleBack}>
            <Icon name="ri-arrow-m-left-line" height="24px" fill={Neutral.B40} />
          </BackButtonContainer>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Back to overview
          </Typography>
        </HeadingNav>
        <HeadingTitle>
          <Typography variant="headline6" as="span" color={Neutral.B18}>
            {attendanceData?.hubber.fullName}
          </Typography>
          {attendanceData?.hubber.status && (
            <Badge
              status={talentStatusBadgeMapping[attendanceData?.hubber.status]}
            >
              {employmentStatusText[attendanceData?.hubber.status]}
            </Badge>
          )}
        </HeadingTitle>
      </HeadingContainer>
      <FilterContainer>
        <LeftContainer>
          <PeriodSelectContainer>
            <PeriodSelect
              prefix={
                <Icon
                  name="ri-calendar-event-line"
                  height="20px"
                  fill={Neutral.B40}
                />
              }
              options={periodFilterOptions()}
              onSelect={handleSelectPeriod}
              selectedValues={[selectedPeriod]}
              width="100%"
              listHeight={248}
            />
          </PeriodSelectContainer>
          <AttendanceStatusSelectContainer>
            <AttendanceStatusSelect
              prefix={
                <Icon
                  name="ri-equalizer-line"
                  height="20px"
                  fill={Neutral.B40}
                />
              }
              options={selectAttendanceStatusOptions}
              onSelect={handleSelectStatus}
              selectedValues={[selectedAttendanceStatus]}
              width="100%"
              listHeight={248}
            />
          </AttendanceStatusSelectContainer>
        </LeftContainer>
        <ExportContainer>
          <PrimaryButton
            icon={<Icon name="ri-download-line" />}
            type="button"
            onClick={onExport}
            loading={isLoadingExport}
          >
            Export
          </PrimaryButton>
        </ExportContainer>
      </FilterContainer>
      <DailyLogTable
        hub={attendanceData?.hubber.hub}
        attendanceLogs={attendanceData?.hubber.attendance.data}
        sortOrder={sortOrder}
        onSort={handleSortClick}
        loading={isLoadingAttendance || isFetchingAttendance}
        handleResetFiltersClick={handleResetFiltersClick}
      />
      <Alert
        show={showAlert.shouldShow}
        onDismissed={() =>
          setShowAlert({ status: undefined, content: '', shouldShow: false })
        }
        content={showAlert.content}
        status={showAlert.status}
      />
    </Container>
  );
};

export default DailyLog;
