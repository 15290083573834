import React, { useEffect, useState } from 'react';
import {
  ActionList,
  ActionListItem,
  Alert,
  Icon,
  Popover,
  Select,
  TextInput,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { getGraphqlClient } from '../../../clients/graphql';
import {
  EmploymentStatus,
  ExportTimeoffRequestsMutation,
  GetTimeOffRequestsQuery,
  SortOrder,
  TimeOffRequestSortField,
  TimeOffRequestStatus,
  useExportTimeoffRequestsMutation,
  useGetTimeOffRequestsQuery,
} from '../../../generated/graphql';
import { useDebounce } from '../../../hooks/debounce';
import { StatusFilterSelect } from '../../../modules/ManagedTalents/components/StatusFilterSelect';
import { statusFilterOptions } from '../../../modules/ManagedTalents/constants';
import { handleAuthError } from '../../../utils/handleAuthError';
import {
  alertContent,
  AlertType,
  PAGE_SIZE,
  selectRequestStatusOptions,
  TALENT_OPTIONS,
} from '../constants';
import { ShowAlertType } from '../interfaces';
import { TimeOffRequestsTable } from './components/TimeOffRequestsTable';
import {
  ExportContainer,
  FilterContainer,
  SearchInputContainer,
  SelectContainer,
  SelectTalentContainer,
  StyledPrimaryButton,
} from './TimeOffRequestsTabStyle';

const TimeOffRequstsTab = () => {
  const graphqlClient = getGraphqlClient();
  const [showAlert, setShowAlert] = useState<ShowAlertType>({
    status: undefined,
    content: '',
    shouldShow: false,
  });
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [clickedExport, setClickedExport] = useState(false);
  const [selectedRequestStatus, setSelectedRequestStatus] = useState(
    selectRequestStatusOptions[0].value
  );
  const [sortOrder, setSortOrder] = useState(SortOrder.Desc);
  const [disableExportFiltered, setDisableExportFiltered] = useState(true);
  const [selectedTalent, setSelectedTalent] = useState<string[]>([
    TALENT_OPTIONS[0].value,
  ]);
  const [hideStatusBadge, setHideStatusBadge] = useState(false);
  const [hasFilters, setHasFilters] = useState(true);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const getTimeOffRequestsVariables = {
    page,
    pageSize: PAGE_SIZE,
    hubberName: debouncedSearchValue,
    ...(selectedRequestStatus !== selectRequestStatusOptions[0].value && {
      requestStatus: selectedRequestStatus as TimeOffRequestStatus,
    }),
    sorts: {
      field: TimeOffRequestSortField.RequestedAt,
      order: sortOrder,
    },
    hubberStatus: selectedTalent as EmploymentStatus[],
  };

  useEffect(() => {
    if (
      !(
        Boolean(debouncedSearchValue) ||
        selectedTalent.length > 0 ||
        selectedRequestStatus !== selectRequestStatusOptions[0].value
      )
    ) {
      setHasFilters(false);
      return;
    }

    setHasFilters(true);
  }, [debouncedSearchValue, selectedRequestStatus, selectedTalent]);

  const {
    isLoading: isLoadingTimeOffRequests,
    data: timeOffRequestsData,
    isFetching: isFetchingTimeOffRequests,
    error: timeoffRequestsError,
  } = useGetTimeOffRequestsQuery<GetTimeOffRequestsQuery, Error>(
    graphqlClient,
    getTimeOffRequestsVariables,
    {}
  );

  const { isLoading: isLoadingExport, mutate: exportMutate } =
    useExportTimeoffRequestsMutation<Error, ExportTimeoffRequestsMutation>(
      graphqlClient
    );

  const handleSortClick = () => {
    resetPage();
    setSortOrder(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
  };

  const handleResetSearch = () => {
    setSearchValue('');
  };

  const resetPage = () => {
    setPage(1);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSearchChange = (value: string) => {
    resetPage();
    setSearchValue(value);

    if (
      value === '' &&
      selectedRequestStatus === selectRequestStatusOptions[0].value
    ) {
      setDisableExportFiltered(true);
    }
    if (value) {
      setDisableExportFiltered(false);
    }
  };

  const handleSelect = ({
    value,
  }: {
    value: string | TimeOffRequestStatus;
  }) => {
    resetPage();
    setSelectedRequestStatus(value);

    if (value === selectRequestStatusOptions[0].value && searchValue === '') {
      setDisableExportFiltered(true);
    }

    if (value !== selectRequestStatusOptions[0].value) {
      setDisableExportFiltered(false);
    }
  };

  const handleTalentSelect = ({ value }: { value: string }) => {
    let updatedSelectedStatus = [];
    if (selectedTalent) {
      if (selectedTalent.includes(value)) {
        updatedSelectedStatus = selectedTalent.filter(
          option => option !== value
        );
        setSelectedTalent(updatedSelectedStatus);
        setPage(1);
      } else {
        updatedSelectedStatus = [...selectedTalent, value];
        setSelectedTalent(updatedSelectedStatus);
        setPage(1);
      }
      if (updatedSelectedStatus?.length < statusFilterOptions?.length) {
        setHideStatusBadge(false);
      }
    }
  };
  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const handleExportAll = () => {
    const exportVariables = {
      hubberName: undefined,
    };

    exportMutate(exportVariables, {
      onSuccess: data => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });
        window.open(data?.exportTimeoffRequests?.fileUrl, '_blank');
      },
      onError: () => {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  const handleExportFilteredData = () => {
    const exportVariables = {
      hubberName: debouncedSearchValue,
      requestStatus:
        selectedRequestStatus === 'ALL'
          ? undefined
          : (selectedRequestStatus as TimeOffRequestStatus),
    };

    exportMutate(exportVariables, {
      onSuccess: data => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });
        window.open(data?.exportTimeoffRequests?.fileUrl, '_blank');
      },
      onError: () => {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  const handleResetAll = () => {
    setPage(1);
    setSearchValue('');
    setSelectedTalent([]);
    setSelectedRequestStatus(selectRequestStatusOptions[0].value);
    setHasFilters(false);
  };

  const activator = (
    <StyledPrimaryButton
      onClick={() => setClickedExport(true)}
      loading={isLoadingExport}
    >
      <Icon name="ri-download-line" className="export-download-icon" />
      <Typography variant="button">Export</Typography>
      <Icon name="ri-arrow-m-down-fill" className="export-down-icon" />
    </StyledPrimaryButton>
  );

  const actions: ActionListItem[] = [
    { content: 'Export all', action: handleExportAll },
    {
      content: 'Export filtered data',
      action: handleExportFilteredData,
      disabled: disableExportFiltered,
    },
  ];

  const pageHeaderActions = (
    <FilterContainer>
      <SearchInputContainer>
        <TextInput
          value={searchValue}
          prefix={<Icon name="ri-search" />}
          placeholder="Search for Name"
          onChange={handleSearchChange}
        />
      </SearchInputContainer>
      <SelectContainer>
        <Select
          prefix={
            <Icon name="ri-equalizer-line" height="20px" fill={Neutral.B40} />
          }
          options={selectRequestStatusOptions}
          onSelect={handleSelect}
          selectedValues={[selectedRequestStatus]}
          width="100%"
        />
      </SelectContainer>
      <SelectTalentContainer>
        <StatusFilterSelect
          selectedStatus={selectedTalent}
          handleSelectStatus={handleTalentSelect}
          updateHideStatusBadge={updateHideStatusBadge}
          hideBadge={hideStatusBadge}
        />
      </SelectTalentContainer>
      <ExportContainer>
        <Popover
          active={clickedExport}
          activator={activator}
          onClose={() => {
            setClickedExport(false);
          }}
        >
          <ActionList items={actions} />
        </Popover>
      </ExportContainer>
    </FilterContainer>
  );

  if (
    timeoffRequestsError &&
    timeoffRequestsError.message.substring(21, 24) === '401'
  )
    return handleAuthError();

  return (
    <>
      {pageHeaderActions}
      <TimeOffRequestsTable
        sortOrder={sortOrder}
        onSort={handleSortClick}
        timeOffRequests={timeOffRequestsData?.timeoffRequests?.data}
        onPageChanged={handlePageChange}
        onResetSearch={handleResetSearch}
        loading={isLoadingTimeOffRequests || isFetchingTimeOffRequests}
        currentPage={timeOffRequestsData?.timeoffRequests.pageNo || 1}
        pageSize={timeOffRequestsData?.timeoffRequests.pageSize || 0}
        totalItems={timeOffRequestsData?.timeoffRequests.total || 0}
        isNoHubbers={
          !(
            !isLoadingTimeOffRequests &&
            timeOffRequestsData?.timeoffRequests?.data.length === 0
          ) || !hasFilters
        }
        onResetAll={handleResetAll}
      />
      <Alert
        show={showAlert.shouldShow}
        onDismissed={() =>
          setShowAlert({ status: undefined, content: '', shouldShow: false })
        }
        content={showAlert.content}
        status={showAlert.status}
      />
    </>
  );
};

export default TimeOffRequstsTab;
