import { Button } from 'glints-aries/lib/@next';
import { space24 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const MainContainer = styled.div`
  .tabs-header-container {
    margin-top: 8px;
    overflow-x: initial;
  }

  ul {
    gap: 0px;
    padding-inline-start: 0px;
  }

  li {
    padding: 0 2px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledPageTitleContainer = styled.div`
  padding-bottom: 4px;
`;

export const AddContactButton = styled(Button)`
  margin-bottom: ${space24};
`;

export const TabContentContainer = styled.div`
  padding-top: ${space24};
`;
