import React from 'react';
import { Icon, Tooltip, Typography } from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';

import { SUPPORT_EMAIL } from '../../../constants/constants';
import { ContactUsContent, EmailSupportContainer } from '../ErrorStyle';

export const ContactUs = () => {
  const copySupportEmailToClipboard = () => {
    navigator.clipboard.writeText(SUPPORT_EMAIL);
  };

  return (
    <ContactUsContent>
      <Typography as="span" variant="subtitle2">
        Still having an issue? Contact us at &nbsp;
      </Typography>
      <EmailSupportContainer>
        <Typography as="span" variant="subtitle2" color={Blue.S99}>
          {SUPPORT_EMAIL}
        </Typography>
        <Tooltip
          preferredPosition="right-middle"
          content="Copied"
          clickable={true}
          timeout={1000}
          onClick={copySupportEmailToClipboard}
        >
          <Icon name="ri-file-copy2-line" fill={Blue.S99} height="20px" />
        </Tooltip>
      </EmailSupportContainer>
    </ContactUsContent>
  );
};
