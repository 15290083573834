import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Alert,
  Button,
  ButtonGroup,
  EmptyState,
  Icon,
  PrimaryButton,
  Select,
  SkeletonImageSquare,
  TextInput,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { DataTable, TableHeading } from 'glints-aries/lib/@next/DataTable';
import {
  Blue,
  Neutral,
  Orange,
  Red,
} from 'glints-aries/lib/@next/utilities/colors';
import { ComponentAction } from 'glints-aries/lib/types/componentAction';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ReactComponent as NebulaSVG } from '../../../assets/images/nebula.svg';
import { getGraphqlClient } from '../../../clients/graphql';
import { StatusIndicator } from '../../../components/StatusIndicator/StatusIndicator';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import {
  EmploymentStatus,
  ExportAttendanceSummaryMutation,
  GetAttendanceLogQuery,
  HubberSortField,
  SortOrder,
  useExportAttendanceSummaryMutation,
  useGetAttendanceLogQuery,
} from '../../../generated/graphql';
import { useDebounce } from '../../../hooks/debounce';
import { StatusFilterSelect } from '../../../modules/ManagedTalents/components/StatusFilterSelect';
import {
  flagMapping,
  hubMapping,
  statusFilterOptions,
} from '../../../modules/ManagedTalents/constants';
import { HubCode } from '../../../modules/ManagedTalents/interface';
import { handleAuthError } from '../../../utils/handleAuthError';
import { periodFilterOptions } from '../components/PeriodFilter';
import {
  alertContent,
  AlertType,
  HOUR_TOLERANCE,
  JS_MONTH_OFFSET,
  PAGE_SIZE,
  TALENT_OPTIONS,
} from '../constants';
import { daysOffData, scheduleData } from '../Schedule/SampleData';
import { ScheduleSideSheet } from '../Schedule/ScheduleSideSheet';
import {
  AttendanceLogHeader,
  ClickableCell,
  DataTableContainer,
  EmptyStateContainer,
  FlagContainer,
  HeaderExportButtonContainer,
  HeaderRequestFilterContainer,
  HeaderSearchContainer,
  HeaderTalentFilterContainer,
  HubLocationContainer,
  TableButtonContainer,
  TableHeadingCellContainer,
  TablePaginationContainer,
  TalentHubCellContainer,
  TotalHourCell,
} from './AttendanceLogTableStyle';
import { HubberDetailsSideSheet } from './components/HubberDetailsSideSheet';

export const AttendanceLogTable = ({
  updateShowBanner,
}: {
  updateShowBanner(shouldShow: boolean): void;
}) => {
  const today = new Date();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get('name') ?? ''
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    searchParams.get('year') && searchParams.get('month')
      ? format(
        new Date(`${searchParams.get('year')} ${searchParams.get('month')}`),
        'MMM yyy'
      )
      : format(today, 'MMM yyy')
  );
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState(
    searchParams.getAll('status') ?? [TALENT_OPTIONS[0].value]
  );

  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

  const [showHubberSidesheet, setShowHubberSidesheet] =
    useState<boolean>(false);
  const [showScheduleSidesheet, setShowScheduleSidesheet] =
    useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<{
    status: undefined | 'success' | 'error';
    content: string;
    shouldShow: boolean;
  }>({
    status: undefined,
    content: '',
    shouldShow: false,
  });
  const [hideStatusBadge, setHideStatusBadge] = useState(false);

  const [currentDataIndex, setCurrentDataIndex] = useState<number>(0);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();

  const variables = {
    page: currentPage,
    pageSize: PAGE_SIZE,
    sorts: [{ field: HubberSortField.FullName, order: sortOrder }],
    name: debouncedSearchValue,
    status: selectedEmploymentStatus as EmploymentStatus[],
    period: {
      year: new Date(selectedPeriod).getFullYear(),
      month: new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET,
    },
  };
  const { data, isLoading, error } = useGetAttendanceLogQuery<
    GetAttendanceLogQuery,
    Error
  >(graphqlClient, variables, {});

  const hubsWithAttendance = ['BT', 'ID'];

  const hasOnlyAttendanceData = data?.company.hubberHubs.every((hub: string) =>
    hubsWithAttendance.includes(hub)
  );
  const hasSomeAttendanceData = data?.company.hubberHubs.some((hub: string) =>
    hubsWithAttendance.includes(hub)
  );

  const { isLoading: isLoadingExport, mutate: exportMutate } =
    useExportAttendanceSummaryMutation<Error, ExportAttendanceSummaryMutation>(
      graphqlClient
    );

  // differentiate between no hubbers and no search results // TODO - is there a better way? this is quite confusing
  const [isNoHubbers, setIsNoHubbers] = useState<boolean>(false);
  useEffect(() => {
    if (!isLoading && data?.hubbers?.data?.length === 0) {
      setIsNoHubbers(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value: string) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  const handleSelectPeriod = ({ value }: { value: string }) => {
    setCurrentPage(1);
    setSelectedPeriod(value);
    setSearchParams({
      year: `${new Date(value).getFullYear()}`,
      month: `${new Date(value).getMonth() + JS_MONTH_OFFSET}`,
    });
  };

  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const handleTalentSelect = ({ value }: { value: string }) => {
    let updatedSelectedStatus = [];

    if (selectedEmploymentStatus) {
      if (selectedEmploymentStatus.includes(value)) {
        updatedSelectedStatus = selectedEmploymentStatus.filter(
          option => option !== value
        );
        setSelectedEmploymentStatus(updatedSelectedStatus);
        setCurrentPage(1);
      } else {
        updatedSelectedStatus = [...selectedEmploymentStatus, value];
        setSelectedEmploymentStatus(updatedSelectedStatus);
        setCurrentPage(1);
      }

      if (updatedSelectedStatus?.length < statusFilterOptions?.length) {
        setHideStatusBadge(false);
      }
    }
  };

  const handleExport = () => {
    const exportVariables = {
      year: new Date(selectedPeriod).getFullYear(),
      month: new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET,
    };
    exportMutate(exportVariables, {
      onSuccess: data => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });
        window.open(data?.exportAttendanceSummary?.fileUrl, '_blank');
      },
      onError: () => {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  const handleSortClick = () => {
    setCurrentPage(1);
    setSortOrder(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
  };

  useEffect(() => {
    setSearchParams({
      page: `${currentPage}`,
      pageSize: `${PAGE_SIZE}`,
      sortField: HubberSortField.FullName,
      sortOrder,
      name: debouncedSearchValue,
      status: selectedEmploymentStatus as EmploymentStatus[],
      year: `${new Date(selectedPeriod).getFullYear()}`,
      month: `${new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET}`,
    });
  }, [
    currentPage,
    debouncedSearchValue,
    selectedEmploymentStatus,
    selectedPeriod,
    setSearchParams,
    sortOrder,
  ]);

  useEffect(() => {
    if (hasOnlyAttendanceData) {
      updateShowBanner(false);
      return;
    }

    if (hasSomeAttendanceData || !hasOnlyAttendanceData) {
      updateShowBanner(true);
      return;
    }
  }, [hasOnlyAttendanceData, hasSomeAttendanceData, updateShowBanner]);

  const headings: TableHeading[] = [
    {
      title: 'Managed Talent',
      defaultSortDirection: 'DESCENDING',
    },
    {
      title: 'TalentHub Location',
    },
    {
      title: (
        <TableHeadingCellContainer>
          Expected Total Hours
          <Tooltip
            content="This is the expected total number of hours an individual is obligated to work over a specific period, accounting for deductions for leaves and holidays."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </TableHeadingCellContainer>
      ),
      align: 'right',
    },
    {
      title: (
        <TableHeadingCellContainer>
          Actual Total Hours
          <Tooltip
            content="This represents the total working hours when check-in and check-out times are logged within a day."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </TableHeadingCellContainer>
      ),
      align: 'right',
    },
    {
      title: 'Actions',
    },
  ];

  const emptyState = (
    <EmptyState
      title="No Logging Data"
      description="None of your talent has ever been required to log attendance"
      image={<NebulaSVG />}
    />
  );
  const noMatchAction: ComponentAction = {
    label: 'Clear All Filters',
    onClick: () => {
      setCurrentPage(1);
      setSearchValue('');
      setSelectedEmploymentStatus([]);
      setSelectedPeriod(format(today, 'MMM yyy'));
    },
  };
  const noMatchingResultsState = (
    <EmptyState
      title="No Logging Data"
      description="No results were found based on current filtering conditions."
      basicButtonAction={noMatchAction}
    />
  );

  const loadingRow = [...Array(10).keys()].map(n => (
    <DataTable.Row id={`loading-row-${n}`} key={n} position={n}>
      {[...Array(5).keys()].map(n => (
        <DataTable.Cell key={`loading-row-cell-${n}`}>
          <SkeletonImageSquare height="24px" width="100%" />
        </DataTable.Cell>
      ))}
    </DataTable.Row>
  ));

  const currentHubber = data?.hubbers.data[currentDataIndex];
  const rowMarkup = data?.hubbers.data.map((data, index) => {
    const expectedHours = parseFloat(data?.attendance.scheduledHours).toFixed(
      1
    );
    const actualHours = parseFloat(data?.attendance.actualHours).toFixed(1);
    const hasAttendance = hubsWithAttendance.includes(data.hub);

    return (
      <DataTable.Row key={index}>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <ClickableCell
            onClick={() => {
              setCurrentDataIndex(index);
              setShowHubberSidesheet(true);
            }}
            data-left-padding={true}
          >
            <Typography
              as="span"
              color={Blue.S99}
              className="hubber-name-span"
              style={{
                zIndex: 10,
              }}
            >
              {data.fullName}
            </Typography>
            <StatusIndicator active={data.status === EmploymentStatus.Active} />
          </ClickableCell>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          <TalentHubCellContainer>
            <FlagContainer>{flagMapping[data.hub as HubCode]}</FlagContainer>
            <HubLocationContainer>
              <Typography as="span" variant="subtitle2">
                {data.hub}
              </Typography>
              {(data.hub === 'BT' || data.hub === 'ID') && (
                <Typography as="span" variant="overline" color={Neutral.B40}>
                  {` (${hubMapping[data.hub as HubCode]})`}
                </Typography>
              )}
            </HubLocationContainer>
          </TalentHubCellContainer>
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          <Typography
            as="span"
            variant="subtitle2"
            color={hasAttendance ? Neutral.B18 : Neutral.B85}
          >
            {hasAttendance ? expectedHours : `Inapplicable`}
          </Typography>
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          <TotalHourCell>
            {parseFloat(actualHours) <
              parseFloat(expectedHours) - HOUR_TOLERANCE && (
              <Icon
                name="ri-arrow-down-line"
                height="16px"
                width="16px"
                fill={Orange.S86}
              />
            )}
            {parseFloat(actualHours) >
              parseFloat(expectedHours) + HOUR_TOLERANCE && (
              <Icon
                name="ri-arrow-up-line"
                height="16px"
                width="16px"
                fill={Red.B93}
              />
            )}
            <Typography
              as="span"
              variant="subtitle2"
              color={hasAttendance ? Neutral.B18 : Neutral.B85}
            >
              {hasAttendance ? actualHours : `Inapplicable`}
            </Typography>
          </TotalHourCell>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <ButtonGroup>
            {/* THIS IS DISABLED FOR NOW DUE TO LACK OF DATA
            <Button size="slim" onClick={() => {
              setCurrentDataIndex(index);
              setShowScheduleSidesheet(true);
            }}>
              <TableButtonContainer>
                View Schedule
              </TableButtonContainer>
            </Button> */}
            <Button
              size="slim"
              icon={<Icon name="ri-arrow-m-right-line" />}
              iconPosition="right"
              onClick={() => {
                navigate({
                  pathname: `${data?.id}`,
                  search: createSearchParams({
                    year: `${new Date(selectedPeriod).getFullYear()}`,
                    month: `${
                      new Date(selectedPeriod).getMonth() + JS_MONTH_OFFSET
                    }`,
                    prevPage: `${currentPage}`,
                    prevSearch: `${debouncedSearchValue}`,
                  }).toString(),
                });
              }}
              disabled={!hasAttendance}
            >
              <TableButtonContainer data-no-margin-right={true}>
                Daily Log
              </TableButtonContainer>
            </Button>
          </ButtonGroup>
        </DataTable.Cell>
      </DataTable.Row>
    );
  });

  const paginationTable = (
    <TablePaginationContainer className="table-pagination">
      <TablePagination
        currentPage={currentPage}
        pageSize={data?.hubbers.pageSize || 0}
        totalItems={data?.hubbers.total || 0}
        onPageChanged={(page: number) => setCurrentPage(page)}
      />
    </TablePaginationContainer>
  );

  const pageHeaderActions = (
    <AttendanceLogHeader>
      <HeaderSearchContainer>
        <TextInput
          value={searchValue}
          prefix={<Icon name="ri-search" />}
          placeholder="Search for Name"
          onChange={handleSearchChange}
        />
      </HeaderSearchContainer>
      <HeaderRequestFilterContainer>
        <Select
          prefix={
            <Icon
              name="ri-calendar-event-line"
              height="20px"
              fill={Neutral.B40}
            />
          }
          options={periodFilterOptions()}
          onSelect={handleSelectPeriod}
          selectedValues={[selectedPeriod]}
          width="100%"
          listHeight={248}
        />
      </HeaderRequestFilterContainer>
      <HeaderTalentFilterContainer>
        <StatusFilterSelect
          selectedStatus={selectedEmploymentStatus}
          handleSelectStatus={handleTalentSelect}
          updateHideStatusBadge={updateHideStatusBadge}
          hideBadge={hideStatusBadge}
        />
      </HeaderTalentFilterContainer>
      <HeaderExportButtonContainer>
        <PrimaryButton
          icon={<Icon name="ri-download-line" />}
          type="button"
          onClick={handleExport}
          loading={isLoadingExport}
        >
          Export
        </PrimaryButton>
      </HeaderExportButtonContainer>
    </AttendanceLogHeader>
  );

  if (error && error.message.substring(21, 24) === '401')
    return handleAuthError();

  const renderTableContent = () => {
    if (!hasSomeAttendanceData) {
      return <EmptyStateContainer>{emptyState}</EmptyStateContainer>;
    }

    return (
      <>
        {currentHubber && (
          <HubberDetailsSideSheet
            isOpen={showHubberSidesheet}
            onClose={() => setShowHubberSidesheet(false)}
            fullName={currentHubber?.fullName}
            status={currentHubber?.status}
            hub={currentHubber?.hub}
            email={currentHubber?.email}
            phone={currentHubber?.phoneNumber}
            seatId={undefined} // TODO - no data yet
            department={undefined} // TODO - no data yet
            jobTitle={currentHubber?.jobTitle}
            jobStartDate={currentHubber?.joinDate}
            jobEndDate={currentHubber?.resignDate}
            contractEndDate={currentHubber?.contractEndDate}
            salary={currentHubber?.salary}
          />
        )}
        <ScheduleSideSheet // TODO - currently disabled due to lack of data (?)
          isOpen={showScheduleSidesheet}
          onClose={() => setShowScheduleSidesheet(false)}
          fullName={currentHubber?.fullName}
          scheduleData={scheduleData} // TODO - still sample data
          daysOff={daysOffData} // TODO - still sample data
        />
        {!isNoHubbers && pageHeaderActions}
        <DataTableContainer>
          <DataTable
            headings={headings}
            emptyState={isNoHubbers ? emptyState : noMatchingResultsState}
            onSortChanged={handleSortClick}
            style={{ border: 'none' }}
          >
            {isLoading ? loadingRow : rowMarkup}
          </DataTable>
          {!isLoading &&
            (data?.hubbers?.data?.length ?? 0) > 0 &&
            paginationTable}
        </DataTableContainer>
        <Alert
          show={showAlert.shouldShow}
          onDismissed={() =>
            setShowAlert({ status: undefined, content: '', shouldShow: false })
          }
          content={showAlert.content}
          status={showAlert.status}
        />
      </>
    );
  };

  return <>{renderTableContent()}</>;
};
