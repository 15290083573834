import { Badge, Breakpoints } from 'glints-aries/lib/@next';
import {
  borderRadius8,
  borderRadius20,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space2,
  space8,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const CUSTOM_BREAKPOINT_1 = '930px';
const CUSTOM_BREAKPOINT_2 = '546px';

export const StyledBanner = styled.div`
  margin: ${space24} 0;
  padding: ${space16} ${space32};

  background: ${Blue.S08};
  border: 1px solid ${Blue.S99};
  border-radius: ${borderRadius8};

  display: flex;
  flex-direction: row;
  gap: ${space32};

  @media (max-width: ${CUSTOM_BREAKPOINT_1}) {
    flex-direction: column;
    gap: ${space16};
  }

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: row;
    gap: ${space32};
  }

  @media (max-width: ${CUSTOM_BREAKPOINT_2}) {
    flex-direction: column;
    gap: ${space16};
  }
`;

export const GridContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;

  @media (max-width: ${CUSTOM_BREAKPOINT_1}) {
    width: 100%;
  }

  @media (max-width: ${CUSTOM_BREAKPOINT_2}) {
    width: 100%;
  }
`;

export const FrameContainer = styled.div`
  padding: 0 ${space16};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${space8};

  @media (max-width: 1200px) {
    padding: 0 ${space16};
  }
  @media (max-width: 1070px) {
    padding: 0 ${space16};
  }
`;

export const StyledBadge = styled(Badge)`
  border: 1px solid ${Neutral.B100};
  border-radius: ${borderRadius20};
  white-space: nowrap;
`;

export const StyledVerticalLine = styled.div`
  width: 2px;
  background-color: ${Neutral.B85};

  @media (max-width: ${CUSTOM_BREAKPOINT_1}) {
    height: 2px;
    width: 100%;
  }

  @media (max-width: ${Breakpoints.large}) {
    height: auto;
    width: 2px;
  }

  @media (max-width: ${CUSTOM_BREAKPOINT_2}) {
    height: 2px;
    width: 100%;
  }
`;

export const MultiCurrencyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalUnpaidContainer = styled.div`
  display: flex;
  gap: ${space8};
  align-items: center;
`;
