import React, { useEffect, useLayoutEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Breakpoints,
  EmptyState,
  SkeletonText,
  TabModel,
  Tabs,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useLocation, useNavigate } from 'react-router-dom';

import { getGraphqlClient } from '../../clients/graphql';
import {
  GetCompanyProfileQuery,
  GetUserQuery,
  useGetCompanyProfileQuery,
  useGetUserQuery,
} from '../../generated/graphql';
import { useCurrentUser } from '../../hooks/useAuthToken';
import { handleAuthError } from '../../utils/handleAuthError';
import { AccountManager } from './CompanyDetailsTab/AccountManager';
import BasicInfo from './CompanyDetailsTab/BasicInfo';
import BillingInfo from './CompanyDetailsTab/BillingInfo';
import {
  MainContainer,
  StyledPageTitleContainer,
  TabContentContainer,
} from './CompanyProfileStyle';
import {
  CompanyProfileTab,
  companyProfileTab,
  tabIndexMapping,
} from './constants';
import { Role } from './RolesPermissionsTab/interfaces';
import RolesPermissionsTab from './RolesPermissionsTab/RolesPermissionsTab';

const CompanyProfilePage = ({
  activeTab = companyProfileTab.COMPANY_DETAILS,
}: {
  activeTab?: CompanyProfileTab;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const graphqlClient = getGraphqlClient();
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(
    activeTab ?? pathname === '/company-settings/roles-and-permissions'
      ? companyProfileTab.ROLES_AND_PERMISSIONS
      : companyProfileTab.COMPANY_DETAILS
  );
  const [currentUserRole, setCurrentUserRole] = useState<Role | undefined>(
    undefined
  );
  const currentUser = useCurrentUser();

  useEffect(() => {
    setSelected(
      location.pathname === '/company-settings/company-information'
        ? companyProfileTab.COMPANY_DETAILS
        : companyProfileTab.ROLES_AND_PERMISSIONS
    );
  }, [location]);

  const { data: userData } = useGetUserQuery<GetUserQuery, Error>(
    graphqlClient,
    { userId: currentUser.userId }
  );

  const { isLoading, data, error, refetch, isFetching } =
    useGetCompanyProfileQuery<GetCompanyProfileQuery, Error>(graphqlClient);

  const handleTabSelect = (index: number) => {
    setSelected(index);
    navigate(`/company-settings/${tabIndexMapping[index]}`);
  };

  useEffect(() => {
    if (userData) {
      setCurrentUserRole(userData.contact.roles[0].id);
    }
  }, [userData]);

  useEffect(() => {
    if (data) {
      setLastUpdated(
        format(new Date(data?.company?.updatedAt), 'MMM dd, yyyy')
      );
    }
  }, [data]);

  useLayoutEffect(() => {
    const BREAKPOINT = parseInt(Breakpoints.large.slice(0, 3));
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= BREAKPOINT);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tabs: TabModel[] = [
    { id: 'company-information-tab', content: 'Company Information' },
    { id: 'roles-and-permissions-tab', content: 'Roles and Permissions' },
  ];

  const errorComponent = (
    <EmptyState
      basicButtonAction={{
        label: 'Reload',
        onClick: () => refetch(),
      }}
      description="An error has occured. Please reload the page later."
      imageName="safety-cone"
      title="Something went wrong"
    />
  );
  const tabContents = [
    Boolean(error) && !(isLoading || isFetching)
      ? errorComponent
      : data && (
        <TabContentContainer>
          <AccountManager
            name={data?.company?.accountManager?.name}
            email={data?.company?.accountManager?.email}
            isLoading={isLoading || isFetching}
          />
          <BasicInfo
            name={data.company.name}
            registeredName={data.company.registeredName}
            registeredAddress={data.company.registeredAddress.street}
            operatingAddress={data.company.operatingAddress.street}
            isLoading={isLoading || isFetching}
          />
          <BillingInfo
            billingName={data.company.billingName}
            billingEmail={data.company.billingEmail}
            billingAddress={data.company.billingAddress.street}
            isLoading={isLoading || isFetching}
          />
        </TabContentContainer>
      ),
    Boolean(error) && !(isLoading || isFetching) ? (
      errorComponent
    ) : (
      <TabContentContainer>
        <RolesPermissionsTab />
      </TabContentContainer>
    ),
  ];

  if (error && error.message.substring(21, 24) === '401')
    return handleAuthError();
  return (
    <MainContainer>
      <StyledPageTitleContainer>
        <Typography as="div" variant="headline6" color={Neutral.B18}>
          Company Settings
        </Typography>
      </StyledPageTitleContainer>
      <Typography as="div" variant="overline" color={Neutral.B40}>
        {isLoading || isFetching ? (
          <SkeletonText
            rows={[
              {
                width: '300px',
                variant: 'overline',
              },
            ]}
          />
        ) : (
          <>{!error && <>Last Updated: {lastUpdated}</>}</>
        )}
      </Typography>
      <Tabs
        tabs={currentUserRole === Role.CompanyAdmin ? tabs : tabs.slice(0, 1)}
        selected={selected}
        onSelected={handleTabSelect}
        fitted={isSmallScreen}
      >
        {tabContents[selected]}
      </Tabs>
    </MainContainer>
  );
};

export default CompanyProfilePage;
