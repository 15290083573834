import { Breakpoints } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const HEADER_HEIGHT_EMPTY_STATE = '132px';

export const Container = styled.div`
  height: calc(100% - ${HEADER_HEIGHT_EMPTY_STATE});

  .tabs-header-container {
    margin-top: 8px;
    overflow-x: initial;
  }

  ul {
    gap: 0px;
    padding-inline-start: 0px;
  }

  li {
    padding: 0 2px;
  }
`;

export const TabsContainer = styled.div`
  height: 100%;

  .tabs-container {
    height: 100%;

    .tab-item-content {
      height: 100%;
    }
  }
`;

export const TabFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
  color: ${Neutral.B40};

  svg {
    height: 20px;
    width: 20px;
    fill: ${Neutral.B40};
  }

  &[data-active='true'] {
    color: ${Blue.S99};
    svg {
      fill: ${Blue.S99};
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;
