import React from 'react';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';

import { OptionContainer } from './OptionStyle';

interface OptionProps {
  iconName: IconNames;
  selectedIconName: IconNames;
  title: React.ReactNode;
  isSelected: boolean;
  isCollapsed: boolean;
  onClick: () => void;
  collapseWidth?: string;
}

export const Option = ({
  iconName,
  selectedIconName,
  title,
  isSelected,
  isCollapsed,
  onClick,
  collapseWidth = '135px'
}: OptionProps) => (
  <OptionContainer
    data-selected={isSelected}
    data-collapsed={isCollapsed}
    collapseWidth={collapseWidth}
    onClick={onClick}
  >
    <Icon name={isSelected ? selectedIconName : iconName} />
    <Typography as="span" variant="button">
      {title}
    </Typography>
  </OptionContainer>
);
