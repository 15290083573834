import React from 'react';
import {
  Badge,
  Button,
  DataTable,
  Icon,
  SkeletonImageSquare,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { TableHeading } from 'glints-aries/lib/@next/DataTable';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { getGraphqlClient } from '../../../../clients/graphql';
import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { TablePagination } from '../../../../components/TablePagination/TablePagination';
import {
  GetInvoicePdfMutation,
  GetReceiptPdfMutation,
  useGetInvoicePdfMutation,
  useGetReceiptPdfMutation,
} from '../../../../generated/graphql';
import { formatMoney } from '../../../../utils/formatString';
import { paymentMethodMap } from '../../constants';
import { PaymentCompletedTableProps } from '../../interfaces';
import {
  DataTableContainer,
  LeftPaddingWrapper,
  PaymentAmountWrapper,
  RightPaddingWrapper,
  StyledLink,
  TableButtonContainer,
  TableHeadingCellContainer,
  TablePaginationContainer,
} from './PaymentCompletedTableStyle';

export const PaymentCompletedTable = ({
  totalItems,
  currentPage,
  pageSize,
  onPageChanged,
  data,
  loading,
  handleSortChange,
}: PaymentCompletedTableProps) => {
  const graphqlClient = getGraphqlClient();

  const { mutate: invoicePdfMutate } = useGetInvoicePdfMutation<
    Error,
    GetInvoicePdfMutation
  >(graphqlClient);

  const { mutate: receiptPdfMutate } = useGetReceiptPdfMutation<
    Error,
    GetReceiptPdfMutation
  >(graphqlClient);

  const handleClickInvoicePdf = (id: string) => {
    const pdfVariables = {
      id,
    };
    invoicePdfMutate(pdfVariables, {
      onSuccess: data => {
        window.open(data?.invoicePdf?.url, '_blank');
      },
      onError: () => {
        // handle error
      },
    });
  };

  const handleClickReceiptPdf = (id: string) => {
    const pdfVariables = {
      id,
    };
    receiptPdfMutate(pdfVariables, {
      onSuccess: data => {
        window.open(data?.paymentPdf?.url, '_blank');
      },
      onError: () => {
        // handle error
      },
    });
  };

  const headings: TableHeading[] = [
    {
      id: 'paid-date',
      title: 'Paid Date',
      align: 'left',
      defaultSortDirection: 'DESCENDING',
    },
    {
      title: 'Payment Method',
      align: 'left',
    },
    {
      title: 'Payment Condition',
      align: 'left',
    },
    {
      title: 'Paid Amount',
      align: 'right',
    },
    {
      title: 'Invoice Amount',
      align: 'right',
    },
    {
      title: 'Invoice Reference',
      align: 'left',
    },
    {
      title: 'Settlement Date',
      align: 'left',
    },
    {
      title: (
        <TableHeadingCellContainer>
          Credit Notes
          <Tooltip
            content="Credit notes are generated when there is a discrepancy between your payment and the total invoice amount. The adjustment will be applied in the next billing cycle. If you have any questions, please reach out to your account manager."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </TableHeadingCellContainer>
      ),
      align: 'right',
    },
    {
      title: <RightPaddingWrapper>Actions</RightPaddingWrapper>,
      align: 'left',
    },
  ];

  const rowMarkup = data.map((payment, index) => {
    const paymentId = payment.payment?.id ?? '';
    const invoiceReferenceId = payment.invoice?.id ?? '';
    const invoiceReferenceNumber = payment.invoice?.referenceNumber ?? '';
    const paymentMethod = payment.payment?.method
      ? paymentMethodMap[payment.payment?.method] ?? 'Unknown'
      : '';
    const settlementDate = payment.invoice?.closedDate;
    const invoiceAmount = payment.invoice?.amount?.amount;
    const invoiceCurrency = payment.invoice?.amount?.currency;
    const paidDate = payment?.payment?.date;
    const paidAmount = payment.invoice?.paidAmount?.amount;
    const paidCurrency = payment.invoice?.paidAmount?.currency;
    const creditNotes = payment.invoice?.creditNoteLines?.[0];
    const creditNotesAmount = creditNotes?.amount?.amount;
    const creditNotesCurrency = creditNotes?.amount?.currency;

    return (
      <DataTable.Row key={index}>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <FormattedDate date={paidDate} />
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          {paymentMethod}
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          {parseFloat(invoiceAmount) === parseFloat(paidAmount) && (
            <Badge status="neutral">Paid in full</Badge>
          )}
          {parseFloat(invoiceAmount) < parseFloat(paidAmount) && (
            <Badge status="enticing">Overpaid</Badge>
          )}
          {parseFloat(invoiceAmount) > parseFloat(paidAmount) && (
            <Badge status="attention">Underpaid</Badge>
          )}
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          <PaymentAmountWrapper>
            <Typography as="div" variant="subtitle2" color={Neutral.B18}>
              {formatMoney({ amount: paidAmount })}
            </Typography>
            <Typography as="div" variant="overline" color={Neutral.B40}>
              {paidCurrency}
            </Typography>
          </PaymentAmountWrapper>
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          <PaymentAmountWrapper>
            <Typography as="div" variant="subtitle2" color={Neutral.B18}>
              {formatMoney({ amount: invoiceAmount })}
            </Typography>
            <Typography as="div" variant="overline" color={Neutral.B40}>
              {invoiceCurrency}
            </Typography>
          </PaymentAmountWrapper>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <LeftPaddingWrapper>
            <StyledLink
              url="#"
              onClick={() => handleClickInvoicePdf(invoiceReferenceId)}
            >
              {invoiceReferenceNumber}
            </StyledLink>
          </LeftPaddingWrapper>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          {settlementDate ? (
            <FormattedDate date={settlementDate} />
          ) : (
            <Typography as="span" variant="subtitle2" color={Neutral.B85}>
              Not yet settled
            </Typography>
          )}
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          {creditNotes ? (
            <PaymentAmountWrapper>
              <Typography as="div" variant="subtitle2" color={Neutral.B18}>
                {formatMoney({ amount: creditNotesAmount })}
              </Typography>
              <Typography as="div" variant="overline" color={Neutral.B40}>
                {creditNotesCurrency}
              </Typography>
            </PaymentAmountWrapper>
          ) : (
            <Typography as="span" color={Neutral.B85}>
              &#8212;
            </Typography>
          )}
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <Button
            size="slim"
            onClick={() => handleClickReceiptPdf(paymentId)}
            disabled={!payment?.payment?.isPdfReady}
          >
            <TableButtonContainer>View Receipt</TableButtonContainer>
          </Button>
        </DataTable.Cell>
      </DataTable.Row>
    );
  });

  const loadingRow = [...Array(10).keys()].map(n => (
    <DataTable.Row id={`loading-row-${n}`} key={n} position={n}>
      {[...Array(9).keys()].map(n => (
        <DataTable.Cell key={`loading-row-cell-${n}`}>
          <SkeletonImageSquare height="24px" width="100%" />
        </DataTable.Cell>
      ))}
    </DataTable.Row>
  ));

  return (
    <DataTableContainer>
      <DataTable headings={headings}>
        {loading ? loadingRow : rowMarkup}
      </DataTable>
      {!loading && data.length > 0 && (
        <TablePaginationContainer>
          <TablePagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChanged={onPageChanged}
          />
        </TablePaginationContainer>
      )}
    </DataTableContainer>
  );
};
