import React, { useEffect, useState } from "react";
import { Alert, Modal, TextArea, Typography } from "glints-aries/lib/@next";
import { Neutral } from "glints-aries/lib/@next/utilities/colors";
import { ComponentAction } from "glints-aries/lib/types/componentAction";

import { getGraphqlClient } from "../../../../clients/graphql";
import { CreateInvoiceDisputeMutation, useCreateInvoiceDisputeMutation } from "../../../../generated/graphql";
import { formatMoney } from "../../../../utils/formatString";
import { alertContent, AlertType, ShowAlertType } from "../constants";

interface DisputeInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  invoiceId: string;
  invoiceReference: string;
  paymentAmount: string;
  paymentCurrency: string;
}

export const DisputeInvoiceModal = ({
  isOpen, onClose, invoiceId, invoiceReference, paymentAmount, paymentCurrency
}: DisputeInvoiceModalProps) => {
  const [value, setValue] = useState('');
  const [showAlert, setShowAlert] = useState<ShowAlertType>({
    status: undefined,
    content: '',
    shouldShow: false,
  });

  useEffect(() => {
    setValue('');
  }, [invoiceId]);

  const graphqlClient = getGraphqlClient();

  const { mutate: createDisputeMutate } =
    useCreateInvoiceDisputeMutation<Error, CreateInvoiceDisputeMutation>(graphqlClient);

  const handleSubmit = () => {
    createDisputeMutate({
      invoiceId: invoiceId,
      reason: value,
    }, {
      onSuccess: () => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });
        onClose();
      },
      onError: () => {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
        onClose();
      }
    });
  };

  const primaryAction = {
    label: 'Submit',
    action: handleSubmit,
    disabled: value.length === 0,
  } as ComponentAction;
  const secondaryAction = {
    label: 'Cancel',
    action: onClose,
  };

  return (
    <>
      <Alert
        show={showAlert.shouldShow}
        onDismissed={() =>
          setShowAlert({ status: undefined, content: '', shouldShow: false })
        }
        content={showAlert.content}
        status={showAlert.status}
      />
      <Modal
        isOpen={isOpen}
        header="Dispute Invoice"
        showCloseButton={false}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        <Typography as="div" variant="body1" color={Neutral.B18} style={{
          marginBottom: '16px'
        }}>
          You are iniating a dispute for <b>{invoiceReference}</b>,
          amounting to <b>{paymentCurrency} ${formatMoney({ amount: paymentAmount })}</b>
        </Typography>
        <Typography as="div" variant="body1" color={Neutral.B18} style={{
          marginBottom: '8px'
        }}>
          Tell us the reason for this dispute
        </Typography>
        <TextArea
          placeholder="Kindly specify the discrepancy in the invoice. Indicating the expected amount or other details will help us better understand the issue."
          value={value}
          width="calc(100% - 26px)"
          onChange={val => setValue(val)}
          canExceedMaxLength={false}
          rows={5}
        />
      </Modal>
    </>
  );
};
