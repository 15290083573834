import './index.css';

import React from 'react';
import { ModalProvider } from 'glints-aries/lib/@next';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import { GA_DEV, GA_PROD } from './constants/constants';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: process.env.NODE_ENV === 'production' ? GA_PROD : GA_DEV,
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ModalProvider>
      <App />
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
